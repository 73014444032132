import React from 'react'
import { Colors } from '@sonic-foundry/mediasite-ui-react'
import Carousel from '../../../../foundation/components/Carousel';
import { Typography } from '@sonic-foundry/mediasite-ui-react';
import VideoPreviewThumb from '../../../VideoPreviewThumb';
import ErrorBoundary from '../../../../foundation/ErrorBoundary/ErrorBoundary';
import './FeaturedCarousel.scss'
import { SpotlightPresentation } from '../../../../types/presentations/SpotlightPresentation';

export interface IFeaturedCarouselProps {
    id?: string
    items?: SpotlightPresentation[]
    onPreview?: (id: string, video?: SpotlightPresentation) => void
    title?: string
}


const FeaturedCarousel = (props: IFeaturedCarouselProps) => {


    if (!props.items || props.items?.length == 0) {
        return <div id={props.id}></div>
    }

    return (
        <ErrorBoundary className="featured-carousel">
            {(props.items && props.title) &&
                <Typography style={{ color: Colors.OffWhite }} variant="body1">{props.title}</Typography>
            }
            <Carousel
                id="featured-carousel"
                useNavigation
            >
                {props.items?.map((item) => {
                    return (
                        <VideoPreviewThumb
                            id={item?.id}
                            key={item.id}
                            content={item}
                            includeLowerDetails={true}
                        />
                    )
                })}
            </Carousel>
        </ErrorBoundary>
    )
}

export default FeaturedCarousel