import React, { useEffect } from 'react'
import { getPresentationEndDate, getUserTimezone, tryFormatDate } from '../../../utils/DateUtils';
import { Button } from '@sonic-foundry/mediasite-ui-react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { SpotlightPresentation } from '../../../types/presentations/SpotlightPresentation';
import './AddToCalendarButton.scss'

const AddToCalendarButton = (video: SpotlightPresentation) => {

    const presentationEndDate = getPresentationEndDate(video.start, video.mediasiteDuration);

    useEffect(() => {
        //@ts-ignore
        if (window.addeventatc) {
            //@ts-ignore
            window.addeventatc.refresh()
        }
    }, [video])

    return (
        <Button
            className="sofoatc addeventatc"
            endIcon={<DateRangeIcon />}
            variant="outlined"
        >Add To Calendar
            <span className="start">{tryFormatDate(video.start)}</span>
            <span className="end">{presentationEndDate}</span>
            <span className="timezone">{getUserTimezone()}</span>
            <span className="title">{video.mediasiteTitle}</span>
            <span className="description">{video.mediasiteDescription}</span>
        </Button>
    )
}

export default AddToCalendarButton