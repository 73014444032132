import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Button } from '@sonic-foundry/mediasite-ui-react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router'

import Modal from '../../foundation/components/Modal'
import { fetchVideo } from '../../store/slices/spotlightSlice'
import LikeButton from '../../foundation/components/LikeButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { buildSpotlightLink, buildWatchLink } from '../../utils/Video/index';
import RelatedVideosCarousel from '../WatchModal/components/RelatedVideosCarousel/RelatedVideosCarousel';
import { favoritePresentation, unfavoritePresentation, likePresentation, unlikePresentation } from '../../store/slices/engagementSlice';
import AddToCalendarButton from "../../foundation/components/AddToCalendarButton";
import { tryFormatDate, getUserTimezoneAbbreviation } from '../../utils/DateUtils';
import './VideoPreviewModal.scss'
import { SpotlightPresentation } from '../../types/presentations/SpotlightPresentation';

type VideoPreviewModalStoreProps = {
  defaultThumbnailUrl?: string
  videoId?: string
}

export type ConnectedVideoPreviewModalProps = VideoPreviewModalStoreProps



const VideoPreviewModal = (props: ConnectedVideoPreviewModalProps) => {

  const dispatch = useAppDispatch()
  // const [video, setVideo] = useState<MSVideo>(props.video)
  let { playlistId, videoId } = useParams();
  const homeLink = buildSpotlightLink(playlistId)
  const enableAddToCalendar = useAppSelector(state => state.config.platformSettings.enableAddToCalendar)

  // let relatedVideos = useAppSelector(x => x.spotlight.upcoming)
  const settings = useAppSelector(x => x.config.settings)
  const presentation: SpotlightPresentation = useAppSelector(x => x.spotlight.selectedVideo)
  const defaultThumbnailUrl: string = useAppSelector(x => x.config.settings.defaultThumbnailURL)
  const userFavs: string[] = useAppSelector(x => x.engagement.favoritedPresentationIds)
  const userLikes: string[] = useAppSelector(x => x.engagement.likedPresentationIds)
  const likeCount: number = useAppSelector(x => x.engagement.presentationLikeCounts[presentation?.mediasiteResourceId])
  const relatedVideosEnabled = useAppSelector(x => x.config.settings.relatedVideosEnabled);

  useEffect(() => {
    if (!presentation || presentation.id !== videoId) {
      dispatch(fetchVideo(videoId)) // User opens page on preview modal.
    }
  }, []) // Only runs on initial render

  useEffect(() => {
    if (!presentation || presentation.id !== videoId) {
      dispatch(fetchVideo(videoId)) // User opens page on preview modal.
    }
  }, [videoId]) // Only runs on initial render


  /// What should be shown while the presentation is loading??
  if (!presentation) { return (<div></div>) }

  const presentationId = presentation.mediasiteResourceId

  const CloseButton = () => (
    <Link id="preview-modal-closeBtn" to={homeLink} title="Close">
      <span>Close</span>
      <div className="closeIcon">
        <IconButton >
          <CloseIcon />
        </IconButton>
      </div>
    </Link>
  )



  const watchLink = buildWatchLink(presentation.id, playlistId)
  const isVideoAvailable = new Date(presentation.start) < new Date()
  const thumbnailUrl = presentation.mediasiteThumbnailUrl || defaultThumbnailUrl


  const isFavorited: boolean = userFavs?.find(x => x === presentation?.mediasiteResourceId) !== undefined
  const isLiked: boolean = userLikes.find(x => x === presentation.mediasiteResourceId) !== undefined

  const showAddToCalendar = enableAddToCalendar && new Date().getTime() < Number(presentation.start)

  const toggleFavorite = () => {
    if (!isFavorited) {
      dispatch(favoritePresentation(presentationId))
    } else {
      dispatch(unfavoritePresentation(presentationId))
    }
  }

  const toggleLike = () => {
    if (!isLiked) {
      dispatch(likePresentation(presentationId))
    } else {
      dispatch(unlikePresentation(presentationId))
    }
  }

  return (
    <Modal
      id="preview-modal"
      fullScreen={false}
      onClose={null}
      open
      closeBtnElement={<CloseButton />}
    >
      <div>
        <div className="video-preview-content">
          <Grid container spacing={5} alignItems="center" justifyContent="center">
            <Grid item xs={12} lg={10} xl={8} justifyContent="center">
              <Typography id="preview-title" variant="h3">{presentation.mediasiteTitle}</Typography>
              {presentation.mediasitePresenters?.map((presDetails: string, index) => (
                <Typography key={index} variant="h6">{presDetails}</Typography>
              ))}
            </Grid>
            <Box width="100%" />
            <Grid item xs={12} lg={10} xl={8} justifyContent="center">
              <Typography className="view-likes-wrapper" variant="h6">
                <Typography variant="body2">{tryFormatDate(presentation.start)} {getUserTimezoneAbbreviation()}</Typography>
                {settings.likesEnabled &&
                  <LikeButton liked={isLiked} onLike={toggleLike} onUnlike={toggleLike} numLikes={likeCount} />
                }
              </Typography>
            </Grid>
            <Box width="100%" />

            <Grid item xs={10} md={6} lg={5} xl={4} >
              <img className="img-fluid" src={thumbnailUrl} />
            </Grid>
            <Grid item xs={10} md={6} lg={5} xl={4}  >
              <Typography variant={'body1'} className="line-clamped">{presentation.mediasiteDescription}</Typography>
              <div className="preview-actions">
                {isVideoAvailable &&
                  <Link to={watchLink}>
                    <Button variant="contained">Watch</Button>
                  </Link>
                }
                {settings.favoritesEnabled &&
                  <Button
                    className="favorite-btn"
                    color="primary"
                    endIcon={isFavorited ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
                    onClick={toggleFavorite}
                    variant="outlined"
                  >
                    {isFavorited ? 'Favorited' : 'Favorite'}
                  </Button>
                }
                {showAddToCalendar &&
                  <AddToCalendarButton {...presentation} />
                }
              </div>
            </Grid>
          </Grid>
        </div>
          <Grid container spacing={5} alignItems="center" justifyContent="center" style={{ marginTop: 20 }}>
            <Grid item xs={12} lg={10} xl={8} justifyContent="center">
              {relatedVideosEnabled &&
                <RelatedVideosCarousel
                  key={presentation?.id}
                  presentationId={presentation.id}
                />
              }              
            </Grid>
          </Grid>        
      </div>
    </Modal >
  );
}


export default VideoPreviewModal