import React from 'React'
import { Toast } from 'react-bootstrap'

export interface IUserAlertToastProps {
    autohide?: boolean
    duration?: number
    logo: string
    message: string
    onDismiss?: any
    onEngage?: any
    time: string
    title: string
    timetoken: number
}

const UserAlertToast = (props: IUserAlertToastProps) => {

    const engage = () => {
        props.onEngage && props.onEngage(props)
    }
    
    const handleClose = () => {
        props.onDismiss && props.onDismiss(props)
    }

    return (
        <Toast onClose={handleClose} delay={props.duration} autohide={props.autohide}>
            <Toast.Header>
                <strong className="mr-auto">{props.title}</strong>
            </Toast.Header>
            <Toast.Body>
                <div onClick={engage}>
                    <div dangerouslySetInnerHTML={{ __html: props.message }} />
                </div>
            </Toast.Body>
        </Toast>
    )
}

export default UserAlertToast