/**
 * Build a consistent messaging channel id for presentations.
 * @param presentationId Mediasite Presentation Id
 * @param clientId Spotlight Client id. Necessary for uniqueness across reused presentations.
 * @returns Formatted channel id
 */
export const buildChatRoomId = (presentationId: string, clientId: string): string => {
    return `client.${clientId}.pres.${presentationId}.chat`
}

// export const buildPrivateRoomId = () => {}
// export const buildPresentationQARoomId = () => {}