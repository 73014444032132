import React from 'react'
import Pubnub from 'pubnub'
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Card, Accordion } from 'react-bootstrap';

import { getPubnubClient } from '../../redux/integrations/pubnub/pubnubConfig'
import { RootState } from '../../redux/store';
import AdminAlertMessage from '../../components/alerts/AdminAlertMessage';
import { IPubnubMessage } from '../../models/messagingModels';
import { IProfileState } from '../../redux/features/profile/profileSlice';
import AlertComposer from '../../components/alerts/AlertComposer';

interface IAdminAlertsContainerReactProps {
    target: string
}
interface IAdminAlertsContainerReduxProps {
    profile: IProfileState
}

interface IAdminAlertsContainerProps extends IAdminAlertsContainerReactProps, IAdminAlertsContainerReduxProps { }


interface IAdminAlertsContainerState {
    pubnubClient: Pubnub
    alerts: IPubnubMessage[]
}

class AdminAlertsContainer extends React.Component<IAdminAlertsContainerProps, IAdminAlertsContainerState> {

    constructor(props: IAdminAlertsContainerProps) {
        super(props)
        this.state = {
            alerts: [],
            pubnubClient: getPubnubClient(props.profile.id)
        }
    }

    loadMessages = async () => {
        const { profile } = this.props
        const { pubnubClient } = this.state
        let channels = [`event.${profile.eventId}.alerts`]
        let alerts = []
        await Promise.all(channels.map(async (channel) => {
            const response = await pubnubClient.fetchMessages({ channels: [channel], includeMessageActions: true, includeMeta: true, includeMessageType: true })
            const channelMessagesObject = response.channels[channel]
            if (channelMessagesObject) {
                const messagesArr = Object.values(channelMessagesObject)
                if (messagesArr.length > 0) {
                    alerts.push(...messagesArr)
                }
            }
        }))
        this.setState({ alerts })
    }

    async componentDidMount() {
        await this.loadMessages()
    }

    submitMessage = async (subject: string, body: string) => {
        const channel = `event.${this.props.profile.eventId}.alerts`
        this.publishMessage(channel, { body, subject })
        setTimeout(async ()=> {
            await this.loadMessages()
        }, 5000)
    }

    testMessage = (subject: string, body: string) => {
        const channel = `user.${this.props.profile.id}.test`
        this.publishMessage(channel, { body, subject })
    }

    publishMessage = (channel: string, messageData: any) => {
        const { pubnubClient } = this.state
        const payload = {
            channel,
            message: messageData
        }
        pubnubClient.publish(payload)
    }

    render() {
        let { alerts } = this.state
        _.reverse(alerts) // Order by newest
        return ReactDOM.createPortal(
            <div>
                <AlertComposer
                    onPreview={this.testMessage}
                    onSubmit={this.submitMessage}
                />
                <br />
                <Card>
                    <Card.Header>
                        Sent Notifications {alerts && (`(${alerts.length})`)}
                    </Card.Header>
                    {alerts &&
                        <Accordion defaultActiveKey="0">
                            {alerts.map((msg, index: number) =>
                                <AdminAlertMessage
                                    key={msg.timetoken}
                                    index={index}
                                    message={msg}
                                />
                            )}
                        </Accordion>
                    }
                </Card>
            </div>,
            document.querySelector(this.props.target)
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        profile: state.profile
    }
}

export default connect(mapStateToProps)(AdminAlertsContainer)