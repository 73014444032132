import { deleteContent, deletePlaylist, fetchContent, fetchPlaylistContent, refreshContent, submitPlaylist, updateContentPriority } from '../../../api/content/contentApi'
import React from "react";
import ContentOptionsDropdown from './components/ContentOptionsDropdown';
import { IContentItem, IUserPlaylist, IContentPlaylist, Playlist, VideoPriorities, ContentData } from '../../../models/content';
import SofoModal from '../../../components/brand/modal';

enum SortColumn {
    Name,
    ReverseName,
    Id,
    ReverseId,
    Date,
    ReverseDate,
    Playlist,
    ReversePlaylist,
    Priority,
    ReversePriority
}

interface IContentManagerContainerProps {

}

interface IContentManagerContainerState {
    content: IContentItem[];
    searchTerm: string;
    filteredContent: IContentItem[];
    filteredColumn: SortColumn;
    userPlaylists: IUserPlaylist[];
    playlistContent: IContentPlaylist[];
    selectedContentIds: string[];
    selectAll: boolean;
    newPlaylistName?: string;
    playlistId?: string;
    playlistIdsToDelete: string[];
    playlistIsDisable: boolean;
    showPlaylistModal: boolean;
    showPlaylistDeleteModal: boolean;
    showContentDeleteModal: boolean;
    validationError: boolean;
    errorText: string;
}


class ContentManagerContainer extends React.Component<IContentManagerContainerProps, IContentManagerContainerState> {
    constructor(props) {
        super(props);

        this.state = {
            content: [],
            searchTerm: '',
            filteredContent: [],
            filteredColumn: SortColumn.Name, //if one changes this, also update the component did mount to make sure they stay matching
            userPlaylists: [],
            playlistContent: [],
            selectedContentIds: [],
            selectAll: false,
            newPlaylistName: '',
            playlistId: '',
            playlistIdsToDelete: [],
            playlistIsDisable: true,
            showPlaylistModal: false,
            showPlaylistDeleteModal: false,
            showContentDeleteModal: false,
            validationError: false,
            errorText: '',
            
        };
    }

    async componentDidMount() {
        const contentData = await fetchContent();    
        const playlistData = await fetchPlaylistContent();

        const sortedData = this.sortContentByDescription(contentData.content, true);

        this.setState({
            content: sortedData, 
            playlistContent: playlistData.playlistContent, 
            userPlaylists: playlistData.userPlaylists
        });
    }

    handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {       
        const enteredTerm = event.target.value;

        let foundContent = [];

        //create a list of content based upon the content and the search term
        //if the entered term is empty, don't bother doing all this look up, since the results will be equal to all the content
        if (enteredTerm.length > 0) {
            foundContent = this.filterContent(this.state.content, enteredTerm);
        }

        this.setState({
            searchTerm: enteredTerm,
            filteredContent: foundContent,
            selectAll: false //reset the selection button so that it doesn't stay selected when the results change and now we are displaying different results which might not all be selected
        });
    }

    filterContent = (content: IContentItem[], searchTerm: string) => {
        const matchTerm = searchTerm.toLowerCase();
        return content.filter(
            contentItem => {
                const playListNames = this.getPlaylistNamesforContentId(contentItem);

                return contentItem.mediasiteTitle.toLowerCase().indexOf(matchTerm) > -1 ||
                    contentItem.mediasiteResourceId.toLowerCase().indexOf(matchTerm) > -1 ||
                    contentItem.mediasiteLocalizedStartDate.toLowerCase().indexOf(matchTerm) > -1 ||
                    contentItem.mediasiteTimeZoneAbbreviation.toLowerCase().indexOf(matchTerm) > -1 ||
                    contentItem.mediasiteTimeZoneAbbreviation.toLowerCase().indexOf(matchTerm) > -1 ||
                    playListNames.toLowerCase().indexOf(matchTerm) > -1
            }
        );
    }

    getPlaylistNamesforContentId = (contentItem: IContentItem) => {
        const includedPlaylists = this.state.playlistContent.filter(pl => {
            return (pl.contentId === contentItem.id);
        });

        return includedPlaylists.map(pl => {
            return pl.playlist.name;
        }).toString();
    }

    handleContentSelectionChange = async (event, contentId: string) => {
        let contentIds = this.state.selectedContentIds;
        const target = event.target;

        if (target.checked) {           
            contentIds.push(contentId);
        } else {
            contentIds = contentIds.filter(x => x != contentId);          
        }       

        this.setState({ selectedContentIds: contentIds }, () => {
            this.checkPlaylistViewability();
        });
    }

    handlePlaylistChange = async (event, isNew: boolean) => {
        var value = event.target.value;

        if (isNew) {
            this.setState({ newPlaylistName: value, playlistId: '' });  
        } else {
            this.setState({
                playlistId: value,
                newPlaylistName: '',
                errorText: '',
                validationError: false
            });
        }
    }

    handleSelectAll = async () => {
        let {
            selectedContentIds,
            content,
            filteredContent,
            searchTerm
        } = this.state;

        const workingContent = this.getActiveContent(content, filteredContent, searchTerm);

        const allSelected = selectedContentIds.length === workingContent.length;

        if (allSelected) {
            selectedContentIds = [];
        } else {
            selectedContentIds = workingContent.map(x => x.id);
        }

        this.setState({ selectAll: !allSelected, selectedContentIds }, () => {
            this.checkPlaylistViewability();
        });
    }

    handleFilterName = async () => {
        let {
            content,
            searchTerm,
            filteredColumn,
        } = this.state;

        const isForward = (filteredColumn === SortColumn.Name) ? false : true;
        const sortedContent = this.sortContentByDescription(content, isForward);
        const filterContent = this.filterContent(content, searchTerm);

        this.setState({
            content: sortedContent,
            filteredContent: filterContent,
            filteredColumn: (filteredColumn === SortColumn.Name) ? SortColumn.ReverseName : SortColumn.Name
        });
    }

    handleFilterId = async () => {
        let {
            content,
            searchTerm,
            filteredColumn,
        } = this.state;

        const isForward = (filteredColumn === SortColumn.Id) ? false : true;
        const sortedContent = this.sortContentById(content, isForward);
        const filterContent = this.filterContent(content, searchTerm);

        this.setState({
            content: sortedContent,
            filteredContent: filterContent,
            filteredColumn: (filteredColumn === SortColumn.Id) ? SortColumn.ReverseId : SortColumn.Id
        });
    }

    handleFilterDate = async () => {
        let {
            content,
            searchTerm,
            filteredColumn,
        } = this.state;

        const isForward = (filteredColumn === SortColumn.Date) ? false : true;
        const sortedContent = this.sortContentByDate(content, isForward);
        const filterContent = this.filterContent(content, searchTerm);

        this.setState({
            content: sortedContent,
            filteredContent: filterContent,
            filteredColumn: (filteredColumn === SortColumn.Date) ? SortColumn.ReverseDate : SortColumn.Date
        });
    }

    handleFilterPlaylist = async () => {
        let {
            content,
            searchTerm,
            filteredColumn,
        } = this.state;

        const isForward = (filteredColumn === SortColumn.Playlist) ? false : true;
        const sortedContent = this.sortContentByPlaylist(content, isForward);
        const filterContent = this.filterContent(content, searchTerm);

        this.setState({
            content: sortedContent,
            filteredContent: filterContent,
            filteredColumn: (filteredColumn === SortColumn.Playlist) ? SortColumn.ReversePlaylist : SortColumn.Playlist
        });
    }

    handleFilterPriority = async () => {
        let {
            content,
            searchTerm,
            filteredColumn,
        } = this.state;

        const isForward = (filteredColumn === SortColumn.Priority) ? false : true;
        const sortedContent = this.sortContentByPriority(content, isForward);
        const filterContent = this.filterContent(content, searchTerm);

        this.setState({
            content: sortedContent,
            filteredContent: filterContent,
            filteredColumn: (filteredColumn === SortColumn.Priority) ? SortColumn.ReversePriority : SortColumn.Priority
        });
    }

    handleSubmitPlaylist = async () => {
        await this.validateUniquePlaylistName();

        let {
            playlistId,
            newPlaylistName,
            selectedContentIds,
            validationError
        } = this.state;

        if (validationError) {
            return;
        }

        let playlist = new Playlist();
        playlist.id = playlistId;
        playlist.name = newPlaylistName;
        playlist.contentIds = selectedContentIds;

        const result = await submitPlaylist(playlist);

        if (result.error?.length > 0) {
            alert(result.error);
        } else {
            location.reload();
        }        
    }

    handlePlaylistDeleteChange = async (event) => {
        const options = event.target.options;
        let playlistIdsToDelete = [];

        for (var i = 0; i < options.length; i++) {
            if (options[i].selected) {
                playlistIdsToDelete.push(options[i].value);
            }
        }

        this.setState({ playlistIdsToDelete });
    }

    handleSubmitDeletePlaylist = async () => {
        const playlistIdsToDelete = this.state.playlistIdsToDelete;

        if (playlistIdsToDelete.length > 0) {
            await deletePlaylist(playlistIdsToDelete);
            location.reload();
        }
    }

    handleSubmitRefreshContent = async () => {
        await refreshContent();
        location.reload();
    }

    handleSubmitDeleteContent = async () => {
        const selectedContent = this.state.selectedContentIds;

        if (selectedContent.length > 0) {
            await deleteContent(selectedContent);
            location.reload();
        }
    }

    checkPlaylistViewability = () => {
        const isDisabled = this.state.selectedContentIds.length > 0 ? false : true;
        this.setState({ playlistIsDisable: isDisabled })
    }

    handlePlaylistModal = () => {
        this.setState({showPlaylistModal: true});
    }

    handleContentDeleteModal = () => {
        this.setState({showContentDeleteModal: true});
    }

    handlePlaylistDeleteModal = () => {
        this.setState({showPlaylistDeleteModal: true});
    }

    onPlaylistModalClose = () => {
        this.setState({
            showPlaylistModal: !this.state.showPlaylistModal,
            validationError: false,
            errorText: ''
        });
    }

    onPlaylistModalDeleteClose = () => {
        this.setState({showPlaylistDeleteModal: !this.state.showPlaylistDeleteModal});
    }

    onContentModalDeleteClose = () => {
        this.setState({showContentDeleteModal: !this.state.showContentDeleteModal});
    }

    getActiveContent = (content: IContentItem[], filteredContent: IContentItem[], searchValue: string) => {
        if (searchValue.length > 0) {
            return filteredContent;
        } else {
            return content;
        }
    }

    sortContentByDescription = (content: IContentItem[], isFoward: boolean) => {
        const multiplier = (isFoward) ? 1 : - 1;
        return content.sort((a: IContentItem, b: IContentItem) => {
            return a.mediasiteTitle.trim().localeCompare(b.mediasiteTitle.trim()) * multiplier;
        });
    }

    sortContentById = (content: IContentItem[], isFoward: boolean) => {
        const multiplier = (isFoward) ? 1 : - 1;
        return content.sort((a: IContentItem, b: IContentItem) => {
            return a.mediasiteResourceId.localeCompare(b.mediasiteResourceId) * multiplier;
        });
    }

    sortContentByDate = (content: IContentItem[], isFoward: boolean) => {
        const multiplier = (isFoward) ? 1 : - 1;
        return content.sort((a: IContentItem, b: IContentItem) => {
            const dateA = Date.parse(a.mediasiteLocalizedStartDate);
            const dateB = Date.parse(b.mediasiteLocalizedStartDate);

            return (dateA - dateB) * multiplier;
        });
    }

    sortContentByPlaylist = (content: IContentItem[], isFoward: boolean) => {
        const multiplier = (isFoward) ? 1 : - 1;
        const lastPossibleString = "ZZZZZZZZZZZZZZZZZZ"; //we want empty strings to appear after everything else. Instead of doing complicated logic, we'll set empty strings to this for calcuation

        return content.sort((a: IContentItem, b: IContentItem) => {
            let playListNamesA = this.getPlaylistNamesforContentId(a);
            let playListNamesB = this.getPlaylistNamesforContentId(b);

            playListNamesA = (playListNamesA === "") ? lastPossibleString : playListNamesA;
            playListNamesB = (playListNamesB === "") ? lastPossibleString : playListNamesB;

            return playListNamesA.localeCompare(playListNamesB) * multiplier;
        });
    }

    sortContentByPriority = (content: IContentItem[], isFoward: boolean) => {
        const multiplier = (isFoward) ? 1 : - 1;
        return content.sort((a: IContentItem, b: IContentItem) => {
            return (a.priority - b.priority) * multiplier;
        });
    }

    validateUniquePlaylistName = async () => {
        let {
            userPlaylists,
            newPlaylistName,
            errorText
        } = this.state;

        let isInvalid = false;

        if (newPlaylistName.length > 0) {
            for (let i = 0; i < userPlaylists.length; i++) {
                if (userPlaylists[i].name === newPlaylistName) {
                    isInvalid = true;
                    errorText = 'Please provide unique playlist name. ' + newPlaylistName + ' already exists.';
                    break;
                }
            }
        }   
        this.setState({ validationError: isInvalid, errorText });                 
    }

    updatePriority = async (contentId: string) => {
        var {
            content
        } = this.state;

        let index = content.findIndex(cont => cont.id === contentId);
        let contentToUpdate = content[index];

        if(contentToUpdate.priority == VideoPriorities.None) {
            contentToUpdate.priority = VideoPriorities.Featured;
        } else if (contentToUpdate.priority == VideoPriorities.Featured) {
            contentToUpdate.priority = VideoPriorities.Spotlight;
        } else {
            contentToUpdate.priority = VideoPriorities.None;
        }

        const response = await updateContentPriority(contentToUpdate);

        if (response === 200) {
            content[index] = contentToUpdate;
            this.setState({ content });
        } else if (response.error) {
            alert(response.error);
        }    
    }

    render() {
        let  { 
            content,
            searchTerm,
            filteredContent,
            filteredColumn,
            newPlaylistName,
            userPlaylists,
            playlistContent,
            showPlaylistModal,
            showPlaylistDeleteModal,
            showContentDeleteModal,
            selectAll,
            validationError,
            errorText
        } = this.state;  
        
        const plalistViewability = (this.state.playlistIsDisable) ? 'disabledElement':'';
        const deletePlaylistViewability = playlistContent?.length === 0 ? 'disabledElement' : '';

        const visibleContent = this.getActiveContent(content, filteredContent, searchTerm);

        return ( 
            <>
                <div className="d-flex flex-wrap contentManagerNav pt-3 pb-1">
                    <div className='d-flex contentManagerNavItem'>
                        <div className='dropdown'>
                            <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className='text-sofo-blue'>Playlist</span>
                            </div>

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item">
                                    <p className={plalistViewability} onClick={this.handlePlaylistModal}><i className="fas fa-plus pr-3"></i>Add</p>
                                </div>

                                <div className="dropdown-item">
                                    <p className={deletePlaylistViewability} onClick={this.handlePlaylistDeleteModal}><i className="far text-danger fa-trash-alt pr-3"></i>Delete</p>
                                </div>
                            </div>
                        </div>
                        

                        <div className={plalistViewability} onClick={this.handleContentDeleteModal} style={{cursor:"pointer", paddingLeft: 30}}>
                            <span className='text-sofo-blue'>Remove Selected Content</span>
                        </div>
                    </div>
                 
                    <div className='contentManagerNavItem'>
                        <button className="btn btn-success mr-2" type="button" onClick={this.handleSubmitRefreshContent}><i className="fas fa-sync-alt pr-2"></i>Refresh Site Content</button>
                    </div>

                    <div className="input-group pt-3">
                        <input className="form-control" placeholder="Filter Video Results" aria-label="Filter" type="text" id="FilterResults" name="FilterResults" value={searchTerm} onChange={this.handleSearchChange} />
                    </div>

                    <SofoModal open={showContentDeleteModal} onClose={this.onContentModalDeleteClose} title='Confirm Content Deletion'>
                        <p>
                            The content will be removed from your Connect site. It will <b>NOT</b> be removed from your Mediasite library. Are you sure you want to continue?
                        </p>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.onContentModalDeleteClose}>Close</button>
                            <button onClick={this.handleSubmitDeleteContent} className="btn btn-danger">
                                <i className="fas fa-trash-alt pr-2"></i>
                                Delete
                            </button>
                        </div>                       
                    </SofoModal>                  
                </div>     
                
                {/* Create, Add to Playlist Modal  */}
                <SofoModal open={showPlaylistModal} onClose={this.onPlaylistModalClose} title='Add To Playlist'>
                    <div>

                        <select className="custom-select my-1 mr-sm-2"
                            onChange={(e) => this.handlePlaylistChange(e, false)} value={this.state.playlistId}>
                                <option value="">Select Playlist</option>
                                {userPlaylists.map(playlist => 
                                    <option key={playlist.id} value={playlist.id}>{playlist.name}</option>
                                )}
                        </select>

                        <div className="form-group">
                            <input 
                            type="text" 
                            name="newPlaylistName"
                            className="form-control" 
                            placeholder="New Playlist" 
                            value={newPlaylistName}
                            onChange={(e) => this.handlePlaylistChange(e, true)} />
                        </div>

                        {validationError ? (
                            <span className='text-danger'>{errorText}</span>
                        ) : null}             

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.onPlaylistModalClose}>Close</button>
                            <button type="button" onClick={this.handleSubmitPlaylist} className="btn btn-primary">Save</button>
                        </div>
                    </div>                
                </SofoModal>
                {/* END Create, Add to Playlist Modal  */}

                {/* Delete Playlists Modal  */}
                <SofoModal open={showPlaylistDeleteModal} onClose={this.onPlaylistModalDeleteClose} title='Select Playlists To Delete'>
                    <div> 
                        <select multiple className="custom-select my-1 mr-sm-2"
                            onChange={(e) => this.handlePlaylistDeleteChange(e)}>
                                {userPlaylists.map(playlist => 
                                    <option 
                                    key={playlist.id} 
                                    value={playlist.id}
                                    >{playlist.name}</option>
                                )}
                        </select>

                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.onPlaylistModalDeleteClose}>Close</button>
                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmitDeletePlaylist}>Save</button>
                        </div>
                    </div>             
                </SofoModal>                   
                {/* END Delete Playlists Modal  */}

                {visibleContent.length ?               
                    <>
                        <table className="table table-btn-action">
                            <thead>
                                <tr>
                                    <th>
                                        <input type='checkbox' checked={selectAll} onChange={this.handleSelectAll}/>
                                    </th>
                                    <th aria-label="Manage"></th>
                                    <th className={`col-3 sortable ${(filteredColumn == SortColumn.Name) ? "active" : ""} ${(filteredColumn == SortColumn.ReverseName) ? "reverse-active" : ""}`}>
                                        <span onClick={this.handleFilterName}>Name</span>
                                    </th>
                                    <th className={`sortable ${(filteredColumn == SortColumn.Id) ? "active" : ""} ${(filteredColumn == SortColumn.ReverseId) ? "reverse-active" : ""}`}>
                                        <span onClick={this.handleFilterId}>Mediasite Id</span>
                                    </th>
                                    <th className={`sortable ${(filteredColumn == SortColumn.Date) ? "active" : ""} ${(filteredColumn == SortColumn.ReverseDate) ? "reverse-active" : ""}`}>
                                        <span onClick={this.handleFilterDate}>Start Date/Time</span>
                                    </th>
                                    <th className={`sortable ${(filteredColumn == SortColumn.Playlist) ? "active" : ""} ${(filteredColumn == SortColumn.ReversePlaylist) ? "reverse-active" : ""}`}>
                                        <span onClick={this.handleFilterPlaylist}>Playlist</span>
                                    </th>
                                    <th className={`sortable ${(filteredColumn == SortColumn.Priority) ? "active" : ""} ${(filteredColumn == SortColumn.ReversePriority) ? "reverse-active" : ""}`}>
                                        <span onClick={this.handleFilterPriority}>Priority</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="font-weight-strong">                            
                                {                            
                                    visibleContent.map((cont) =>
                                        <tr key={cont.id}>
                                            <td>                                           
                                                <div className="presSelection">
                                                    <input type="checkbox"
                                                        name="presSelection"
                                                        checked={this.state.selectedContentIds.includes(cont.id)}
                                                        onChange={(e) => { this.handleContentSelectionChange(e, cont.id) }}
                                                    />
                                                </div>                                        
                                            </td>
                                            <td>
                                                <ContentOptionsDropdown contentId={cont.id} mediasiteTitle={cont.mediasiteLocalizedStartDate} mediasiteResourceId={cont.mediasiteResourceId}  />                                        
                                            </td>
                                            <td><a href={'/site/'+ global.urlSlug +'/watch/' + cont.id} target="_blank">{cont.mediasiteTitle}</a></td>
                                            <td><a href={cont.mediasiteManageUri} target="_blank">{cont.mediasiteResourceId}</a></td>
                                            <td>{cont.mediasiteLocalizedStartDate} {cont.mediasiteTimeZoneAbbreviation}</td>
                                            <td>
                                                {
                                                    playlistContent.map(pl => {
                                                        if (pl.contentId === cont.id) {
                                                            return <span key={pl.playlistId}>{pl.playlist.name} <br/> </span>
                                                        }
                                                    })                                                                                                      
                                                }
                                            </td>
                                            <td onClick={() => this.updatePriority(cont.id)} style={{cursor: 'pointer'}}>
                                            {(() => {
                                                switch (cont.priority) {
                                                    case VideoPriorities.None:   
                                                        return <span><i className="far fa-star pr-2"></i></span>;
                                                    case VideoPriorities.Featured: 
                                                        return <span><i className="fas fa-star-half-alt pr-2"></i>Featured</span>;
                                                    case VideoPriorities.Spotlight:  
                                                        return <span><i className="fas fa-star pr-2"></i>Spotlight</span>;
                                                    default:
                                                        return null;
                                                }
                                            })()}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </>
                : null
                }
            </>
        );
    }
}

export default ContentManagerContainer;