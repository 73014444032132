import React from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { CONTENT_SORT_ORDERS } from '../../../../constants/index';
import SortDropdown from '../../../../foundation/components/SortDropdown'

import './PresentationGridListHeader.scss'

export interface IContentGridHeaderProps {
    collapseCallback?: (videoType: string) => void
    collapsed: boolean
    onCollapsed: (collapsed: boolean) => void
    onSort: (sortOrder: number) => void
    /**
     * Current sort order being applied to the list
     */
    sort: typeof CONTENT_SORT_ORDERS.NEWEST 
    | typeof CONTENT_SORT_ORDERS.OLDEST 
    | typeof CONTENT_SORT_ORDERS.TITLE_A 
    | typeof CONTENT_SORT_ORDERS.TITLE_Z 
    | typeof CONTENT_SORT_ORDERS.POPULAR
    title: string
}

const ContentGridNav = (props: IContentGridHeaderProps) => {

    const toggleCollapsed = () => {
        //collapseCallback reverts the number of content items to 10 or fewer
        props.collapseCallback && props.collapseCallback(props.title)
        return props.onCollapsed(!props.collapsed)
    }

    const handleSortSelection = (sortOrder: number) => {
        props.onSort(sortOrder)
    }

    const sortOrder = props.sort

    return (
        <div className="pres-grid-list-header">
            <div className="toggleDetails" onClick={toggleCollapsed}>
                <div className="title">{props.title}</div>
                {props.collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {!props.collapsed &&
                <div className="sort-options">
                    <SortDropdown onChange={handleSortSelection} />
                </div>
            }

        </div>
    )
}

type IContentGridNavOptionProps = {
    active: boolean
    onClick: any
    sortOrder: typeof CONTENT_SORT_ORDERS.NEWEST 
    | typeof CONTENT_SORT_ORDERS.OLDEST 
    | typeof CONTENT_SORT_ORDERS.TITLE_A 
    | typeof CONTENT_SORT_ORDERS.TITLE_Z 
    | typeof CONTENT_SORT_ORDERS.POPULAR
    title: string
}
const ContentGridNavOption = (props: IContentGridNavOptionProps) => {
    const handleSelect = () => {
        return props.onClick(props.sortOrder)
    }
    /** CSS classes to use for the item */
    let classes = 'option'
    if (props.active) { classes += ' active' }
    return (
        <div onClick={handleSelect} className={classes}>
            {props.title}
        </div>
    )
}

export default ContentGridNav