import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

interface ISofoModalProps {
    children?: any
    title: string
    open?: boolean
    onClose?: any
    onOpen?: any
    customClass?: string
}

const SofoModal = (props: ISofoModalProps) => {
    const [open, setOpen] = useState<boolean>(props.open)
    
    const closeModal = () => {
        setOpen(false)
        if (props.onClose) {
            props.onClose()
        }
    }

    useEffect(() => {
        setOpen(props.open);
    },
    [props.open]);

    return (
        <Modal show={open} backdrop="static" dialogClassName={props.customClass} onHide={closeModal}>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {props.children}
            </Modal.Body>
        </Modal>
    )
}

export default SofoModal