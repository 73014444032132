import React from "react";
import { IScoreQuestionnaireResponse } from "../../../../models/ce";

interface IResultsSummaryProps extends IScoreQuestionnaireResponse {
    handleClose: () => void
    handleCompleted: () => void
    handleRetake: () => void
}

const ResultsSummary = (props: IResultsSummaryProps) => {

    return (
        <div className="results-summary text-center">
            <h3>{props.message}</h3>
            {props.passed &&
                        <button
                        className="btn btn-primary px-5 mt-3"
                        type="button"
                        onClick={props.handleCompleted}
                    >Close</button>
            }
            {!props.passed &&
                        <button
                        className="btn btn-primary px-5 mt-3"
                        type="button"
                        onClick={props.handleRetake}
                    >Retake Quiz</button>
            }

        </div>
    )

}

export default ResultsSummary