import * as React from "react";
import SelectUnstyled, {
    SelectUnstyledProps,
    SelectOption
} from "@mui/base/SelectUnstyled";
import OptionUnstyled from "@mui/base/OptionUnstyled";
import './SortDropdown.scss'

type SortDropdownProps = {
    onChange: (sortOrder: number) => void
}

const SortSelectElement = (props: SelectUnstyledProps<number>) => {
    const components: SelectUnstyledProps<number>["components"] = {
        ...props.components
    };

    return <SelectUnstyled {...props} onChange={props.onChange} components={components} />;
}

function renderValue(option: SelectOption<number> | null) {
    return <span>Sort By</span>;
}

const SortDropdown = (props: SortDropdownProps) => {
    return (
        <SortSelectElement renderValue={renderValue} onChange={props.onChange}>
            <OptionUnstyled value={0}>Newest to oldest</OptionUnstyled>
            <OptionUnstyled value={1}>Oldest to Newest</OptionUnstyled>
            <OptionUnstyled value={2}>Title A-Z</OptionUnstyled>
            <OptionUnstyled value={3}>Title Z-A</OptionUnstyled>
            <OptionUnstyled value={4}>Popular</OptionUnstyled>
        </SortSelectElement>
    );
}

export default SortDropdown
