import { ICEUserQuestionnaireSubmission, IQuestionnaire } from '../../models/ce/index';
import useApi, { API_REQUEST_METHOD, IConnectApiRequest } from '../connectApi';

const QUESTIONNAIRE_API_ENDPOINT = '/CE/questionnaire'

export const createQuestionnaire = async (questionnaire: IQuestionnaire): Promise<IQuestionnaire> => {
    const endpoint = QUESTIONNAIRE_API_ENDPOINT
    const request: IConnectApiRequest = {
        data: questionnaire,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request)
}

export const deleteQuestionnaire = async (questionnaireId: string) : Promise<any> => {
    const endpoint = `${QUESTIONNAIRE_API_ENDPOINT}/${questionnaireId}`
    const request: IConnectApiRequest = {
        method: API_REQUEST_METHOD.DELETE,
        endpoint
    }
    return await useApi(request)
}

export const fetchQuestionnaire = async (questionnaireId: string): Promise<IQuestionnaire> => {
    const endpoint = `${QUESTIONNAIRE_API_ENDPOINT}/${questionnaireId}`
    const request: IConnectApiRequest = {
        method: API_REQUEST_METHOD.GET,
        endpoint
    }
    return await useApi(request)
}

export const updateQuestionnaire = async (questionnaire: IQuestionnaire): Promise<IQuestionnaire> => {
    const endpoint = `${QUESTIONNAIRE_API_ENDPOINT}/${questionnaire.id}`
    const request: IConnectApiRequest = {
        data: questionnaire,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request)
}


export const submitUserQuestionnaire = async (submission: ICEUserQuestionnaireSubmission) => {
    const endpoint = `${QUESTIONNAIRE_API_ENDPOINT}/${submission.questionnaireId}/submit`
    const request: IConnectApiRequest = {
        data: submission,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request)
}

export const fetchAvailablePresentations = async () => {
    const endpoint = `/CE/presentations`
    const request: IConnectApiRequest = {
        method: API_REQUEST_METHOD.GET,
        endpoint
    }
    return await useApi(request)
}