export interface IQuestionnaire extends CEQuestionnaire { }
export class Questionnaire implements IQuestionnaire {
  id: string
  bannerKey: string
  bannerUrl: string
  category: string
  credits: number
  description : string
  dueDate: string
  eventId: string
  isGraded: boolean
  isNew?: boolean
  isRequired?: boolean
  passingGrade: number
  presentationId?: string
  presenters: string
  published: boolean
  questions: IQuestionnaireQuestion[]
  title: string
}

export interface IQuestionnaireQuestion extends CEQuestion {}
export interface IQuestionnaireQuestionOption extends CEQuestionOption {}

export enum CEQuestionType {
  MultipleChoice = 0,
  OpenForm = 1
}

export interface CEQuestionnaire {
  id: string;
  bannerUrl: string;
  bannerKey: string
  category: string;
  credits: number;
  dueDate: string
  presenters: string;
  description: string;
  eventId: string;
  isGraded: boolean;
  title: string;
  passingGrade: number;
  presentationId?: string
  published: boolean;
  questions: CEQuestion[];
}

export interface CEQuestion {
  id: string;
  eventId: string;
  graded: boolean;
  options: CEQuestionOption[];
  order?: number
  required: boolean;
  text: string;
  responseType: CEQuestionType;
  questionnaireId: string;
}

export interface CEQuestionOption {
  id: string;
  ceQuestion?: CEQuestion;  
  ceQuestionId: string;
  isCorrect: boolean;
  order: number;
  text: string;
}

export interface ICEUserQuestionResponse {
  optionId?: string // Response option
  ceQuestionId: string
  text?: string // Open form question response text
}

export class CEUserQuestionResponse implements ICEUserQuestionResponse {
  optionId: string
  ceQuestionId: string
  text: string

  constructor(questionId : string, optionId: string, text: string) {
    this.optionId = optionId
    this.ceQuestionId = questionId
    this.text = text
  }
}

export interface ICEUserQuestionnaireSubmission {
  questionnaireId: string
  answers: ICEUserQuestionResponse[]
}

export interface IScoreQuestionnaireResponse {
  message: string
  numCorrect: number
  numGradedQuestions: number
  numIncorrect: number
  numQuestions: number
  questionnaireId: string
  passed: boolean
}
