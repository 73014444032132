import useApi, { API_REQUEST_METHOD, ConnectApiRequest } from './connectApi';
import { IsNullOrEmpty } from '../helpers/index';

/** Fetch the current Connect user's profile */
export const fetchProfile = async () => {
    var request: ConnectApiRequest = {
        url : `https://${window.location.host}/api/user/profile`,
        method : API_REQUEST_METHOD.GET
    }

    if (!IsNullOrEmpty(window.urlSlug)){
        request.url += `?site=${window.urlSlug}`
    }

    if (window.location.pathname.startsWith('/admin')) {
        var adminQuery = IsNullOrEmpty(window.urlSlug) ? '?admin=true' : '&admin=true'
        request.url += adminQuery
        
    }
    return await useApi(request)
}