import { ContentData, IContentItem, Playlist, PlaylistContent } from '../../models/content';
import useApi, { API_REQUEST_METHOD, IConnectApiRequest } from '../connectApi';
const CONTENT_API_ENDPOINT = '/content'

export const fetchContent = async (): Promise<ContentData> => {
    const endpoint = `${CONTENT_API_ENDPOINT}/eventContent`;

    const request: IConnectApiRequest = {
        method: API_REQUEST_METHOD.GET,
        endpoint
    }
    return await useApi(request)
}

export const fetchPlaylistContent = async (): Promise<PlaylistContent> => {
    const endpoint = `${CONTENT_API_ENDPOINT}/playlistContent`;

    const request: IConnectApiRequest = {
        method: API_REQUEST_METHOD.GET,
        endpoint
    }
    return await useApi(request)

}

export const submitPlaylist = async (playlistData: Playlist) => {
    const endpoint = `${CONTENT_API_ENDPOINT}/playlist/submit`
    const request: IConnectApiRequest = {
        data: playlistData,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request)
}

export const deletePlaylist = async (playlistIds: string[]) => {
    const endpoint = `${CONTENT_API_ENDPOINT}/playlist/delete`
    const request: IConnectApiRequest = {
        data: playlistIds,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request)
}

export const refreshContent = async () => {
    const endpoint = `${CONTENT_API_ENDPOINT}/refresh`;
    const request: IConnectApiRequest = {
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request);
}

export const deleteContent = async (contentIds: string[]) => {
    const endpoint = `${CONTENT_API_ENDPOINT}/delete`;
    const request: IConnectApiRequest = {
        data: contentIds,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request);
}

export const updateContentPriority = async (content: IContentItem) => {
    const endpoint = `${CONTENT_API_ENDPOINT}/updatePriority`;
    const request: IConnectApiRequest = {
        data: content,
        method: API_REQUEST_METHOD.POST,
        endpoint
    }
    return await useApi(request);
}