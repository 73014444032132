// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  MeetingStatus,
  useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import routes from '../constants/routes';

const useMeetingEndRedirect = () => {
  const history = useHistory();
  const meetingStatus = useMeetingStatus();

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      history.push(routes.ENDED_LOBBY);
    }
  }, [meetingStatus]);
};

export default useMeetingEndRedirect;
