import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap';
import useApi, { ConnectApiRequest, API_REQUEST_METHOD } from '../../api/connectApi';
import FormstackConfigForm from './Formstack/FormstackConfigForm';
import GenericConfigForm from './Generic/GenericConfigForm';
import { IntegrationConfigItem, IntegrationOptionType } from './types';

export type ConnectIntegrationConfigurationType = {
    appIntegration: IntegrationOptionType;
    appIntegrationId: string
    eventId: string
    id: string
    pageMarkdown: string
    settingKeys: IntegrationConfigItem[]
}


const IntegrationConfigContainer = () => {

    const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
    const FORMSTACK_INTEGRATION_ID = '3d5c5371-333f-489e-9b41-74b40916dd8f'
    const NAYLOR_INTEGRATION_ID = 'c49d296e-3edf-47ba-aa05-23fc57d34b67'
    const CONFIG_CONTAINER_ID = 'securityIntegrationWrapper'
    const EDITOR_SELECTOR = `integrationPageMarkdown`


    const [availableIntegrations, setAvailableIntegrations] = useState<IntegrationOptionType[]>([])
    const [configFields, setConfigFields] = useState([])
    const [selectedIntegration, setSelectedIntegration] = useState<IntegrationOptionType>(undefined)
    const [integrationConfig, setIntegrationConfig] = useState<ConnectIntegrationConfigurationType>()
    const [urlSlug, setUrlSlug] = useState<string>()

    const formstackSelected = selectedIntegration?.id === FORMSTACK_INTEGRATION_ID
    //const showLoginCustomization = !selectedIntegration || selectedIntegration.id !== NAYLOR_INTEGRATION_ID

    let markdownTitle = 'Customize Login Screen'


    useEffect(() => {
        initialize()
        const urlSlug = document.getElementById(CONFIG_CONTAINER_ID).dataset.urlslug
        setUrlSlug(urlSlug)
    }, [])

    useEffect(() => {
        if (selectedIntegration) {
            setTimeout(() => {
                initializeMarkdown()
            }, 500)
        }
    }, [selectedIntegration])


    const initialize = async () => {
        // If a integration is already in use it will be set as a data attribute
        // in the Security.cshmtl page
        const appIntegrationId = document.getElementById(CONFIG_CONTAINER_ID).dataset.integrationid
        const _availableIntegrations = await fetchAvailableIntegrations()
        setAvailableIntegrations(_availableIntegrations)
        if (appIntegrationId && appIntegrationId !== EMPTY_GUID) {
            const _selectedIntegration = _availableIntegrations.find(x => x.id == appIntegrationId)
            setSelectedIntegration(_selectedIntegration)
            await fetchIntegrationConfig(appIntegrationId)
        }
    }

    const fetchAvailableIntegrations = async (): Promise<IntegrationOptionType[]> => {
        const endpoint = `/integrations/available`
        const request: ConnectApiRequest = {
            method: API_REQUEST_METHOD.GET,
            endpoint: endpoint
        }
        const availableIntegrations = await useApi(request)
        setAvailableIntegrations(availableIntegrations)
        return availableIntegrations
    }

    const clearSelection = () => {
        setIntegrationConfig(undefined)
        setSelectedIntegration(undefined)
        setConfigFields([])
    }

    const handleIntegrationSelection = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const integrationId = e.target.value
        if (!integrationId || integrationId === EMPTY_GUID) {
            clearSelection()
            return
        }
        const integration = availableIntegrations.find(x => x.id === integrationId)
        setSelectedIntegration(integration)
        if (integration !== undefined) {
            await fetchIntegrationConfig(integration.id)
        }
    }


    const fetchIntegrationConfig = async (integrationId: string) => {
        const endpoint = `/integrations/${integrationId}/configuration`
        const request: ConnectApiRequest = {
            method: API_REQUEST_METHOD.GET,
            endpoint: endpoint
        }

        const integrationConfig = await useApi(request)
        if (integrationConfig) {
            setConfigFields(_.sortBy(integrationConfig.settingKeys, x => x.displayOrder))
            setIntegrationConfig(integrationConfig)
            updateMarkdown(integrationConfig.pageMarkdown)
        }
    }

    const initializeMarkdown = (markdown?: string) => {
        //@ts-ignore
        tinymce.remove(`#${EDITOR_SELECTOR}`);
        //@ts-ignore
        initializeTinyMCE(`#${EDITOR_SELECTOR}`, false)
        //@ts-ignore
        tinymce.get(`${EDITOR_SELECTOR}`).setContent(markdown || '')
    }

    const updateMarkdown = (markdown: string) => {
        setTimeout(function () {
            //@ts-ignore
            tinymce.get(`${EDITOR_SELECTOR}`).setContent(markdown || '')
        }, 500)
    }

    return (
        <div id="admin-integration-configurer">
            <Form.Group>
                <Form.Label>Integration</Form.Label>
                <Form.Control as="select" name="SecuritySettingsModel.SelectedIntegrationId" onChange={handleIntegrationSelection} value={selectedIntegration?.id}>
                    <option disabled>Select an integration</option>
                    <option value={EMPTY_GUID}>None</option>
                    {availableIntegrations?.length && availableIntegrations.map((i) =>
                        <option key={i.id} value={i.id}>{i.name}</option>
                    )}
                </Form.Control>
            </Form.Group>
            {(integrationConfig && configFields) &&
                <Card style={{ marginBottom: '1rem' }} key={selectedIntegration?.id}>
                    <Card.Header>{selectedIntegration?.name} Configuration</Card.Header>
                    <Card.Body>
                        {formstackSelected &&
                            <FormstackConfigForm
                                configFields={configFields}
                                urlSlug={urlSlug}
                            />
                        }
                        {!formstackSelected &&
                            <GenericConfigForm
                                configFields={configFields}
                                urlSlug={urlSlug}
                            />
                        }
                        {selectedIntegration && //showLoginCustomization &&
                            <>
                                <Form.Label>{markdownTitle}</Form.Label>
                                <textarea id={EDITOR_SELECTOR} className="tinymce" name="PageMarkdown" />
                            </>
                        }
                        <button type="submit" className="btn btn-sofo-blue mt-3">Save</button>
                    </Card.Body>
                </Card>
            }
        </div>
    )

}

export default IntegrationConfigContainer