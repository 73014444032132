import React, { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTheme } from '@mui/material/styles';
import './LikeButton.scss'
import { Typography } from "@mui/material";

type LikeButtonProps = {
    liked?: boolean
    numLikes: number
    onLike: any
    onUnlike: any
    iconSX?: any
    thumbSX?: any
}

const LikeButton = (props: LikeButtonProps) => {
    const [numLikes, setNumLikes] = useState<number>(props.numLikes)
    const [liked, setLiked] = useState<boolean>(props.liked || false)

    const theme = useTheme()

    const handleClick = (event: React.MouseEvent) => {
        // TODO should this always be a blocking event? Maybe make it a prop decision
        event.preventDefault()
        event.stopPropagation()
        setLiked(!liked)
        if (!liked) {
            setNumLikes(numLikes + 1)
            props.onLike()
        } else {
            setNumLikes(numLikes - 1)
            props.onUnlike()
        }
    }

    useEffect(() => {
        setLiked(props.liked)
    }, [props.liked])

    useEffect(() => {
        setNumLikes(props.numLikes)
    }, [props.numLikes])

    const label = `Like presentation`

    let likeBtnStyle = [
        {
            ":hover svg": {
                color: `${theme.palette.primary.main}!important`
            }
        },
        liked && {
            "& svg": {
                color: `${theme.palette.primary.main}!important`
            }
        }
    ]

    return (
        <div className='like-button-wrapper'>
            <IconButton className={'likeBtn'} onClick={handleClick} aria-label={label} title={label} component="span" sx={likeBtnStyle}>
                <ThumbUpIcon />
            </IconButton>
            <Typography variant="caption" sx={{ color: theme.palette.primary.main }} className="numLikes">{numLikes || 0}</Typography>
        </div>
    )
}

const defaultProps: LikeButtonProps = {
    iconSX: {},
    onLike: undefined,
    onUnlike: undefined,
    numLikes: 0,
    thumbSX: {}
}
LikeButton.defaultProps = defaultProps

export default LikeButton
