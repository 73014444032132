import { isNumeric } from '../index';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import { SpotlightPresentation } from '../../types/presentations/SpotlightPresentation';
dayjs.extend(tz);

/** Convert milliseconds into min:ss format */
export const prettifyMilliseconds = (durationInMilliseconds: number | string): string => {
    const parsedDuration = ~~durationInMilliseconds
    if (parsedDuration === 0) {
        if (!isNumeric(durationInMilliseconds)) {
            throw Error
        }
    }
    const totalSeconds = parsedDuration / 1000
    const hrs = ~~(totalSeconds / 3600);
    const mins = ~~((totalSeconds % 3600) / 60);
    const secs = ~~totalSeconds % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export const formatDate = (contentItem: SpotlightPresentation) => {
    if (!contentItem?.start) {
        return "";
    }
    
    var startDate = dayjs(contentItem.start, 'MM/DD/YYYY');
    var oneYearAgo = dayjs().subtract(1, 'year');
    if (startDate.isBefore(oneYearAgo)) {
        return startDate.format('M/DD/YYYY');
    }
 
    return contentItem.start;
}

const LONG_DATE_FORMAT  = 'M/DD/YY'
export const LONG_DATE_TIME_FORMAT = 'M/DD/YY h:mm A'

/**
 * Attempt to format a date representation to the specified format.
 * @param date Date object to format. Can be a number or string
 * @param format Desired output format. Defaults to M/DD/YY
 * @returns 
 */
export const tryFormatDate = (date: string | number, format? : string) => {   
    if (!format) { format = LONG_DATE_TIME_FORMAT}
    let startDate = dayjs(date);
    return startDate.format(format);
}

/**
 * Format a date. Ignores time if the presentation is more than 2 days old.
 * @param date Date object to format. Can be a number or string
 * @param format Desired output format. Defaults to M/DD/YY
 * @returns 
 */
 export const getSimplePresentationDate = (date: string | number, format? : string) => {   
    if (!format) { format = LONG_DATE_FORMAT}
    let now = new Date()
    let startDate = dayjs(date);
    if (dayjs().add(-2, 'day') > startDate) {
        return startDate.format(LONG_DATE_FORMAT)
    }
    return startDate.format(format);
}

/**
 * Adds duration to a start date and returns a formatted value 
 * @param start Start Date as a string
 * @param duration Amount of time in milliseconds
 * @param format How should the date be formatted. Defaults to 'M/DD/YYYY h:mm A'
 * @returns 
 */
export const getPresentationEndDate = (start: string | number, duration: number, format: string = LONG_DATE_TIME_FORMAT) => {
    const startDate = dayjs(start);
    if (duration < (60 * 1000)) { // Calendar expects at least a 1 minute meeting
        duration = 60000 
    }
    const finish = startDate.add(duration, 'ms')
    const finishFormatted = finish.format(format)
    return finishFormatted
}

export const getUserTimezone = () => {
    return dayjs.tz.guess();
}

export const getUserTimezoneAbbreviation = () => {
    return new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]
}

