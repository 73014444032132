import React from 'react'
import { IPubnubMessage } from '../../models/messagingModels';

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { parseLongUnixTimestamp } from '../../helpers/dateHelpers';
import { Card, Accordion } from 'react-bootstrap';
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)


export interface IAdminAlertMessageProps {
    index: number
    message: IPubnubMessage
    onDelete?: () => {}
}

const AdminAlertMessage = (props: IAdminAlertMessageProps) => {
    let engageCount = 0
    let readCount = 0

    const { index } = props
    const message = props.message
    const actions = message.actions


    if (actions) {
        engageCount = actions['receipt']['open']?.length || 0
        readCount = actions['receipt']['read']?.length || 0
    }

    const shortTimeToken = parseLongUnixTimestamp(props.message.timetoken?.toString())
    const timeAgo = dayjs(shortTimeToken).format('L LT')

    return (
        <Card>
            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                <span className="subject"><strong>{message.message.subject}</strong></span>
                <span className="date">{timeAgo}</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body>
                    <div className="alert-body" dangerouslySetInnerHTML={{ __html: props.message.message.body }} />
                    <div className="alert-footer">
                        {/* <div>Opened By: {engageCount}</div> */}
                        <div>Read By: {readCount}</div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AdminAlertMessage