import { SpotlightPresentation } from '../types/presentations/SpotlightPresentation';

const MockVideo: SpotlightPresentation = {
    "id": "88e402af-5895-423d-673d-08d5e5d22d2c",
    "mediasiteDescription": "Hold the button.  Keep holding the button.  Keep doing it.  Smash.",
    "mediasiteDuration": 24000,
    "mediasiteEndDate": "2018-04-28T06:31:24",
    mediasiteNumberOfViews: Math.floor(Math.random() * 100),
    "mediasitePresenters": [
        "Justin Helgerson"
    ],
    "mediasiteResourceId": "3a13a5755d5443f5ac9fd2dd21fb784c1d",
    "mediasiteStartDate": "2018-04-27T23:51:24",
    mediasiteTags: ["Bob O'Brien"],
    "mediasiteThumbnailUrl": "https://justinapi.mediasite.com/Mediasite/FileServer/Presentation/3a13a5755d5443f5ac9fd2dd21fb784c1d/3666ad57-86fc-4f8e-8d35-e00a824a58e2.jpg?apikey=24f394bf-b47f-4380-b4c9-c34bffdf5a4f&hash=bFdpcW5NLzAwZVZLOGlaNWcvZHNoTVFxeXNrPQ==",
    "mediasiteTitle": "Soldier 76 with a Smash",
    "start": new Date().getTime(),
    "viewable": true,
    "enableLiveQA": true,
    "isSimulated": true,
    "mediasiteIsLive": false,
    "simLivePlayerConfigs": undefined
}

export default MockVideo

