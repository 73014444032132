import React from 'react'
import Carousel from '../../../../foundation/components/Carousel';
import { VideoPreviewThumb } from '../../..';
import { SpotlightPresentation } from '../../../../types/presentations/SpotlightPresentation';
import useAxiosFetch from '../../../../hooks/useAxiosFetchHook';
import { useAppSelector } from '../../../../store';
import LoadingIcon from '../../../../foundation/components/LoadingIcon';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { buildRelatedLink} from '../../../../utils/Video';

// Styles
import './RelatedVideosCarousel.scss'


type IRelatedVideosCarouselProps = {
    /**
     * Id of the Video with related content.
     */
    presentationId: string
}

export const RelatedVideosCarousel = (props: IRelatedVideosCarouselProps) => {

    const { presentationId } = props
    const { playlistId } = useParams()

    const apiClientConfig = useAppSelector(x => x.config.apiClientConfig)
    const apiEndpoint = `${apiClientConfig.basePath}${buildRelatedLink(presentationId, playlistId)}`
    const { data, loading } = useAxiosFetch(apiEndpoint, 10000)

    return (
        <div className='related-container'>                    
            {loading && <LoadingIcon />}
            {(!loading && data && data.length > 0) &&
                <>
                <Typography variant={'h5'}>{'Related Videos'}</Typography><br />               
                <Carousel
                    id={presentationId}
                    mousewheel
                    useNavigation 
                    >
                    {data && data.map((video: SpotlightPresentation) => (
                        <VideoPreviewThumb
                            id={video?.id}
                            key={video.id}
                            content={video}
                            includeLowerDetails={true}
                        />
                    ))}
                </Carousel>
                </>
            }
        </div>
    )
}

export default RelatedVideosCarousel
