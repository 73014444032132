export const KEY_CODES = {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
};

export const CONTENT_SORT_ORDERS = {
    NEWEST: 0,
    OLDEST: 1,
    TITLE_A: 2,
    TITLE_Z: 3,
    POPULAR: 4
}
