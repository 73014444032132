import React from 'react'
import { darken, Theme } from "@mui/material"
import { GlobalStyles } from "@mui/styled-engine"

type AppStylesProps = {
    siteTheme: Theme
}

const AppStyles = (props: AppStylesProps) => {
    const siteTheme = props.siteTheme
    const styles = {
        ".addeventatc.sofoatc": {
            border: `1px solid ${darken(siteTheme.palette.primary.main, .4)} !important`,
            color: `${siteTheme.palette.primary.main} !important`
        },
        ".addeventatc.sofoatc:hover": {
            border: `1px solid ${darken(siteTheme.palette.primary.main, 0)} !important`,
        },
    }
    return <GlobalStyles styles={styles} />    
}

export default AppStyles