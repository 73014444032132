import { Base } from '../base'
import { InteractionSummaryRequest, InteractionSummaryResponse, UserProgressSummary, UserProgressSummaryItem } from './types';

const resourceName = 'engagement'

/**
 * Handle Engagement summaries for Spotlight engagement actions 
 * such as likes and favorites
 */
export class EngagementApi extends Base {

    /**
     * Fetch the latest engagement details for a Spotlight Client
     * @param requestData 
     * @returns InteractionSummaryResponse for the client and profile's latest presentation fav/like counts
     */
    async getEngagement(request: InteractionSummaryRequest): Promise<InteractionSummaryResponse> {
        const endpoint = request.playlistId
            ? `playlist/${request.playlistId}/${resourceName}`
            : `${resourceName}`
        return await this.request(endpoint)
    }

    /**
     * Fetch 
     * @returns A dictionary of presentationId:progress items
     */
    async getProgress() : Promise<UserProgressSummaryItem[]> {
        const endpoint = 'progress'
        return await this.request(endpoint)
    }

}

