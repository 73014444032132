import React, { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { IsNullOrEmpty } from '../../helpers/index';
import InputErrorMessage from '../brand/InputErrorMessage';

interface IAlertComposerProps {
    onPreview: (subject: string, body: string) => void
    onSubmit: (subject: string, body: string) => void
}

const AlertComposer = (props: IAlertComposerProps) => {

    const [subject, setSubject] = useState<string>('')
    const [subjectError, setSubjectError] = useState<string>()
    const [bodyError, setBodyError] = useState<string>()

    const SUBJECT_ID = 'send-notification-subject'
    const EDITOR_ID = 'send-notification-text'
    const EDITOR_SELECTOR = '#' + EDITOR_ID
    const BODY_TOO_LONG_ERROR = 'The notification is too long.'

    useEffect(() => {
        //@ts-ignore
        initializeTinyMCE(EDITOR_SELECTOR, false)
    }, [])


    const getAlertBody = () => {
        //@ts-ignore
        return tinymce.get(EDITOR_ID).getContent()
    }

    const onPreview = () => {
        const body = getAlertBody()
        const isValid = validateAlert()
        if (isValid) {
            props.onPreview(subject, body)
        }
    }

    const onSubmit = () => {
        const body = getAlertBody()
        const isValid = validateAlert()
        if (isValid) {
            props.onSubmit(subject, body)
            setSubject('')
            //@ts-ignore
            tinymce.get(EDITOR_ID).setContent('')
        }
    }

    const validateAlert = (): boolean => {
        let valid = true
        if (IsNullOrEmpty(subject)) {
            setSubjectError('A subject is required.')
            valid = false
        } else { setSubjectError(undefined) }
        const body = getAlertBody()
        if (IsNullOrEmpty(body)) {
            setBodyError('A notification is required.')
            valid = false
        } else if (body.length > 512) {
            setBodyError(BODY_TOO_LONG_ERROR)
            valid = false
        } else { setBodyError(undefined) }
        return valid
    }

    return (
        <Card id="alert-composer">
            <Card.Header>Send Notification</Card.Header>
            <Card.Body>
                <Form.Group className="mb-3">
                    <Form.Control
                        id={SUBJECT_ID}
                        maxLength={120}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value.toString())}
                        placeholder="Subject"
                        required
                        value={subject} />
                    {subjectError && <InputErrorMessage>{subjectError}</InputErrorMessage> }
                </Form.Group>
                <Form.Group>
                    <textarea required id={EDITOR_ID}></textarea>
                    {bodyError && <InputErrorMessage>{bodyError}</InputErrorMessage> }                    
                </Form.Group>
                <div>
                    <Button variant="secondary" onClick={onPreview}>Preview</Button>
                    <Button variant="primary" onClick={onSubmit}>Send Notification</Button>
                </div>
            </Card.Body>
        </Card>
    )
}

export default AlertComposer