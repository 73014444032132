import React, { useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

interface ISearchableSelectProps {
    mapper: any
    onSelect: any
    placeholder?: string
    searchUrl: string
}

const SearchableSelect = (props: ISearchableSelectProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = async (query: string) => {
        setIsLoading(true);
        let response = await fetch(`${props.searchUrl}?query=${query}`)
        let data = await response.json()
        let users = data.map(props.mapper)
        setOptions(users)
        setIsLoading(false)
    };


    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id="PresenterSearch"
            isLoading={isLoading}
            labelKey="name"
            minLength={2}
            onSearch={handleSearch}
            options={options}
            placeholder={props.placeholder}
            renderMenuItemChildren={(option) => (
                <div onClick={() => { props.onSelect(option) }}>
                    <div>{option.name}</div>
                    <div>{option.email}</div>
                </div>
            )}
        />
    );
}

export default SearchableSelect