import React, { ReactNode, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Mousewheel, Navigation, SwiperOptions } from 'swiper';
import { ArrowBtn } from '../../../utils/CarouselPlugins';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './Carousel.scss'


interface CarouselProps extends SwiperOptions {
    id: string
    children?: ReactNode
    style?: any
    useNavigation: boolean

}

// Navigation notes:
// https://github.com/nolimits4web/swiper/issues/3855

export const Carousel = (props: CarouselProps) => {

    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

    const {
        id,
        useNavigation
    } = props


    const defaultBreakPoints = {
        "640": {
            "slidesPerView": 2,
            "spaceBetween": 20
        },
        "768": {
            "slidesPerView": 3,
            "spaceBetween": 30
        }
    }

    if (useNavigation) {
        SwiperCore.use([Navigation, A11y, Mousewheel]);
    } else {
        SwiperCore.use([A11y, Mousewheel]);
    }

    return (
        <div className="sofo-carousel" key={id}>
            <div
                className="sofo-swiper-nav-btn"
                ref={(node) => setPrevEl(node)}
            >
                <ArrowBtn direction="left" />
            </div>
            <Swiper
                key={id}
                breakpoints={props.breakpoints || defaultBreakPoints}
                className="sofo-swiper"
                mousewheel={props.mousewheel}
                navigation={useNavigation ? { prevEl, nextEl } : null}
                slidesPerView={props.slidesPerView || 1}
                spaceBetween={props.spaceBetween || 10}
                style={props.style || { marginLeft: 10, marginRight: 10 }}
            >
                {React.Children.map(props.children, (child, index) => {
                    return <SwiperSlide key={index}>{child}</SwiperSlide>
                })}
            </Swiper>
            <div
                ref={(node) => setNextEl(node)}
                className="sofo-swiper-nav-btn"
            >
                <ArrowBtn direction="right" />
            </div>
        </div >
    )
}

export default Carousel
