// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { MemoryRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  lightTheme,
  MeetingProvider,
  NotificationProvider,
} from 'amazon-chime-sdk-component-library-react';

import { AppStateProvider } from './providers/AppStateProvider';
import ErrorProvider from './providers/ErrorProvider';
import routes from './constants/routes';
import { NavigationProvider } from './providers/NavigationProvider';
import { Meeting, Home, DeviceSetup } from './views';
import Notifications from './containers/Notifications';
import NoMeetingRedirect from './containers/NoMeetingRedirect';
import meetingConfig from './meetingConfig';
import EndedLobby from './views/EndedLobby/index';

interface IMeetingAppProps {
  meetingId: string
  name: string
  isPresenter: boolean
  region: string
  userId: string
}

const App = (props: IMeetingAppProps) => {
  return (
    <Router
      initialEntries={[routes.HOME]}
      initialIndex={0}
    >
      <AppStateProvider
        isPresenter={props.isPresenter}
        meetingId={props.meetingId}
        name={props.name}
        region={props.region}
        userId={props.userId}
      >
        <Theme>
          <NotificationProvider>
            <Notifications />
            <ErrorProvider>
              <MeetingProvider>
                <NavigationProvider>
                  <Switch>
                    <Route exact path={routes.HOME} component={Home} />
                    <Route path={routes.DEVICE}>
                      <NoMeetingRedirect>
                        <DeviceSetup />
                      </NoMeetingRedirect>
                    </Route>
                    <Route path={routes.MEETING}>
                      <NoMeetingRedirect>
                        <Meeting />
                      </NoMeetingRedirect>
                    </Route>
                    <Route path={routes.ENDED_LOBBY}>
                      <NoMeetingRedirect>
                        <EndedLobby />
                      </NoMeetingRedirect>
                    </Route>
                  </Switch>
                </NavigationProvider>
              </MeetingProvider>
            </ErrorProvider>
          </NotificationProvider>
        </Theme>
      </AppStateProvider>
    </Router>
  )
}

const Theme: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      {children}
    </ThemeProvider>
  );
};

export default App;
