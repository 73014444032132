import React from 'react'

interface ISwitchProps {
    checked: boolean
    label?: string
    labelStyle?: any
    name?: string
    onChange: (checked: boolean) => void
}
const Switch = (props: ISwitchProps) => (
    <div className="sf-ms-switch d-flex align-items-center" style={{padding: '4px 0'}}>
        {props.label &&
            <span className="mr-3" style={props.labelStyle}>{props.label}</span>
        }
        <label className='switch'>
            <input
                checked={props.checked}
                name={props.name}
                onChange={() => props.onChange(!props.checked)}
                type="checkbox"
            />
            <div className={`slider round`} />
        </label>

    </div>
);

export default Switch