import { InitializationConfig } from '../config/InitializationConfig'
import { createTheme, Theme } from "@mui/material";

const buildTheme = (config: InitializationConfig): Theme => {
    let theme = {
        type: 'light',
        palette: {
            action: {
                active: config.theme.fontColor,
                selected: config.theme.buttonColor
            },
            primary: {
                main: config.theme.buttonColor,
                dark: config.theme.buttonColorSecondary
            },
            secondary: {
                main: config.theme.buttonColorSecondary
            },
            text: {
                primary: config.theme.fontColor
            },
            overrides: {
                IconButton: {
                    root: {
                        "& .svg": {
                            color: config.theme.fontColor
                        },
                        "&:hover": {
                            backgroundColor: config.theme.buttonColor
                        },
                    }
                },
                MuiButton: {
                    root: {
                        "&:hover": {
                            backgroundColor: config.theme.buttonColorSecondary
                        },
                    }
                },
                MuiSvgIcon: {
                    colorPrimary: {
                        color: [config.theme.fontColor, "!important"],
                    },
                    colorSecondary: {
                        color: [config.theme.fontColor, "!important"],
                    }
                }
            }
        },

    }
    return createTheme(theme)
}

export default buildTheme
