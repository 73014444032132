import React from "react";

const ContentOptionsDropdown = props => {
    const urlSlug = global.urlSlug;
    
    return (
        <div className="dropdown">
            <button className="btn btn-sofo-blue dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Options
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div className="dropdown-item">
                    <form method="get" action={`/admin/site/${urlSlug}/content/${props.contentId}/update`}>
                        <button type="submit"
                                className="nakedBtn"
                                aria-label={'Update ' + props.mediasiteTitle}>
                            <i className="fas fa-edit pr-3"></i>Edit
                        </button>
                    </form>
                </div>
                <div className="dropdown-item">
                    <form method="post" action={`/admin/site/${urlSlug}/content/${props.mediasiteResourceId}/refresh`} >
                        <button type="submit"
                            className="nakedBtn text-success"
                            aria-label={'Refresh Content ' + props.mediasiteTitle}>
                            <i className="text-success fas fa-sync-alt pr-3"></i>Refresh
                        </button>
                    </form>
                </div>
                <div className="dropdown-item">
                    <form method="post" action={`/admin/site/${urlSlug}/content/${props.mediasiteResourceId}/delete`} >
                        <button type="submit"
                                className="nakedBtn text-danger"
                                aria-label={'Delete ' + props.mediasiteTitle}>
                                <i className="far text-danger fa-trash-alt pr-3"></i>Delete
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContentOptionsDropdown