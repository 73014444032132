import { Fab } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface IArrowBtnProps {
    direction: 'left' | 'right'
    disabled?: boolean
}

export const ArrowBtn = (props: IArrowBtnProps) => {
    return (
        <Fab disabled={props.disabled} size={'small'} color="primary" aria-label="Next">
            {props.direction === 'left' && <ChevronLeftIcon />}
            {props.direction === 'right' && <ChevronRightIcon />}
        </Fab>
    )
}
