import axios from 'axios'
import { IsNullOrEmpty } from '../helpers/index';

export enum API_REQUEST_METHOD {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT'
}

export interface IApiRequest {
  data?: any // Data is automatically serialized so don't preemptively stringify
  endpoint?: string
  method: API_REQUEST_METHOD
  url?: string
}

//https://github.com/axios/axios#request-config
export interface IConnectApiRequest extends IApiRequest { }
export class ConnectApiRequest implements IConnectApiRequest {
  data?: any
  endpoint?: string
  method: API_REQUEST_METHOD
  url?: string
  ConnectApiRequest? = () => { }
}

/**
 * 
 * @returns Get the appropriate site endpoint for Connect Event api requests
 */
export const getSiteApiUrl = () => {
  // @ts-ignore
  let urlSlug = window.urlSlug
  if (IsNullOrEmpty(urlSlug)){
    console.error('UrlSlug not provided')
  }
  return `https://${window.location.host}/api/site/${urlSlug}`
}

/**
 * 
 * @param request Make an authorized call to the Connect API
 * @returns Requested api data. Fails on error and returns null
 */
const useApi = async (request: IConnectApiRequest) : Promise<any> => {
  // modify the api call here
  // use jwt token?
  // enable cors? 
  // const url = !IsNullOrEmpty(request.url) ? request.url : (getSiteApiUrl() + request.endpoint)
  if (IsNullOrEmpty(request.url)) {
    request.url = getSiteApiUrl() + request.endpoint
  }
  const result = await axios(request);
  if (result.status === 200) { return result.data }
  else {
    console.error(result)
  }
}

/**
 * Call an external api endpoint without including User Connect auth tokens
 * @param request typeof IConnectApiRequest
 * @returns 
 */
export const useExternalApi = async (request: IApiRequest) => {
  let result = await axios(request);
  if (result.status === 200) { return result.data }
  else {
    console.error(result)
  }
}

export default useApi