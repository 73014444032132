// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';

import routes from '../constants/routes';

const NoMeetingRedirect: React.FC = ({ children }) => {
  const history = useHistory();
  const meetingManager = useMeetingManager();

  useEffect(() => {
    if (!meetingManager.meetingSession) {
      history.push(routes.ENDED_LOBBY);
    }
  }, []);

  return <>{children}</>;
};

export default NoMeetingRedirect;
