import React, { useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import { TextareaAutosize } from '@mui/material';
import './ChatInput.scss'

type ChatInputProps = {
    onSend: (msg: string) => void
}

const ChatInput = (props: ChatInputProps) => {
    const [message, setMessage] = useState('')

    const sendMessage = () => {
        props.onSend(message)
        setMessage('')
    }

    return (
        <div className="chat-input">
            <TextareaAutosize
                aria-label="Enter a message"
                className="chat-input"
                minRows={1}
                maxRows={5}
                placeholder="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
            />
            <IconButton color="primary" onClick={sendMessage}>
                <SendIcon />
            </IconButton>
        </div>
    )
}

export default ChatInput