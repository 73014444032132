import React, { createContext, useContext, useEffect, useState } from "react";
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { useAppSelector } from "../..";
import { SpotlightUserState } from "../../store";

type ProviderType = PubNub | undefined

const initialValue: ProviderType = undefined

export const SofoPubnubContext = createContext(initialValue);

export const SofoPubnubProvider = ({ children }: any) => {

    const [client, setClient] = useState<ProviderType>()
    const user: SpotlightUserState = useAppSelector(x => x.user)

    useEffect(() => {
        if (user.messaging && !client) {
            let pubnubCreds: PubNub.PubnubConfig = {
                publishKey: user.messaging.publishKey,
                subscribeKey: user.messaging.subscribeKey,
                uuid: user.accountId
            }
            const pubnub = new PubNub(pubnubCreds);
            setClient(pubnub)
        }
    }, [user]) // Triggered if the user state is changed

    return (
        <SofoPubnubContext.Provider value={client}>
            {client &&
                <PubNubProvider client={client}>
                    {children}
                </PubNubProvider>
            }
        </SofoPubnubContext.Provider>
    );
};

export const usePubnubContext = () => useContext(SofoPubnubContext);
