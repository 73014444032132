import { SpotlightAPIClientConfig } from './types';

export abstract class Base {
    private apiKey?: string
    private basePath: string
    private clientId: string
    private profileId: string

    constructor(config: SpotlightAPIClientConfig) {
        this.configure(config)
    }

    configure = (config: SpotlightAPIClientConfig) => {
        this.apiKey = config.apiKey
        this.basePath = config.basePath
        this.clientId = config.clientId
        this.profileId = config.profileId
    }

    getApiBasePath() {
        return this.basePath
    }

    async request<T>(endpoint: string, options?: RequestInit): Promise<T> {
        const url = this.basePath + endpoint
        const headers = {
            'api-key': this.apiKey,
            'client-id': this.clientId,
            'Content-type': 'application/json',
            'profile-id': this.profileId
        }

        const config = {
            ...options,
            headers,
        }

        var response = await fetch(url, config)
        if (response.ok) {
            try {
                var data = await response.json()
                return data
            } catch (err) {
                return // Ignore empty bodies unable to be parsed
            }
        }
        throw new Error(response.statusText)

    }
}