export enum API_REQUEST_METHOD {
    DELETE = 'DELETE',
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT'
}

/**
 * Expected api request details
 */
export type IApiRequest = {
    data?: any // Data is automatically serialized so don't preemptively stringify
    endpoint?: string
    method: API_REQUEST_METHOD
    url?: string
}

/**
 * WebRequest handler for React requests using a hook lookup.
 */
 export type ApiHookResponse = {
    /**
     * Error data
     */
    error? : any,
    /**
     * Source error message for failed request
     */
    errorMessage?: any
    /**
     * Successful webrequest result data
     */
    data: any,
    /**
     * Is the request in progess awaiting an Http response?
     */
    loading: boolean,

}