import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchVideo } from '../../store/slices/spotlightSlice';
import { loadProgress } from '../../store';
import { ApiHookResponse } from '../../api/index';
import { UserProgressSummaryRequest } from '../../SpotlightAPIClient/engagement/types';
import { SpotlightPresentationMeta } from '../../types/presentations/SpotlightPresentation';
import SpotlightAPIClient from '../../SpotlightAPIClient';
import useInterval from '../../hooks/useIntervalHook';
import preparePlayers  from '../../utils/players/simulatedLivePlayer';
import moment from 'moment';
import "../../utils/players/simulatedLivePlayer.scss"

export type VideoPlayerProps = {
    presentationId: string
}

const VideoPlayer = (props: VideoPlayerProps) => {

    const { presentationId } = props

    const dispatch = useAppDispatch()
    const defaultThumbnailUrl = useAppSelector(x => x.config.settings?.defaultThumbnailURL)
    const selectedVideo = useAppSelector(x => x.spotlight.selectedVideo)
    const selectedPresentation: SpotlightPresentationMeta = useAppSelector(x => x.spotlight.presentations?.find(x => x.id === presentationId))
    const apiClientConfig = useAppSelector(x => x.config.apiClientConfig)
    const apiClient = new SpotlightAPIClient(apiClientConfig)

    let playbackTicketResponse: ApiHookResponse = apiClient.getVideoPlaybackUri(presentationId)

    /**
     * How often should the app check for a user's playback progress.
     * At least every 20 seconds, or 12 times through the video's duration.
     */
    const refreshContinueWatchingDataInterval: number | null = !selectedVideo || !selectedVideo.mediasiteDuration
        ? null
        : Math.max(20000, selectedVideo.mediasiteDuration / 12)

    /**
     * Fetch User Playback details from Connect -> Mediasite Api.
     * Used to reflect a user's live progress in the Continue Watching list.
     */
    const refreshContinueWatchingData = () => {
        const loadProgressRequest: UserProgressSummaryRequest = {}
        dispatch(loadProgress(loadProgressRequest))
    }

    useInterval(refreshContinueWatchingData, refreshContinueWatchingDataInterval)

    const loadVideo = async () => {
        if (selectedVideo === undefined) {
            await dispatch(fetchVideo(presentationId))
        }
    }

    const thumbnailUrl = selectedVideo?.mediasiteThumbnailUrl || defaultThumbnailUrl
    const { data: playbackTicketUri, loading: fetchingPlaybackTicket } = playbackTicketResponse

    const playerContainerStyle = {
        backgroundImage: playbackTicketResponse.loading ? `url('${thumbnailUrl}')` : 'initial',
        backgroundSize: 'contain'
    }
 
    useEffect(() => {
        loadVideo()
        if (!selectedVideo.simLivePlayerConfigs.isOnDemand) {
            const playerConfig = {
                title: selectedVideo.mediasiteTitle,
                playerId: 'myPlayer',
                presentationUrl: selectedVideo.simLivePlayerConfigs.presentationUrl,
                start: moment.utc(selectedVideo.simLivePlayerConfigs.startUTCStr).toDate(),
                startUTCStr: selectedVideo.simLivePlayerConfigs.startUTCStr,
                end: moment.utc(selectedVideo.simLivePlayerConfigs.endUTCStr).toDate(),
                endUTCStr: selectedVideo.simLivePlayerConfigs.endUTCStr,
                isPending: selectedVideo.simLivePlayerConfigs.isPending,
                isLive: selectedVideo.simLivePlayerConfigs.isLive,
                durationInSeconds: selectedVideo.simLivePlayerConfigs.durationInSeconds,
            };
            preparePlayers(playerConfig)
        }
    }, [])

    return (
        <div className="video-container">
            {(selectedVideo.simLivePlayerConfigs.isOnDemand && !fetchingPlaybackTicket && playbackTicketUri) &&
                <div className="fluidMedia" style={playerContainerStyle}>
                    <iframe
                        allow="autoplay"
                        allowFullScreen
                        frameBorder="0"
                        sandbox="allow-same-origin allow-forms allow-scripts"
                        src={playbackTicketUri}
                        title={selectedPresentation?.title}
                    />
                </div>
            }
            {(!selectedVideo.simLivePlayerConfigs.isOnDemand) && 
                <div className="row">
                    <div className="col-md-12">
                        <div id="controlBarCover">
                            <div className="playBtnArea live-waiting noselect">
                                <div id="liveStatusDescription2" className="isLive">Live</div>
                                <div id="timeCover" className="timeCover isLive">00:00</div>
                            </div>
                            <div className="timeScrubCover isLive"></div>
                            <div id="progressHider" className="live-waiting"></div>
                            <div id="controlBarLoadingCover" className="live-waiting"></div>
                            <div id="timeHider" className="live-waiting"></div>
                        </div>
                        <div>
                            <div id="liveBadge" className="liveBadge noselect isLive">
                                <div className="content">
                                    <div className="icon"></div>
                                    <div className="liveText">LIVE</div>
                                </div>
                            </div>
                            <div id="intermissionCover" className="intermissionCover">
                                <div id="simlive-msg-wrapper">
                                    <div id="simlive-msg"></div>
                                </div>
                            </div>
                        </div>
                        <div id="customPlayerWrapper" className="playerWrapper">
                            <div className="embed-responsive embed-responsive-16by9">
                                <div id="myPlayer"></div>
                            </div>
                        </div>
                    </div>
                </div>            
            }
        </div>
    )
}


export default VideoPlayer


