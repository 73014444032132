import { configureStore } from '@reduxjs/toolkit'
import configReducer from './slices/configSlice'
import engagementReducer from './slices/engagementSlice'
import messagingReducer from './slices/messagingSlice'
import profilesReducer from './slices/profilesSlice'
import spotlightReducer from './slices/spotlightSlice'
import userReducer from './slices/userSlice'

export const store = configureStore({
  reducer: {
    config: configReducer,
    engagement: engagementReducer,
    messaging: messagingReducer,
    profiles: profilesReducer,
    spotlight: spotlightReducer,
    user: userReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch