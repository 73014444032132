import React from "react";
import { Link } from "react-router-dom";
import { buildPreviewLink } from "../../../../utils/Video";
import './PresentationSearchResult.scss'

interface ISearchContentResultsProps {
    title: string;
    presenter: string;
    playlistId?: string;
    contentId: string;
}


function SearchContentResults(props: ISearchContentResultsProps) {

    const previewLink = buildPreviewLink(props.contentId, props.playlistId)

    return(
        <div className="contentResultContainer">
            <Link to={previewLink}>
                <div className="resultItem">
                    <span className={'videoTitle lineLimit'}>{props.title}</span>
                    <span className={'presenterName lineLimit'}>{props.presenter}</span>
                </div>            
            </Link>
        </div>        
    );
}

export default SearchContentResults;