interface IVideoProgressBarProps {
    color?: string
    progress?: number | string
}
const VideoProgressBar = (props: IVideoProgressBarProps) => {
    const { progress } = props
    if (!progress || progress < 0 || progress > 99) { return <div></div> }
    const style = { backgroundColor: props.color,  width: `${progress}%` }
    return (
        <div className="progressBar">
            <div className="progressBarFill" style={style} ></div>
        </div>
    )
}

export default VideoProgressBar