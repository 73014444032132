export const IsNullOrEmpty = (value: string) => {
  return (!value || value == undefined || value == "" || value.length == 0);
}

export const createTempId = () => {
  return `temp${Date.now().toString()}`
}

export const getSiteUrl = () => {
  // @ts-ignore
  let urlSlug = window.urlSlug
  if (IsNullOrEmpty(urlSlug)){
    console.error('UrlSlug not provided')
  }
  return `https://${window.location.host}/site/${urlSlug}`
}

export const getSiteAdminUrl = () => {
  // @ts-ignore
  let urlSlug = window.urlSlug
  if (IsNullOrEmpty(urlSlug)){
    console.error('UrlSlug not provided')
  }
  return `https://${window.location.host}/admin/site/${urlSlug}`
}

export const getSiteApiUrl = () => {
  // @ts-ignore
  let urlSlug = window.urlSlug
  if (IsNullOrEmpty(urlSlug)){
    console.error('UrlSlug not provided')
  }
  return `https://${window.location.host}/api/site/${urlSlug}`
}

/**
 * Convert an ASP date string from "2022-12-12T00:00:00" to yyyy-mm-dd for HTML input Fields
 * @param longdate 
 * @returns 
 */
export const formatInputDate = (longdate: string) => {
  return new Date(longdate).toISOString().split('T')[0]
}

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
}

export const getIntegrationLoginUrl = (urlSlug: string) => {
  return `https://${window.location.host}/site/${urlSlug}/login`
}