import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { useApiClientConfig } from '.'
import SpotlightAPIClient from '../../SpotlightAPIClient'
import { FetchMessagesRequest } from '../../SpotlightAPIClient/messaging'
import { SpotlightMessage } from '../../types/messaging'
import { RootState } from '../store'

export interface MessagingState {
    configured: boolean,
    messages: SpotlightMessage[]
    subscribedChannelIds?: string[]
}

const initialState: MessagingState = {
    configured: false,
    messages: [],
    subscribedChannelIds: []
}

export const fetchPresentationChat = createAsyncThunk<string, FetchMessagesRequest, { state: RootState }>(
    'api/messaging/fetchPresentationChat',
    async (request: FetchMessagesRequest, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.fetchChatMessages(request)        
    }
)

export const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        addMessage: (state, action : PayloadAction<SpotlightMessage>) => {
            let messages = state.messages
            const index = messages.findIndex(x => x.time === action.payload.time);
            if (index > -1) {
                messages = messages.splice(index, 1)
            }
            messages.push(action.payload)
            state.messages = messages
        },
        addMessages: (state, action: PayloadAction<SpotlightMessage[]>) => {
            const existingMessages = state.messages
            const allMessages = existingMessages.concat(action.payload)
            const uniqueMessages = _.uniqBy(allMessages, x => x.time)
            state.messages = uniqueMessages
        },
        setMessages: (state, action: PayloadAction<SpotlightMessage[]>) => {
            state.messages = action.payload
        },
        setConfigured: (state, action: PayloadAction<boolean>) => {
            state.configured = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPresentationChat.fulfilled, (state, action : PayloadAction<any>) => {
            state.messages = state.messages.concat(action.payload)
        })
    }
})

export const {
    addMessage,
    addMessages,
    setConfigured,
} = messagingSlice.actions

export default messagingSlice.reducer

