/** Safe lazy way to find out if a value is provided and not an empty string */
export const IsNullOrEmpty = (value: string) => {
    return (!value || value == undefined || value == "" || value.length == 0);
}

export const isNumeric = (value: any) => {
    return !isNaN(Number(value)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(value)) // ...and ensure strings of whitespace fail
}

export const goToExternalPage = (url: string) => {
    window.location.href = url
}

export const openTab = (url: string) => {
    window.open(url, '_blank').focus();
}

/** Combines a list of css class names to the expected format for React className */
export const cssClass = (...c: string[]): string => {
    c = c.filter(x => x !== undefined)
    return c.join(" ")
}
