import _ from 'lodash'
import React from 'react'
import { fetchQuestionnaire, submitUserQuestionnaire } from '../../../../api/ce/questionnairesAPI';
import { CEQuestion, CEQuestionnaire, CEQuestionType, ICEUserQuestionnaireSubmission, IScoreQuestionnaireResponse } from '../../../../models/ce'
import { CEUserQuestionResponse } from '../../../../models/ce/index';
import { Image, Modal } from 'react-bootstrap'
import ResultsSummary from './ResultsSummary';
import QuestionnaireTakerQuestion from './QuestionnaireTakerQuestion';
import { IsNullOrEmpty } from '../../../../helpers/index';

interface IQuestionnaireTakerProps {
    id: string
    onClose?: () => void
    onComplete?: () => void
    questionnaire?: CEQuestionnaire
    showTitle?: boolean
}
interface IQuestionnaireTakerState {
    curAttempt: number
    inProgress: boolean
    responses: CEUserQuestionResponse[]
    questions: CEQuestion[]
    questionnaire?: CEQuestionnaire
    submissionResult?: IScoreQuestionnaireResponse
    resultsModalOpen: boolean
}

class QuestionnaireTaker extends React.Component<IQuestionnaireTakerProps, IQuestionnaireTakerState> {
    constructor(props: IQuestionnaireTakerProps) {
        super(props)
        this.state = {
            curAttempt: 0,
            inProgress: true,
            responses: [],
            resultsModalOpen: false,
            questions: null,
        }
    }

    async componentDidMount() {
        let questionnaire = this.props.questionnaire
        if (!questionnaire) {
            questionnaire = await fetchQuestionnaire(this.props.id)
        }
        let questions = _.sortBy(questionnaire.questions, x => x.order)
        this.setState({ questionnaire, questions })
    }

    handleCompletedQuestionnaire = () => {
        console.log('handle completed questionnaire')
        this.props.onComplete && this.props.onComplete()  
        this.handleHideResults()      
    }

    handleHideResults = () => {
        console.log('handle hide results')
        if (this.state.resultsModalOpen) {
            this.setState({ resultsModalOpen: false })
            // this.props.onComplete && this.props.onComplete()
            this.props.onClose && this.props.onClose()
        }
    }

    handleShowResults = () => { this.setState({ resultsModalOpen: true }) }


    storeResponse = (response: CEUserQuestionResponse) => {
        let responses = this.state.responses
        let index = responses.findIndex(x => x.ceQuestionId == response.ceQuestionId)
        if (index !== -1) {
            responses.splice(index, 1)
        }
        responses.push(response)
        this.setState({ responses })
    }

    handleSubmitQuestionnaire = async () => {
        const { responses, questionnaire } = this.state
        const submissionRequest: ICEUserQuestionnaireSubmission = {
            questionnaireId: questionnaire.id,
            answers: responses
        }
        const submissionResult = await submitUserQuestionnaire(submissionRequest)
        this.setState({ inProgress: false, submissionResult })
        this.handleShowResults()
    }

    retakeQuestionnaire = () => {
        this.setState({
            inProgress: true,
            responses: [],
            resultsModalOpen: false
        })
    }

    renderLoading = () => {
        return (
            <div>Loading...</div>
        )
    }

    render() {
        if (!this.state.questionnaire) { return this.renderLoading() }
        let submittable = true
        let requiredQuestions = this.state.questionnaire.questions.filter(x => x.required)
        for (let i = 0; i < requiredQuestions.length; i++) {
            let userResponse = this.state.responses.find(x => x.ceQuestionId === requiredQuestions[i].id)
            if (!userResponse) { submittable = false }
            else if (requiredQuestions[i].responseType == CEQuestionType.OpenForm && IsNullOrEmpty(userResponse.text)) {
                submittable = false
            }
        }

        return (
            <div className="questionnaire-taker">
                {this.state.questionnaire.bannerUrl &&
                    <Image src={this.state.questionnaire.bannerUrl} fluid />
                }
                <br />
                <br />
                {this.props.showTitle && <h4>{this.state.questionnaire.title}</h4>}
                {this.state.questions && this.state.questions.map((q, index) => {
                    const userResponse = this.state.responses.find(r => r.ceQuestionId === q.id)
                    return (
                        <QuestionnaireTakerQuestion
                            key={q.id}
                            attemptNum={this.state.curAttempt}
                            onSelect={this.storeResponse}
                            order={index + 1}
                            question={q}
                            response={userResponse}
                        />
                    )
                })}
                <div className="mt-3 form-group">
                    <button
                        type="button"
                        className="btn btn-primary px-5"
                        disabled={!submittable}
                        onClick={this.handleSubmitQuestionnaire}
                    >Submit Answers</button>
                </div>
                <Modal
                    backdrop='static'
                    bsPrefix={'modal sofo-modal-backdrop'}
                    show={this.state.resultsModalOpen}
                    centered
                    onHide={this.state.submissionResult?.passed ? this.handleCompletedQuestionnaire : this.handleHideResults}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.submissionResult?.passed ? 'Success!' : 'Sorry'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <ResultsSummary
                            {...this.state.submissionResult}
                            handleClose={this.handleHideResults}
                            handleCompleted={this.handleCompletedQuestionnaire}
                            handleRetake={this.retakeQuestionnaire}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}


export default QuestionnaireTaker