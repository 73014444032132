import React from 'react'
import { Form } from 'react-bootstrap'

interface IPresentationSelectorProps {
    label: string
    onSelect: (id: string) => void
    presentations?: Map<string, string>
    value?: string
}

const PresentationSelector = (props: IPresentationSelectorProps) => {
    const NO_PRESENTATION_VALUE = 'No Presentation'
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value == NO_PRESENTATION_VALUE) { return props.onSelect(undefined) }
        props.onSelect(e.target.value)
    }
    return (
        <Form.Group>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control as="select" custom value={props.value || undefined} onChange={handleChange}>
                <option>{NO_PRESENTATION_VALUE}</option>
                {props.presentations &&
                    Object.keys(props.presentations).map(x =>
                        <option key={x} value={x.toString()}>{props.presentations[x]}</option>
                    )
                }
            </Form.Control>
        </Form.Group>
    )
}

export default PresentationSelector