import React from 'react'
import { uploadFile } from '../api/uploads/uploadApi'

interface IFormFileUploaderProps {
    onDelete: () => void
    onUpdate: (key: string, url: string) => void
    src: string
}
interface IFormFileUploaderState {
    loading: boolean
    // src: string
}

class FormFileUploader extends React.Component<IFormFileUploaderProps, IFormFileUploaderState> {
    constructor(props: IFormFileUploaderProps) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    uploadUserFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        var file = e.target.files[0]
        let result = await uploadFile(file)
        this.props.onUpdate(result.s3Key, result.presignedDownloadUrl)
    }

    render() {
        return (
            <div>
                <input type="file" onChange={this.uploadUserFile} />
                {this.props.src &&
                    <img src={this.props.src} className="img-thumbnail brand-image-preview"></img>
                }
            </div>
        )
    }
}

export default FormFileUploader