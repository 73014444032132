import React, { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import HeartIconEmpty from '@mui/icons-material/FavoriteOutlined'
import { useTheme } from "@mui/material"
import './FavoriteButton.scss'


type FavoriteButtonProps = {
    liked?: boolean
    onLike: any
    onUnlike: any
}

const FavoriteButton = (props: FavoriteButtonProps) => {
    const [liked, setLiked] = useState<boolean>(props.liked || false)
    const theme = useTheme()

    const handleClick = (event: React.MouseEvent) => {
        // TODO should this always be a blocking event? Maybe make it a prop decision
        event.preventDefault()
        event.stopPropagation()
        if (!liked) { props.onLike() }
        else { props.onUnlike() }
        setLiked(!liked)
    }

    useEffect(() => {
        setLiked(props.liked)
    }, [props.liked])

    let styles = [
        {
            ":hover svg": {
                color: `${theme.palette.primary.main}!important`
            }
        },
        liked && {
            display: 'flex!important',
        },
        liked && {
            "& svg": {
                color: `${theme.palette.primary.main}!important`
            }
        }
    ]

    const label = 'Add presentation to your Favorites'
    return (
        <IconButton
            aria-label={label}
            className="favBtn"
            data-favorited={liked ? true : null}
            onClick={handleClick}
            sx={styles}
            title={label}
        >
            <HeartIconEmpty />
        </IconButton>
    )
}

export default FavoriteButton