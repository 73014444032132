import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IAppConfig from '../../config/AppConfig';
import { InitializationConfig } from '../../config/InitializationConfig';
import { SpotlightAPIClientConfig } from '../../SpotlightAPIClient';
import { Colors } from '@sonic-foundry/mediasite-ui-react'

// Define a type for the slice state
export interface ConfigState extends IAppConfig {
    apiClientConfig: SpotlightAPIClientConfig
    /** Have the configuration settings been loaded? Prevent premature rendering/api calls if false */
    configured?: boolean
    features: {
        useChat: boolean
    }
}
const initialState: ConfigState = {
    apiClientConfig: undefined,
    baseApiUrl: undefined,
    baseAppUrl: undefined,
    clientId: undefined,
    configured: false,
    playlistId: undefined,
    features: {
        useChat: true
    },
    settings: {
        commentsEnabled: false,
        continueWatchingEnabled: false,
        defaultThumbnailURL: undefined,
        favoritesEnabled: false,
        featuredVideosEnabled: false,
        likesEnabled: false,
        recommendedVideosEnabled: false,
        relatedVideosEnabled: false,
        spotlightEnabled: false,
        spotlightFontColor: 'whitesmoke',
        upcomingVideosEnabled: false,
    },
    theme: {
        buttonColor: Colors.Grey5,
        buttonColorSecondary: Colors.Orange,
        fontColor: Colors.Grey5,
        linkColor: Colors.BrightBlue,
    },
    platformSettings: {
        enableAddToCalendar: false
    }
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setApiClientConfiguration: (state, action: PayloadAction<SpotlightAPIClientConfig>) => {
            state.apiClientConfig = action.payload
        },
        /** Set the required sdk config settings for UI/api requests */
        setConfiguration: (state, action: PayloadAction<InitializationConfig>) => {
            const config = action.payload
            state.apiClientConfig = {
                apiKey: 'dev',
                basePath: action.payload.baseApiUrl,
                clientId: action.payload.clientId,
                profileId: action.payload.user.profileId
            }
            state.baseApiUrl = config.baseApiUrl
            state.baseAppUrl = config.baseAppUrl
            state.clientId = config.clientId
            state.playlistId = config.playlistId ? config.playlistId : undefined
            state.settings = config.settings
            state.urlSlug = config.urlSlug
            state.configured = true
            state.features.useChat = action.payload.features.useChat
            state.theme = action.payload.theme
            state.platformSettings = action.payload.platformSettings
        },
    }
})

export const {
    setApiClientConfiguration,
    setConfiguration
} = configSlice.actions


/**
 * Get the required config options for using the consolidated Spotlight API client
 * @param state Redux State
 * @returns 
 */
export const useApiClientConfig = (state: ConfigState): SpotlightAPIClientConfig => ({
    apiKey: 'dev',
    basePath: state.baseApiUrl,
    clientId: state.clientId,
    profileId: state.userId
})

export default configSlice.reducer