import { IconButton, SvgIcon } from "@mui/material";
import React, { useState } from "react";
import SimpleChatRoom from "../Messaging/SimpleChatRoom/SimpleChatRoom";
import './EngagementSidebar.scss'
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SimpleQA from "../Messaging/SimpleQA/SimpleQA";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//@ts-ignore
import QAIcon from '../../Icons/QAIcon.svg'

const ENGAGEMENT_VIEWS = {
    CHAT: 0,
    QA: 1,
}
type EngagmentSidebarProps = {
    chatRoomId: string
    /** Is the chat room fixed to the window or normal page content. */
    fixed?: boolean
    useChat: boolean
    useQA: boolean
    /** Optional callback for modifying layout in parent components */
    onCollapse?: (collapsed: boolean) => void
    onCollapseQA?: (collapsedQA: boolean) => void
    onCollapseChat?: (collapsedChat: boolean) => void

}

const EngagementSidebar = (props: EngagmentSidebarProps) => {

    const useChat = props.useChat
    const useQA = props.useQA;
    const [collapsed, setCollapsed] = useState<boolean>(false)
    const [view, setView] = useChat ? useState(ENGAGEMENT_VIEWS.CHAT) : useState(ENGAGEMENT_VIEWS.QA)
    const [collapsedQA, setCollapsedQA] = useState<boolean>(true)
    const [collapsedChat, setCollapsedChat] = useState<boolean>(true)

    const openChat = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { 
        e.stopPropagation(); 
        setView(ENGAGEMENT_VIEWS.CHAT); 
        setCollapsed(false); 
        if (props.onCollapse) {
            props.onCollapse(false)
        }
    }
    const closeChatSidebar = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setCollapsedChat(true);
        if (props.onCollapseChat) {
            props.onCollapseChat(true)
        }
    }

    const openQA = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { 
        e.stopPropagation(); 
        setView(ENGAGEMENT_VIEWS.QA); 
        setCollapsed(false); 
        if (props.onCollapse) {
            props.onCollapse(false)
        }
    }
    const closeQASidebar = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setCollapsedQA(true);
        if (props.onCollapseQA) {
            props.onCollapseQA(true)
        }
    }

    const closeSidebar = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setCollapsed(true);
        if (props.onCollapse) {
            props.onCollapse(true)
        }
    }

    return (
        <div
            className="engagement-sidebar"
            data-collapsed={collapsed || null}
            data-fixed={props.fixed ? true : null}
        >
            <div className="engagement-sidebar-buttons">
                {useChat &&
                    <IconButton
                        aria-label={collapsed ? 'Open Chat' : 'Hide Chat'}
                        onClick={(e) => { collapsedChat ? openChat(e) : closeChatSidebar(e) }}
                    >
                        <ChatBubbleOutlineIcon />
                    </IconButton>
                }
                {useQA &&
                    <IconButton
                        aria-label={collapsed ? 'Open Q&A' : 'Hide Q&A'}
                        onClick={(e) => { collapsedQA ? openQA(e) : closeQASidebar(e) }}
                    >
                        <span style={{ fontSize: 17 }}>
                            <QAIcon />
                        </span>
                    </IconButton>
                }              
                {!collapsed && 
                    <IconButton onClick={(e) => { collapsed ? openChat(e) : closeSidebar(e) }}>
                        <ChevronRightIcon />
                    </IconButton>
                }
                
            </div>
            <div className="engagement-sidebar-views">
                {(view == ENGAGEMENT_VIEWS.CHAT  && useChat) &&
                    <SimpleChatRoom roomId={props.chatRoomId} />
                }
                {(view == ENGAGEMENT_VIEWS.QA && useQA) &&
                    <SimpleQA />
                }
            </div>
        </div>
    )
}

export default EngagementSidebar