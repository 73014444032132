import React from 'react'
import { Box, Grid } from '@mui/material'
import FeaturedCarousel from './components/FeaturedCarousel'
import SpotLightCarousel from './components/SpotlightedCarousel'
import { useAppSelector, useFeaturedPresentations, useSpotlightedPresentations } from '../../store';

import './SpotlightFocus.scss'
import SearchBar from '../SearchBar';
import { SpotlightPresentation } from '../../types/presentations/SpotlightPresentation';


export type ISpotlightFocusProps = {
    id?: string
    backgroundImageDisplayStyle?: string
    backgroundImageURL?: string
    featuredListTitle?: string
    showFeatured: boolean
    showSpotlighted: boolean
}


export interface ISpotlightFocusState {
    playingVideo: boolean
    selectedVideo: any
}

const SpotlightFocus = (props: ISpotlightFocusProps) => {
    var sectionStyle = {
        width: "100%",
        height: "100%",
        background: `url(${props.backgroundImageURL})`,
        backgroundSize: '100% 100%'
    };
    if (props.backgroundImageDisplayStyle) {
        sectionStyle.backgroundSize = props.backgroundImageDisplayStyle
    }

    const spotlightFontColor = useAppSelector(x => x.config.settings.spotlightFontColor)
    const playlistId = useAppSelector(x => x.spotlight.playlistId)
    const spotlightState = useAppSelector(x => x.spotlight)
    const featuredPresentations: SpotlightPresentation[] = useFeaturedPresentations(spotlightState)
    const spotlightedPresentations: SpotlightPresentation[] = useSpotlightedPresentations(spotlightState)

    const containerStyle = props.backgroundImageURL ? sectionStyle : { backgroundColor: '#25282A' }

    return (
        <Box
            id={props.id}
            className="spotlight-focus-container"
            style={containerStyle}
            sx={{
                '& .video-preview-thumb': {
                    '& .title,.subDescription': {
                        color: spotlightFontColor
                    }
                }
            }}>
            <div className='search-wrapper'>
                <SearchBar playlistId={playlistId} />
            </div>
            <Grid
                className='spotlight-focus-container-grid'
                container
                spacing={2}
            >
                <Grid item xs={11} md={10}>
                    {(props.showSpotlighted && spotlightedPresentations?.length > 0) &&
                        <SpotLightCarousel
                            items={spotlightedPresentations}
                        />
                    }
                    <br />
                    {(props.showFeatured && featuredPresentations?.length > 0) &&
                        <FeaturedCarousel
                            items={featuredPresentations}
                        />
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default SpotlightFocus
