import React from 'react'
import { IQuestionnaireQuestion } from "../../../../models/ce"

type IQuestionnaireComposerQuestionSummaryProps = {
    namePrefix?: string
    onDelete: ((questionId: string) => void)
    onDraft: ((questionId: string) => void)
    question: IQuestionnaireQuestion
}
export default class QuestionnaireComposerQuestionSummary extends React.Component<IQuestionnaireComposerQuestionSummaryProps> {
    constructor(props: IQuestionnaireComposerQuestionSummaryProps) {
        super(props)
        this.state = {
            question: this.props.question,
        }
    }
    render() {
        const { question, onDelete, onDraft } = this.props
        return (
            <div className="form-row m-0">

                <div className="col form-group" onClick={() => {onDraft(question.id)}}>
                    <input type="text"
                        className="form-control"
                        defaultValue={question.text}
                        disabled                        
                        placeholder={question.text}
                    />
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-sm btn-secondary ml-3"
                        onClick={() => { onDraft(question.id) }}
                        style={{ lineHeight: 1 }}
                    >
                        <span className="material-icons-outlined">edit</span>
                    </button>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-sm btn-secondary ml-3"
                        onClick={() => { onDelete(question.id) }}
                        style={{ lineHeight: 1 }}
                    >
                        <span className="material-icons-outlined">delete_outline</span>
                    </button>
                </div>
            </div>
        )
    }
}