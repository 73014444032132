import axios from 'axios'
import { getSiteApiUrl } from '../../helpers'

const getPresignedUploadUrl = async (file : IHtmlFile, connectFileType: number) : Promise<any> => {
    let uploadUrl = getSiteApiUrl() + '/uploads'
    let uploadRequestData : IUploadRequest = {
        StorageType: connectFileType,
        FileName: file.name,
    }
    let result = await axios.post(uploadUrl, uploadRequestData)
    if (result.status === 200) { return result.data }
    // handle error
    console.error(result)
}

export const uploadFile = async (file : IHtmlFile) : Promise<IUploadResult> => {
    const presignedUrlResponse : IUploadResult = await getPresignedUploadUrl(file, 0)
    const uploadResult = await axios.put(presignedUrlResponse.presignedUploadUrl, file)
    if (uploadResult.status === 200) {
        return presignedUrlResponse
    }
}

interface IHtmlFile extends Blob  {
    name: string
    type: string
}

interface IUploadRequest {
    StorageType: any
    FileName: string
}
interface IUploadResult {
    presignedDownloadUrl: string
    presignedUploadUrl: string
    s3Key: string
}