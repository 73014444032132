import { ApiHookResponse } from '../../api';
import useAxiosFetch from '../../hooks/useAxiosFetchHook';
import { SpotlightPresentation } from '../../types/presentations/SpotlightPresentation'
import { Base } from '../base'

const resourceName = 'presentations'

export class PresentationsAPI extends Base {

    async getAllPresentations(request: FetchAllPresentationsRequest): Promise<SpotlightPresentation[]> {
        const endpoint = request.playlistId
            ? `playlist/${request.playlistId}/${resourceName}`
            : `${resourceName}`;
        return await this.request(endpoint)
    }

    async getPlaylistPresentations(playlistId: string) : Promise<SpotlightPresentation[]> {
        return await this.request(`playlists/${playlistId}/${resourceName}`)
    }

    async getPresentation(presentationId: string): Promise<SpotlightPresentation> {
        return await this.request(`${resourceName}/${presentationId}`)
    }

    async getDetailedPresentation(presentationId: string): Promise<SpotlightPresentation> {
        return await this.request(`${resourceName}/${presentationId}?includeDetails=true`)
    }

    /** Get an object with lists of presentation ids that are spotlighted/featured */
    async getSpotlightedPresentationIds(request: GetSpotlightedPresentationIdsRequest) : Promise<GetSpotlightedPresentationIdsResponse> {
        const endpoint = request.playlistId
            ? `playlist/${request.playlistId}/${resourceName}/spotlighted`
            : `${resourceName}/spotlighted`;
        return await this.request(endpoint)
    }

    /**
     * Fetch the Mediasite playback ticket for a presentation
     * @param presentationId Mediasite Presentation Id
     * @returns {string} Playback URI
     */
    async getPresentationPlaybackUri(presentationId: string) : Promise<string> {
        return await this.request(`playback/${presentationId}/ticket`)
    }
    /**
     * Fetch the Mediasite playback ticket for a presentation
     * @param presentationId Mediasite Presentation Id
     * @returns {string} Playback URI
     */
    getVideoPlaybackUri(presentationId: string): ApiHookResponse {
        let basePath = this.getApiBasePath()
        const url = `${basePath}playback/${presentationId}/ticket`
        return useAxiosFetch(url, 15000)
    }

    /**
     * Search for matching presentations. Optionally filter to a playist by id
     * @param request 
     * @returns 
     */
    async searchPresentations(request: SearchPresentationsRequest) : Promise<PresentationSearchResponse> {
        let endpoint = request.playlistId
            ? `playlist/${request.playlistId}/search`
            : `search`;
        endpoint += `?query=${encodeURIComponent(request.query)}`
        return await this.request(endpoint)
    }

}

export type FetchAllPresentationsRequest = {
    playlistId?: string
}
export type GetSpotlightedPresentationIdsRequest = {
    playlistId?: string
}
export type GetSpotlightedPresentationIdsResponse = {
    /** Presentation ids to be shown in the spotlight featured section */
    featuredIds: string[]
    /** Presentation ids to be shown in the main spotlight section */
    spotlightedIds: string[]
}

export type SearchPresentationsRequest = {
    limit?: number
    playlistId?: string
    query: string
}
export type PresentationSearchResultItem = {
    id: string
    presenters: string[] // How do we maintain ordering?
    title: string
}
export type PresentationSearchResponse = PresentationSearchResultItem[]