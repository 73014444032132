import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';
import { useApiClientConfig } from './configSlice';
import SpotlightAPIClient from '../../SpotlightAPIClient';
import { SpotlightPresentation, SpotlightPresentationMeta } from '../../types/presentations/SpotlightPresentation';
import { InteractionSummaryResponse, UserProgressSummary } from '../../SpotlightAPIClient/engagement/types';
import { FetchAllPresentationsRequest, GetSpotlightedPresentationIdsRequest, GetSpotlightedPresentationIdsResponse, PresentationsAPI } from '../../SpotlightAPIClient/presentations';

export type SpotlightState = {
    content: SpotlightPresentation[]
    featured: SpotlightPresentation[]
    /** Presentation ids to be shown in the spotlight featured section */
    featuredIds: string[]
    playlistId?: any
    playlist?: any
    playingVideo: boolean
    presentations: SpotlightPresentationMeta[]
    previewOpen: boolean
    progress: { [key: string]: number } // Mapped presId: progressItem
    related: SpotlightPresentation[]
    selectedVideo?: SpotlightPresentation
    spotlighted: SpotlightPresentation[]
    /** Presentation ids to be shown in the main spotlight section */
    spotlightedIds: string[]
}


// Define the initial state using that type
const initialState: SpotlightState = {
    content: undefined,
    featured: undefined,
    featuredIds: undefined,
    playingVideo: false,
    presentations: undefined,
    previewOpen: false,
    progress: undefined,
    related: [],
    spotlighted: [],
    spotlightedIds: undefined
}

/**
 * Fetch and set the spotlighted/featured presentation ids
 */
export const fetchSpotlighted = createAsyncThunk<GetSpotlightedPresentationIdsResponse, GetSpotlightedPresentationIdsRequest, { state: RootState }>(
    'presentations/fetchSpotlightedIds',
    async (request: GetSpotlightedPresentationIdsRequest, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.getSpotlightedPresentationIds(request)
    }
)

/**
 * Fetch a listing of basic details for all the Spotlight Client
 * presentations
 */
export const fetchAllContent = createAsyncThunk<SpotlightPresentation[], FetchAllPresentationsRequest, { state: RootState }>(
    'presentations/fetchAll',
    async (request: FetchAllPresentationsRequest, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.getAllPresentations(request)
    }
)

/**
 * Fetch all details for a Mediasite Presentation from the Api
 */
export const fetchVideo = createAsyncThunk<SpotlightPresentation, string, { state: RootState }>(
    'presentations/fetch',
    async (presentationId: string, thunkApi): Promise<SpotlightPresentation> => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.getDetailedPresentation(presentationId)
    }
)


export const spotlightSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setContentEngagement: (state, action: PayloadAction<InteractionSummaryResponse>) => {
            let presentations: SpotlightPresentationMeta[] = state.presentations
            if (!presentations) {
                presentations = state.content.map(x => new SpotlightPresentationMeta(x))
            }
            let favoriteCounts = action.payload.presentationFavoriteCounts
            let likeCounts = action.payload.presentationLikeCounts
            presentations = presentations.map(pres => {
                pres.numFavorites = favoriteCounts[pres.id] || 0
                pres.numLikes = likeCounts[pres.id] || 0
                return pres
            })
            state.presentations = presentations
        },
        setContentProgress: (state, action: PayloadAction<UserProgressSummary>) => {
            let presentations: SpotlightPresentationMeta[] = state.presentations
            if (!presentations) {
                presentations = state.content.map(x => new SpotlightPresentationMeta(x))
            }
            presentations = presentations.map(pres => {
                const presProgress = action.payload[pres.id]?.progress || 0
                pres.userProgress = presProgress
                return pres
            })
            state.presentations = presentations
        },
        setSelectedPresentation: (state, action: PayloadAction<SpotlightPresentation>) => {
            state.selectedVideo = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVideo.fulfilled, (state, action) => {
            state.selectedVideo = action.payload
        })
        builder.addCase(fetchSpotlighted.fulfilled, (state, action: PayloadAction<GetSpotlightedPresentationIdsResponse>) => {
            state.featuredIds = action.payload.featuredIds
            state.spotlightedIds = action.payload.spotlightedIds
        })
        builder.addCase(fetchAllContent.fulfilled, (state, action) => {
            state.content = action.payload
        })
    }
})


export const {
    setContentEngagement,
    setContentProgress,
    setSelectedPresentation
} = spotlightSlice.actions





export const useFavoritedPresentations = (state: RootState): SpotlightPresentation[] => {
    const content = state.spotlight.content
    if (!content) { return undefined }
    const presentations = content?.filter(x => state.engagement.favoritedPresentationIds
        ?.indexOf(x.mediasiteResourceId) > -1)
    return presentations
}

/** Use a list of featured presentations */
export const useFeaturedPresentations = (state: SpotlightState) : SpotlightPresentation[] => {
    if (!state.content || !state.featuredIds) { return undefined }
    return state.content?.filter(x => state.featuredIds.includes(x.mediasiteResourceId))
}

export const useLikedPresentations = (state: RootState): SpotlightPresentation[] => {
    const content = state.spotlight.content
    if (!content) { return undefined }
    const presentations = content?.filter(x => state.engagement.likedPresentationIds
        ?.indexOf(x.mediasiteResourceId) > -1)
    return presentations
}

/** Use a list of featured presentations */
export const useSpotlightedPresentations = (state: SpotlightState): SpotlightPresentation[] => {
    if (!state.content || !state.spotlightedIds) { return undefined }
    return state.content?.filter(x => state.spotlightedIds.includes(x.mediasiteResourceId))
}

export const useUnfinishedPresentations = (state: RootState): SpotlightPresentation[] => {
    const progress = state.engagement.progress
    if (!progress) { return }
    const presentations = state.spotlight.content?.filter(x => progress[x.mediasiteResourceId] !== undefined
        && progress[x.mediasiteResourceId]?.progress > 5
        && progress[x.mediasiteResourceId]?.progress < 95)
    return presentations
}

export const useUpcomingPresentations = (state: SpotlightState): SpotlightPresentation[] => {
    const now = new Date().getTime()
    return state.content?.filter(x => x.start > now)
}

export const useRecommendedPresentations = (state: SpotlightState): SpotlightPresentation[] => {
    const now = new Date().getTime()
    return state.content?.filter(x => x.start < now)
}

export default spotlightSlice.reducer