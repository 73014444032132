
import React from "react"
import MoonLoader from "react-spinners/MoonLoader"

export type LoadingIconProps = {
    centered?: boolean
    color?: string
    // icon?: string // TODO
    size?: number | string
}

const LoadingIcon = (props: LoadingIconProps) => {
    if (props.centered) {
        return <div style={{
            textAlign: 'center',
            minHeight: 40,
            width: '100%'
        }}><MoonLoader {...props} /></div>
    }
    return <MoonLoader {...props} />
}

const defaultProps = {
    centered: true,
    color: 'white',
    size: 20
}

LoadingIcon.defaultProps = defaultProps

export default LoadingIcon