import React from 'react'
import ReactDOM from 'react-dom';
import Pubnub from 'pubnub'
import { connect } from 'react-redux';

import { RootState } from '../../redux/store';
import UserAlertToast from '../../components/alerts/UserAlertToast';
import { getPubnubClient } from '../../redux/integrations/pubnub/pubnubConfig'
import { USER_ALERTS_TARGET } from '../../appTargets';


interface IUserAlertsContainerReactProps { }
interface IUserAlertsContainerReduxProps {
    profile: any
}

interface IUserAlertsContainerProps extends IUserAlertsContainerReactProps, IUserAlertsContainerReduxProps { }


interface IUserAlertsContainerState {
    openedMessages: any[]
    pubnubClient: Pubnub
    newMessages: any[]
}

class UserAlertsContainer extends React.Component<IUserAlertsContainerProps, IUserAlertsContainerState> {

    constructor(props: IUserAlertsContainerProps) {
        super(props)
        this.state = {
            openedMessages: [],
            newMessages: [],
            pubnubClient: getPubnubClient(props.profile.id)
        }
    }

    async componentDidMount() {
        const { profile } = this.props
        const { pubnubClient } = this.state
        let channels = ['connect.alerts', `event.${global.eventId || profile.eventId}.alerts`,  `user.${profile.id}`, `user.${profile.id}.test`]
        pubnubClient.subscribe({ channels, withPresence: true })
        pubnubClient.addListener({
            message: (msg) => {
                this.addNewMessage(msg)
                this.markMessageRead(msg)
            }
        })
    }

    addNewMessage = (msg: any) => {
        let { newMessages } = this.state
        newMessages.push(msg)
        this.setState({ newMessages })
    }

    markMessageRead = async (msg: any) => {
        const { pubnubClient } = this.state
        const action = {
            channel: msg.channel,
            messageTimetoken: msg.timetoken,
            action: {
                type: 'receipt',
                value: 'read'
            }
        }
        await pubnubClient.addMessageAction(action)
    }

    dismissMsg = (msg: any) => {
        let { newMessages } = this.state
        newMessages = newMessages.filter(x => x.timetoken !== msg.timetoken)
        this.setState({ newMessages })
    }

    render() {
        let { newMessages } = this.state
        return ReactDOM.createPortal(
            <div>
                {newMessages && newMessages.map(msg => {
                    return (
                        <UserAlertToast
                            key={msg.timetoken}
                            autohide
                            duration={15000}
                            message={msg.message.body}
                            time={''}
                            timetoken={msg.timetoken}
                            title={msg.message.subject}
                            onDismiss={this.dismissMsg}
                            logo={'https://via.placeholder.com/25'}
                        />
                    )
                })}
            </div>,
            document.querySelector(USER_ALERTS_TARGET)
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        profile: state.profile
    }
}

export default connect(mapStateToProps)(UserAlertsContainer)