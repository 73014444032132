import React from 'react'
import { IconButton } from "@mui/material"
import PlayArrow from '@mui/icons-material/PlayArrow'
import { Colors } from '@sonic-foundry/mediasite-ui-react'
import './PlayButton.scss'


export type PlayButtonProps = {
    color?: string
    onClick?: () => void
}

const PlayButton = (props: PlayButtonProps) => {
    const handleClick = () => {
        if (props.onClick) { props.onClick() }
    }
    return (
        <IconButton
            aria-label="Play Video"
            className="play-btn-sm"
            color="primary"
            onClick={handleClick}
            sx={{
                backgroundColor: props.color,
                ':hover,:focus': {
                    backgroundColor: props.color
                }
            }}
        >
            <PlayArrow />
        </IconButton>
    )
}

const defaultProps: PlayButtonProps = {
    color: Colors.Grey5
}
PlayButton.defaultProps = defaultProps


export default PlayButton