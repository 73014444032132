// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export const BASE_URL = `https://${window.location.host}/api/site/${global.urlSlug}`


interface MeetingResponse {
  JoinInfo: {
    Attendee: any;
    Meeting: any;
  };
}


export async function fetchMeeting(
  meetingId: string,
  name: string,
  userId: string,
  region: string
): Promise<MeetingResponse> {


  const response = await fetch(
    `${BASE_URL}/meeting/join?meetingId=${encodeURIComponent(
      meetingId
    )}&userId=${encodeURIComponent(userId)}${region ? `&region=${encodeURIComponent(region)}` : ''
    }`,
    {
      method: 'GET'
    }
  );
  const result = await response.json();

  if (result.error) {
    throw new Error(`Server error: ${result.error}`);
  }

  var joinData = {
    JoinInfo: {
      Meeting: result.meeting,
      Attendee: result.attendee
    }
  }
  return joinData;
}


export async function joinMeeting(
  meetingId: string,
  userId: string,
  region: string
): Promise<MeetingResponse> {

  const response = await fetch(
    `${BASE_URL}/meeting/join?meetingId=${encodeURIComponent(
      meetingId
    )}&userId=${encodeURIComponent(userId)}${region ? `&region=${encodeURIComponent(region)}` : ''
    }`,
    {
      method: 'GET'
    }
  );
  const result = await response.json();
  if (result.error) {
    throw new Error(`Server error: ${result.error}`);
  }

  var joinData = {
    JoinInfo: {
      Meeting: result.meeting,
      Attendee: result.attendee
    }
  }
  return joinData;
}

export function createGetAttendeeCallback(meetingId: string) {
  return async (chimeAttendeeId: string, externalUserId?: string) => {
    const attendeeUrl = `${BASE_URL}/meeting/attendee/${encodeURIComponent(externalUserId)}`
    const res = await fetch(attendeeUrl, {
      method: 'GET'
    });

    if (!res.ok) {
      throw new Error('Invalid server response');
    }

    const data = await res.json();
    return {
      name: data.name
    };
  };
}

export async function endMeeting(meetingId: string) {
  const res = await fetch(
    `${BASE_URL}/meeting/${meetingId}/end`,
    {
      method: 'POST'
    }
  );

  if (!res.ok) {
    throw new Error('Server error ending meeting');
  }
}
