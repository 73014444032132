import React from 'react'

interface IInputErrorMessageProps {
    children?: any
}

const InputErrorMessage = (props: IInputErrorMessageProps) => {
    return (
        <div className="text-danger errorMsg">
            {props.children}
        </div>
    )

}

export default InputErrorMessage