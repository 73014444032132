import axios, { AxiosResponse } from 'axios';
import { IsNullOrEmpty } from '../utils/index';
import { IApiRequest } from './types';
import {useState, useEffect} from "react";

/** Try to get the appropriate Connect Url slug and generate the Connect api endpoint. */
export const getSiteApiUrl = () => {
    // @ts-ignore
    let urlSlug = global.urlSlug
    if (IsNullOrEmpty(urlSlug)) {
        console.error('UrlSlug not provided')
    }
    return `https://${window.location.host}/api/site/${urlSlug}`
}



/**
 * Make an api request to the configured Connect Event api
 * @param request 
 * @returns Api response data. Fails on error and returns null
 */
export const useApi = async (request: IApiRequest): Promise<any> => {
    // modify the api call here
    // use jwt token?
    if (IsNullOrEmpty(request.url)) {
        request.url = getSiteApiUrl() + request.endpoint
    }
    const result = await axios(request)
    if (result.status === 200) { return result.data }
    else {
        console.error(result)
    }
}



/**
 * WebRequest handler for React requests using a hook lookup.
 */
 export type ApiHookResponse = { 
    /**
     * Error data
     */
    error? : any,
    /**
     * Source error message for failed request
     */
    errorMessage?: any
    /**
     * Successful webrequest result data
     */
    data: any,
    /**
     * Is the request in progess awaiting an Http response?
     */
    loading: boolean,

}