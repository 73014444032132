import React from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'
import { copyToClipboard, getIntegrationLoginUrl } from '../../../helpers'
import GenericConfigForm from '../Generic/GenericConfigForm'
import { IntegrationKey } from '../types'

export type FormstackConfigFormProps = {
    configFields: IntegrationKey[]
    urlSlug: string;
}

const FormstackConfigForm = (props: FormstackConfigFormProps) => {

    const { configFields, urlSlug } = props

    const hookUrl = `https://${window.location.host}/site/${urlSlug}/hooks/formstack/registration`
    const loginUrl = getIntegrationLoginUrl(urlSlug)

    const copyHookUrl = () => {
        copyToClipboard(hookUrl)
    }

    const copyLoginUrl = () => {
        copyToClipboard(loginUrl)
    }

    return (
        <div>
            <p>
                You can use Formstack to let users register for your Event outside of Connect.
                By adding a webhook to your Formstack form users can be automatically added to your Event.
            </p>
            <p>
                <b>Your Formstack form must include all of the Required Custom Fields defined below under <a href="#custom-fields-card">Custom Fields</a></b>
            </p>
            <h4>Formstack Configuration</h4>
            <ol>
                <li>To add a WebHook to your Form, navigate to the Form Settings tab &gt; Emails & Actions &gt; Advanced Settings &gt; Add a Webhook; alternatively, you can edit an existing one.  Choose the " Send Data to an External URL (WebHook)" Action option and enter your WebHook URL in the designated URL Address field.</li>
                <li>For "WEBHOOK NAME" enter any name such as Connect Integration</li>
                <li>For "WEBHOOK URL (REQUIRED)", enter the Webhook URL listed below.</li>
                <li>Check the "Post with sub-field names" box.</li>
                <li>For "POST DATA FIELD KEY", Select "Post with API-friendly field keys"</li>
                <li>For "CONTENT-TYPE" select "JSON"</li>
                <li>For "FILE/SIGNATURE URL TYPE", use "Authenticated URL (Default)</li>
                <li>For "SHARED SECRET", leave the field empty.</li>
                <li>For "HMAC KEY" use any passphrase you choose. It is used to ensure that submissions aren't manipulated before being sent to Connect.</li>
                <li>To automatically send users to Connect after registering add a submission redirect</li>
                <ul>
                    <li>In Formstack go to "Welcome & Submission Message"</li>
                    <li>Click "Add a Submission Message"</li>
                    <li>Select "Redirect to a custom URL"</li>
                    <li>Paste the Login url listed below</li>
                </ul>
            </ol>
            <h4>Connect Configuration</h4>
            <ol>
                <li>Enter the Formstack Form Id. It can be found in the url of of your Formstack form settings page.</li>
                <li>Enter the same HMAC key below.</li>
                <li>Optionally enter the Formstack embed url to include on the login page.</li>
                <li>Validate the integration
                    <ul>
                        <li>Validate the integration by submitting your Formstack Form with the email "test.formstack@mediasiteconnect.com".</li>
                        <li>Using Formstack, go to the "Submissions" tab and check for any integration failures. They will appear with a red background in the table.</li>
                        <li>If the submission for "test.formstack@mediasiteconnect.com" is successful it won't be red.</li>
                    </ul>
                </li>
                <li>If you want to include your registration form on the login page paste it into the "Embed Code" field below.</li>
            </ol>
            <div id="formstack-hooks">
                <Form.Label>Hook Url</Form.Label>
                <InputGroup>
                    <Form.Control disabled value={hookUrl} />
                    <Button onClick={copyHookUrl} variant="primary" id="copy-hook-btn">
                        Copy
                    </Button>
                </InputGroup>
                <Form.Label>Paste this url into your Formstack form configuration.</Form.Label>
                <br />
                <Form.Label>Login Url</Form.Label>
                <InputGroup>
                    <Form.Control disabled value={loginUrl} />
                    <Button onClick={copyLoginUrl} variant="primary" id="copy-login-btn">
                        Copy
                    </Button>
                </InputGroup>
                <Form.Label>Optionally, paste this url into your Formstack form submission configuration.</Form.Label>
            </div>
            <div id="formstack-config">
                <GenericConfigForm configFields={configFields} urlSlug={urlSlug} />
            </div>
        </div>
    )
}

export default FormstackConfigForm