import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useAppSelector } from '../../redux/hooks'
import ChimeMeeting from '../../integrations/chime/chimeIntegration'
import { CHIME_MEETING_TARGET } from '../../appTargets'
import { Card } from 'react-bootstrap'

const MeetingContainer = () => {
    const [meetingId, setMeetingId] = useState(undefined)
    const [presenterId, setPresenterId] = useState(undefined)
    const profile = useAppSelector(x => x.profile)

    useEffect(() => {
        let element = document.querySelector(CHIME_MEETING_TARGET)
        if (element && element instanceof HTMLElement) {
            setMeetingId(element.dataset.meetingid)
            setPresenterId(element.dataset.presenterid)
        }
    }, [])

    if (!meetingId) { return <></> }
    return (
        ReactDOM.createPortal(
            <Card>
                <Card.Header>Live Discussion</Card.Header>
                <Card.Body style={{padding: 0}}>
                    <ChimeMeeting
                        isPresenter={profile.id === presenterId}
                        meetingId={meetingId}
                        name={`${profile.first} ${profile.last}`}
                        region="us-east-1"
                        userId={profile.id} />
                </Card.Body>
            </Card>,
            document.querySelector(CHIME_MEETING_TARGET)
        )
    )
}

export default MeetingContainer