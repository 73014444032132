import { createSlice } from '@reduxjs/toolkit';
import { fetchProfile } from '../../../api/profileApi'
import { getPubnubClient } from '../../integrations/pubnub/pubnubConfig';

export interface IProfileState {
    avatar: string
    first: string
    email: string
    eventId: string
    id: string
    last: string
    subscribedChannels: []
}

const initialState: IProfileState = {
    avatar: 'https://placehold.it/100x100',
    first: undefined,
    email: undefined,
    eventId: undefined,
    id: undefined,
    last: undefined,
    subscribedChannels: []
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action: any) => {
            state.email = action.payload.email
            state.eventId = action.payload.eventId
            state.first = action.payload.first
            state.id = action.payload.id
            state.last = action.payload.last
        },
        setSubscribedChannels: (state, action: any) => {
            state.subscribedChannels = action.payload
        }
    }
})

export default profileSlice.reducer

// Actions

const { setProfile } = profileSlice.actions

export const loadProfile = () => async dispatch => {
    const profile = await fetchProfile();
    dispatch(setProfile(profile))
    if (profile) {
        window.connectUserProfile = profile
    }
    let channels = [
        `user.${profile.id}`,
        `event.${profile.eventId}`
    ]
    dispatch(profileSlice.actions.setSubscribedChannels(channels))
    const pubnubClient = getPubnubClient(profile.id)
    pubnubClient.subscribe({channels})
}