import React from 'react'
import { Box, Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import PresentationGridList from '../../components/PresentationGridList'
import { SpotlightFocus } from '../../components';
import ErrorBoundary from '../../foundation/ErrorBoundary/ErrorBoundary';
import { useAppSelector } from '../../store/hooks';
import { CONTENT_SORT_ORDERS } from '../../constants'
import {
    useFavoritedPresentations,
    useFeaturedPresentations,
    useSpotlightedPresentations,
    useUnfinishedPresentations,
    useUpcomingPresentations,
    useRecommendedPresentations
} from '../../store/slices/spotlightSlice';
import './SpotlightPage.scss'
import SearchBar from '../../components/SearchBar';

export type SpotlightPageProps = {}

export const SpotlightPage = (props: SpotlightPageProps) => {

    const rootState = useAppSelector(state => state)
    const playlistId = useAppSelector(x => x.spotlight.playlistId)
    const settings = useAppSelector(x => x.config.settings)

    let favoritedPresentations = useFavoritedPresentations(rootState)
    let featuredPresentations = useFeaturedPresentations(rootState.spotlight)
    let spotlightedPresentations = useSpotlightedPresentations(rootState.spotlight)
    let unfinishedPresentations = useUnfinishedPresentations(rootState)
    let upcomingPresentations = useUpcomingPresentations(rootState.spotlight)
    let recommendedPresentations = useRecommendedPresentations(rootState.spotlight);

    const focusEnabled = !(settings?.spotlightEnabled === false && settings?.featuredVideosEnabled === false)
    const useFocus = focusEnabled && (featuredPresentations?.length > 0 || spotlightedPresentations?.length > 0)

    return (
        <div>
            <ErrorBoundary>
                {useFocus &&
                    <SpotlightFocus
                        backgroundImageDisplayStyle={settings?.backgroundImageDisplayStyle}
                        backgroundImageURL={settings?.backgroundImageURL}
                        showSpotlighted={settings?.spotlightEnabled}
                        showFeatured={settings?.featuredVideosEnabled}
                    />
                }
                {!useFocus &&
                    <div className='search-wrapper'>
                        <SearchBar playlistId={playlistId} />
                    </div>
                }
            </ErrorBoundary>
            <Box id="page-lists">
                {(settings?.upcomingVideosEnabled) &&
                    <PresentationGridList
                        content={upcomingPresentations}
                        defaultOrder={CONTENT_SORT_ORDERS.OLDEST}
                        emptyListMessage='There are no upcoming videos at this time'
                        title="Upcoming"
                    />
                }
                {settings?.recommendedVideosEnabled &&
                    <PresentationGridList
                        content={recommendedPresentations}
                        emptyListMessage='There are no videos at this time'
                        title="Recommended"
                    />
                }
                {settings?.favoritesEnabled &&
                    <PresentationGridList
                        content={favoritedPresentations}
                        emptyListMessage='Get started by favoriting a video'
                        title="My Favorites"
                    />
                }
                {settings?.continueWatchingEnabled &&
                    <PresentationGridList
                        content={unfinishedPresentations}
                        showProgress={true}
                        title="Continue Watching"
                    />
                }
            </Box>
            {/* Nested routes like Preview/Watch modals are rendered in the outlet */}
            <Outlet />
        </div>
    )
}




export default SpotlightPage