import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import SearchIcon from '@mui/icons-material/Search';
import { useClickOutside } from 'react-click-outside-hook'
import MoonLoader from 'react-spinners/MoonLoader'
import { useDebounce } from '../../hooks/useDebounceHook'
import SearchContentResults from './components/PresentationSearchResult'
import { useAppSelector } from "../..";
import SpotlightAPIClient from "../../SpotlightAPIClient";
import { PresentationSearchResponse, PresentationSearchResultItem } from "../../SpotlightAPIClient/presentations";
import './SearchBar.scss'

interface ISearchBarProps {
  playlistId?: string;
}

const searchResultsLabel = 'Search Results'
const SearchBar = (props: ISearchBarProps) => {

  const [parentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState<PresentationSearchResultItem[]>([]);
  const playlistId = useAppSelector(x => x.config.playlistId)


  const apiClientConfig = useAppSelector(x => x.config.apiClientConfig)

  useEffect(() => {
    if (isClickedOutside) {
      setContent([]);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setSearchQuery('')
    }
  }, [isClickedOutside]);

  const searchContent = async () => {
    if (!searchQuery || searchQuery.trim() === '' || isLoading) {
      return;
    }
    setLoading(true);
    const apiClient = new SpotlightAPIClient(apiClientConfig)
    const response: PresentationSearchResponse = await apiClient.searchPresentations({ query: searchQuery, playlistId: playlistId })
    setContent(response?.length > 0 ? response : undefined);
    setLoading(false);
  }

  useDebounce(searchQuery, 700, searchContent);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  }

  return (
    <div
      className={`search-bar-container`}
      data-count={content?.length > 0 ? content.length : null}
      ref={parentRef}
    >
      <div className="input-container">
        <input
          aria-label="content-search"
          onChange={changeHandler}
          placeholder="Search"
          ref={inputRef}
          value={searchQuery}
        />
        <span className="searchIcon"><SearchIcon fontSize="large" /></span>
      </div>
      {searchQuery &&
        <div className="results-container" aria-label={searchResultsLabel}>
          {isLoading &&
            <div className="loadingWrapper">
              <MoonLoader color="#fff" size={20} />
            </div>
          }

          {(!isLoading && content?.length > 0) &&
            <div className="results">
              {content.map((cont: PresentationSearchResultItem) => (
                <SearchContentResults
                  key={cont.id}
                  contentId={cont.id}
                  playlistId={props.playlistId}
                  presenter={cont.presenters?.join(", ")}
                  title={cont.title}
                />
              ))}
            </div>
          }
        </div>
      }
    </div>
  )
}

export default SearchBar
