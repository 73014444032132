import React from 'react'
import { Grid, Skeleton } from "@mui/material"
import useWindowSize from "../../../../hooks/useWindowSize"

/** 
 * Show a list of loading presentation thumbnails 
 * while the api fetches the content 
*/
export const LoadingGridList = () => {
    const windowSize = useWindowSize()
    const width = windowSize?.width || 100
    let numItems = 2
    if (width >= 600) {
        numItems = 3
    }
    if (width >= 900) {
        numItems = 5
    }
    // let itemWidth = (width / numItems) - 10
    // let itemHeight = itemWidth * (9 / 16) -10
    return (
        <Grid container spacing={1} columns={10}>
            {Array.from({ length: numItems }, (item, index) =>
                <LoadingGridListItem
                    key={index}
                    height={'auto'}
                    width={'100%'}
                />
            )}
        </Grid>
    )
}

/** 
 * Fake presentation thumbnail skeleton to 
 * display a placeholder preview of your content before the data
 * gets loaded to reduce load-time frustration
*/
type LoadingGridListItemProps = {
    height?: number | string
    width?: number | string
}
export const LoadingGridListItem = (props: LoadingGridListItemProps) => {
    return (
        <Grid item xs={5} sm={3} md={2}>
            <Skeleton
                height={props.height || 90}
                sx={{ aspectRatio: '16/9', borderRadius: '15px' }}
                variant="rectangular"
                width={props.width || '100%'}
            />
        </Grid>
    )
}

export default LoadingGridList