import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useApiClientConfig } from '.'
import SpotlightAPIClient from '../../SpotlightAPIClient'
import { FetchUserProfileRequest, FetchUserProfileResponse } from '../../SpotlightAPIClient/user'
import { MessagingCreds } from '../../types/messaging'
import { RootState } from '../store'

// Define a type for the slice state
export interface SpotlightUserState {
    /** Mediasite Account Id */
    accountId: string
    configured: boolean
    messaging: MessagingCreds
    /** Spotlight Profile Id */
    profileId: string
}

export const loadUserProfile = createAsyncThunk<FetchUserProfileResponse, FetchUserProfileRequest, { state: RootState }>(
    'api/user/loadUserProfile',
    async (request: FetchUserProfileRequest, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.fetchUserProfile({})
    }
)

const initialState: SpotlightUserState = {
    accountId: undefined,
    configured: false,
    messaging: undefined,
    profileId: undefined,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<SpotlightUserState>) => {
            return action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadUserProfile.fulfilled, (state, action: PayloadAction<FetchUserProfileResponse>) => {
            // state.accountId = action.payload.accountId
            state.configured = true
            // state.messaging = action.payload.messaging
            // state.profileId = action.payload.profileId
        })
    }
})

export const {
    setUser
} = userSlice.actions

export default userSlice.reducer

