import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import { NewMessageRequest, SpotlightMessage } from "../../../types/messaging"
import useMessagingRoomProvider from "../MessagingRoomProvider"
import { ChatMessage } from "../ChatMessage/ChatMessage"
import ChatInput from "../ChatInput/ChatInput"
import LoadingIcon from "../../../foundation/components/LoadingIcon"
import './SimpleChatRoom.scss'
import { useAppSelector } from "../../.."
import { cssClass } from "../../../utils"

export type SimpleChatRoomProps = {
    id?: string
    className?: string
    roomId: string
}

const SimpleChatRoom = (props: SimpleChatRoomProps) => {

    /** Used to lookup the DOM element with scrollable messages */
    const scrollableMessageElementId = `${props.roomId}-msgs`

    /* Should users be able to disable automatic scrolling to the latest message? */
    const [autoScroll, setAutoScroll] = useState<boolean>(true)
    const [roomMessages, setRoomMessages] = useState<SpotlightMessage[]>()
    const currentUser = useAppSelector(x => x.user)

    /** Update the room chat messages. Invoked via the MessagingRoomProvider
     *  onUpdate callback when messages are changed 
    */
    const onRoomUpdate = (updatedMessages: SpotlightMessage[]) => {
        setRoomMessages(updatedMessages)
        if (autoScroll) {
            window.setTimeout(() => {
                var element = document.getElementById(scrollableMessageElementId)
                if (element) { element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' }) }
            }, 200) // Give react a moment to set the messages before scrolling to bottom
        }
    }

    const { publishMessage } = useMessagingRoomProvider({
        channelId: props.roomId,
        onUpdate: onRoomUpdate, // Callback for updating room with latest messages
        profileId: currentUser.profileId
    })

    const sendMessage = async (message: string) => {
        let publishRequest: NewMessageRequest = {
            message: message
        }
        await publishMessage(publishRequest)
    }

    return (
        <div id={props.id} className={cssClass('sofo-engage', props.className)}>
            <div className="header">
                <Typography variant="h5">Chat</Typography>
            </div>
            <div id={scrollableMessageElementId} className="messages">
                {!roomMessages && <LoadingIcon />}
                {roomMessages && roomMessages.map(message => (
                    <ChatMessage
                        key={message.time}
                        currentUserProfileId={currentUser.profileId}
                        message={message}
                    />
                ))}
            </div>
            <ChatInput onSend={sendMessage} />
        </div>
    )
}

export default SimpleChatRoom