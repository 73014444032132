import { Profile } from '../../types'
import { Base } from '../base'

const resourceName = 'profiles'

export class ProfilesApi extends Base {

    async fetchProfile(request: FetchProfileRequest): Promise<FetchProfileResponse> {
        const endpoint = `${resourceName}/${request.id}`
        return this.request(endpoint)
    }

    async fetchAllProfiles(request: FetchAllProfilesRequest): Promise<FetchAllProfilesResponse> {
        const endpoint = `${resourceName}`
        return this.request(endpoint)
    }

}

export type FetchProfileRequest = {
    id?: string
}
export type FetchProfileResponse = Profile

export type FetchAllProfilesRequest = {}

export type FetchAllProfilesResponse = Profile[]