import { EngagementApi } from './engagement'
import { FavoritesAPI } from './favorites'
import { LikesApi } from './likes'
import { MessagingApi } from './messaging'
import { PresentationsAPI } from './presentations'
import { ProfilesApi } from './profiles'
import { UserApi } from './user'
import { applyMixins } from './utils'
import { Base } from './base'
export * from './types'

class SpotlightAPIClient extends Base { }
interface SpotlightAPIClient extends EngagementApi, FavoritesAPI, LikesApi, MessagingApi, PresentationsAPI, ProfilesApi, UserApi { }
applyMixins(SpotlightAPIClient, [EngagementApi, FavoritesAPI, LikesApi, MessagingApi, PresentationsAPI, ProfilesApi, UserApi]);

export default SpotlightAPIClient