// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export const rootPath = window.location.href

/**
 * These routes are used by the React-Router to handle url routing.
 * This uses an InMemory React-Router provider so the Route NavigationProvider
 * uses these values without changing the window url
 */
const routes = {
  HOME: `/`,
  DEVICE: `/devices`,
  MEETING: `/meeting`,
  ENDED_LOBBY: `/ended`
};

export default routes;
