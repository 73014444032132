import _ from 'lodash'
import React from 'react'
import { CEQuestion, CEQuestionType, CEUserQuestionResponse } from "../../../../models/ce"

const OPEN_FORM_RESPONSE_MAX_LENGTH = 2048

interface IQuestionnaireTakerQuestionProps {
    attemptNum: number
    onSelect: (response: CEUserQuestionResponse) => void
    order?: number
    question: CEQuestion
    response: CEUserQuestionResponse
}

const QuestionnaireTakerQuestion = (props: IQuestionnaireTakerQuestionProps) => {

    let { question } = props
    let options = _.sortBy(question.options, x => x.order)
    let idPrefix = question.id

    const selectAnswer = (id: string) => {
        let response = new CEUserQuestionResponse(props.question.id, id, null)
        props.onSelect(response)
    }

    const saveOpenFormAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let answer = e.target.value
        answer = answer.substr(0, OPEN_FORM_RESPONSE_MAX_LENGTH)
        let response = new CEUserQuestionResponse(props.question.id, null, e.target.value)
        props.onSelect(response)
    }

    return (
        <div className="ce-question mb-3">
            <h6>{props.order}) {question.text }<span className="text-danger">{question.required ? ' *' : ''}</span></h6>
            {question.responseType == CEQuestionType.MultipleChoice &&
                <div>
                    {options.map((option, index) =>
                        <div key={option.id + props.attemptNum} className="custom-control custom-radio">
                            <input type="radio"
                                checked={props.response?.optionId == option.id}
                                className="custom-control-input"
                                id={`${idPrefix}${index}`}
                                name={question.id}
                                onChange={() => { selectAnswer(option.id) }}
                            />
                            <label className="custom-control-label" htmlFor={`${idPrefix}${index}`}>{option.text}</label>
                        </div>
                    )}
                </div>
            }
            {question.responseType == CEQuestionType.OpenForm &&
                <div>
                    <textarea
                        className="form-control"
                        value={props.response?.text || ''}
                        maxLength={OPEN_FORM_RESPONSE_MAX_LENGTH}
                        onChange={saveOpenFormAnswer}
                    />
                </div>
            }
        </div>
    )
}

export default QuestionnaireTakerQuestion