import { createSlice } from '@reduxjs/toolkit';

export interface IMeetingsState {
    meetingId? : string
    attendeeId?: string
    connectMeetingId?: string
    meeting?: any
}

const initialState: IMeetingsState = {
    meetingId: undefined,
    attendeeId: undefined,
    connectMeetingId: undefined
}

export const meetingsSlice = createSlice({
    name: 'meetings',
    initialState,
    reducers: {
        setStoreMeetingDetails: (state, action: any) => {
            state.attendeeId = action.payload.attendeeId
            state.connectMeetingId = action.payload.connectMeetingId
            state.meetingId = action.payload.meetingId
            state.meeting = action.payload.meeting
        }
    }
})

export default meetingsSlice.reducer

// Actions

const { setStoreMeetingDetails } = meetingsSlice.actions

export const setMeetingDetails = (details: IMeetingsState) => async dispatch => {
    dispatch(setStoreMeetingDetails(details))
}