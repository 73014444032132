import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Typography } from '@sonic-foundry/mediasite-ui-react';
import Modal from '../../foundation/components/Modal'
import VideoPlayer from '../VideoPlayer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchVideo } from '../../store/slices/spotlightSlice';
import { buildSpotlightLink } from '../../utils/Video/index';
import { tryFormatDate, getUserTimezoneAbbreviation } from '../../utils/DateUtils';
import EngagementSidebar from '../EnagementSidebar';
import { buildChatRoomId } from '../../utils/Chat';
import LoadingIcon from '../../foundation/components/LoadingIcon';
import useWindowSize from '../../hooks/useWindowSize';
import SimpleChatRoom from '../Messaging/SimpleChatRoom';
import RelatedVideosCarousel from './components/RelatedVideosCarousel';

// Style Imports
import './WatchModal.scss'
import { likePresentation, unlikePresentation } from '../../store';
import LikeButton from '../../foundation/components/LikeButton';
import SimpleQA from '../Messaging/SimpleQA';

type IWatchModalProps = {}

/**
 * Videos watch modal
 * @returns  
 */
const WatchModal = (props: IWatchModalProps) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    let { playlistId, videoId } = useParams();

    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false)

    const clientId = useAppSelector(x => x.config.clientId)
    const presentation = useAppSelector(x => x.spotlight.selectedVideo)
    const useChat = useAppSelector(x => x.config.features?.useChat)
    const relatedVideosEnabled = useAppSelector(x => x.config.settings.relatedVideosEnabled);
    const userLikes: string[] = useAppSelector(x => x.engagement.likedPresentationIds)
    const likeCount: number = useAppSelector(x => x.engagement.presentationLikeCounts[presentation?.mediasiteResourceId])
    const settings = useAppSelector(x => x.config.settings)

    const chatRoomChannelId = buildChatRoomId(presentation?.id, clientId)
    const homeLink = buildSpotlightLink(playlistId)
    const windowSize = useWindowSize()
    const isDesktop = windowSize.width >= 900
    const isLiked: boolean = userLikes.find(x => x === presentation?.mediasiteResourceId) !== undefined

    useEffect(() => {
        dispatch(fetchVideo(videoId)) // User opens page on preview modal.
    }, []) // Only runs on initial render

    useEffect(() => {
        dispatch(fetchVideo(videoId)) // User opens page on preview modal.
    }, [videoId]) // Only runs on videoId changes???

    const handleClose = () => {
        navigate(homeLink)
    }

    const handleEngagementCollapse = (collapsed: boolean) => {
        window.setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 50)
    }
    const toggleLike = () => {
        if (!isLiked) {
          dispatch(likePresentation(presentation.id))
        } else {
          dispatch(unlikePresentation(presentation.id))
        }
      }

    const CloseButton = () => (
        <Link id="watch-modal-closeBtn" to={homeLink} title="Close">
            <span>Close</span>
            <div className="closeIcon">
                <IconButton >
                    <CloseIcon />
                </IconButton>
            </div>
        </Link>
    )

    return (
        <Modal
            key={videoId}
            id="watch-modal"
            closeBtnElement={<CloseButton />}
            fullScreen={false}
            onClose={handleClose}
            open
        >
            <div>
                {!presentation &&
                    <LoadingIcon />
                }
                {presentation &&
                    <div className="watch-content-wrapper">
                        <div className="pres-content" >
                            <Grid container justifyContent="center" >
                                <Grid item xs={12} lg={10} >
                                    <VideoPlayer presentationId={presentation.id} />
                                </Grid>
                                <Grid item xs={12} md={10} justifyContent="center" >
                                    <div className='watch-like-container'>
                                        <Typography className="date-details"><span>{tryFormatDate(presentation.start)} {getUserTimezoneAbbreviation()}</span></Typography>
                                        {settings.likesEnabled &&
                                            <LikeButton liked={isLiked} onLike={toggleLike} onUnlike={toggleLike} numLikes={likeCount} />
                                        }
                                    </div>                                                           
                                    <div className="video-details">
                                        <Typography variant={'h4'}>{presentation.mediasiteTitle}</Typography>
                                        <Typography variant={'h5'} >{presentation.mediasitePresenters?.join(', ')}</Typography>
                                        <Typography variant={'h6'}>{presentation.mediasiteDescription}</Typography>
                                    </div>
                                    <hr />
                                    <div className='markdown-container'>
                                        <div dangerouslySetInnerHTML={{ __html: presentation.contentMarkdown }} />
                                    </div>
                                    {(!isDesktop && useChat) &&
                                        <SimpleChatRoom
                                            key={chatRoomChannelId}
                                            roomId={chatRoomChannelId}
                                        />
                                    }
                                    <br />
                                    {(!isDesktop && presentation.enableLiveQA) &&
                                        <SimpleQA />
                                    }
                                    
                                    <br />
                                    {relatedVideosEnabled &&
                                        <div id="related-container">
                                            <RelatedVideosCarousel
                                                key={presentation.id}
                                                presentationId={presentation.id}
                                            />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        {(isDesktop && (useChat || presentation.enableLiveQA)) &&
                            <EngagementSidebar
                                key={chatRoomChannelId}
                                chatRoomId={chatRoomChannelId}
                                onCollapse={handleEngagementCollapse}
                                useChat={useChat}
                                useQA={presentation.enableLiveQA}
                            />
                        }
                    </div>
                }
            </div>
        </Modal >
    );
}

export default WatchModal