import React from 'react'
import { prettifyMilliseconds, formatDate, tryFormatDate, getSimplePresentationDate, getUserTimezoneAbbreviation } from '../../utils/DateUtils/DateUtils';

import ProgressBar from '../ProgressBar/ProgressBar';
import DurationDisplay from '../DurationDisplay/DurationDisplay';
import LikeButton from '../../foundation/components/LikeButton/LikeButton';

import './VideoPreviewThumb.scss'
import FavoriteButton from '../../foundation/components/FavoriteButton/FavoriteButton';
import PreviewInfoButton from '../../foundation/components/PreviewInfoButton';
import PlayButton from '../../foundation/components/PlayButton'
import MockVideo from '../../mocks/MockVideo'
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { favoritePresentation, unfavoritePresentation, likePresentation, unlikePresentation } from '../../store/slices/engagementSlice';
import { Link, useParams } from 'react-router-dom';
import { buildPreviewLink, buildWatchLink } from '../../utils/Video/index';
import { UserProgressSummaryItem } from '../../SpotlightAPIClient/engagement/types';
import { SpotlightPresentation } from '../../types/presentations/SpotlightPresentation';
import { Typography } from '@mui/material';
import { cssClass } from '../../utils';

export interface IContentLinkProps {
    id?: string
    classes?: string[]
    content?: SpotlightPresentation
    includeLowerDetails?: boolean
    numberOfLikes?: number
    onPreview?: (id: string, video?: SpotlightPresentation) => void
    progress?: number
    showProgress?: boolean
}

const VideoPreviewThumb = (props: IContentLinkProps) => {
    const { content } = props
    const dispatch = useAppDispatch()

    const handleFav = async () => {
        await dispatch(favoritePresentation(content.mediasiteResourceId))
    }
    const handleRemoveFav = async () => {
        await dispatch(unfavoritePresentation(content.mediasiteResourceId))
    }

    const handleLike = async () => {
        await dispatch(likePresentation(content.mediasiteResourceId))
    }
    const handleUnlike = async () => {
        await dispatch(unlikePresentation(content.mediasiteResourceId))
    }

    const formattedDate = tryFormatDate(content.start)

    const settings = useAppSelector(x => x.config.settings)
    const theme = useAppSelector(x => x.config.theme)

    const userFavs: string[] = useAppSelector(x => x.engagement.favoritedPresentationIds)
    const userLikes: string[] = useAppSelector(x => x.engagement.likedPresentationIds)
    const isFavorited: boolean = userFavs.find(x => x === content.mediasiteResourceId) !== undefined
    const isLiked: boolean = userLikes.find(x => x === content.mediasiteResourceId) !== undefined
    const likeCount: number = useAppSelector(x => x.engagement.presentationLikeCounts[content.mediasiteResourceId])

    const userProgressItem: UserProgressSummaryItem = useAppSelector(x => x.engagement.progress[content.mediasiteResourceId])

    const { playlistId } = useParams()

    const defaultThumbnailURL = useAppSelector(x => x.config.settings.defaultThumbnailURL)

    const thumbnailSrc = content?.mediasiteThumbnailUrl || defaultThumbnailURL
    const currentTime = new Date().getTime();
    let isLive = false;

    //if the content is being simulated, see if the current time is within the window of it still being played
    //if the content is actually live content, check if we're actually live at the moment, or just planning to in the future
    if (content?.mediasiteIsLive) {
        if (content?.mediasiteDuration) {
            isLive = currentTime > content?.start && currentTime < (content?.start + content?.mediasiteDuration);
        } else {
            isLive = currentTime > content?.start;
        }
    } else if (content?.isSimulated) { 
        isLive = currentTime > content?.start && currentTime < (content?.start + content?.mediasiteDuration);
    }

    let isDisplayingProgress = false;
    let progressAmount;
    if (isLive) {
        const timeProgress = currentTime - content?.start;
        const timePercentage = (timeProgress / content?.mediasiteDuration) * 100
        isDisplayingProgress = true;
        progressAmount = timePercentage;
    } else if (props.showProgress && userProgressItem) {
        isDisplayingProgress = true;
        progressAmount = userProgressItem?.progress;
    }

    const previewLink = buildPreviewLink(content.id, playlistId)
    const watchLink = buildWatchLink(content.id, playlistId)

    let componentClasses = cssClass('video-preview-thumb', ...(props.classes))

    return (
        <div id={props.id} className={componentClasses}>
            <div className="thumbWrapper">
                <img className="thumbnail" src={thumbnailSrc} />
                <Link to={previewLink} title={`Preview ${content.mediasiteTitle}`}>
                    <PreviewInfoButton />
                </Link>
                {settings.favoritesEnabled &&
                    <FavoriteButton
                        liked={isFavorited}
                        onLike={handleFav}
                        onUnlike={handleRemoveFav}
                    />
                }
                <Link to={watchLink} title={`Watch ${content.mediasiteTitle}`}>
                    <PlayButton color={theme.buttonColor} />
                </Link>
                <div className="thumbDetails">
                    {isDisplayingProgress &&
                        <ProgressBar color={theme.buttonColor} progress={progressAmount} />
                    }
                    <DurationDisplay isLive={isLive} text={isLive ? "Live" : prettifyMilliseconds(content?.mediasiteDuration)} />
                </div>
            </div>
            {props.includeLowerDetails &&
                <div className="details">
                    <div>
                        <p className="title" title={content?.mediasiteTitle}>{content?.mediasiteTitle}</p>
                        <div className="subDescription">
                            <Typography>{formattedDate} {getUserTimezoneAbbreviation()}</Typography>
                        </div>
                    </div>
                    {settings.likesEnabled &&
                        <div className="like-wrapper">
                            <LikeButton
                                liked={isLiked}
                                numLikes={likeCount}
                                onLike={handleLike}
                                onUnlike={handleUnlike}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const defaultProps: IContentLinkProps = {
    classes: [],
    content: MockVideo,
    includeLowerDetails: true,
}
VideoPreviewThumb.defaultProps = defaultProps

export default React.memo(VideoPreviewThumb)
