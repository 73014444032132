import { API_REQUEST_METHOD } from '../../api/types'
import { Base } from '../base'

const resourceName = 'likes'

/**
 * Handle Likes for Spotlight Presentations
 */
export class LikesApi extends Base {

    /**
     * Like a mediasite presentation
     * @param presentationId Mediasite Presentation Id
     * @returns 
     */
    async addLike(presentationId: string): Promise<void> {
        return this.request(`${resourceName}/${presentationId}`, {
            body: JSON.stringify({ presentationId }),
            method: API_REQUEST_METHOD.POST
        })
    }

    /**
     * Remove a like from a mediasite presentation
     * @param presentationId Mediasite Presentation Id
     * @returns 
     */
    async deleteLike(presentationId: string): Promise<void> {
        return this.request(`${resourceName}/${presentationId}`, {
            body: JSON.stringify({ presentationId }),
            method: API_REQUEST_METHOD.DELETE
        })
    }

}