import React from "react"
import { IQuestionnaireQuestionOption } from "../../../../models/ce"

const OPTION_MAX_LENGTH = 512

export interface IQuizComposerQuestionOptionProps {
    isGraded: boolean
    onDelete: ((id: string) => void)
    onUpdate: ((option: IQuestionnaireQuestionOption) => void)
    option: IQuestionnaireQuestionOption
}
export interface IQuizComposerQuestionOptionState {
    option: IQuestionnaireQuestionOption
}

export default class QuestionnaireComposerQuestionOption extends React.Component<IQuizComposerQuestionOptionProps, IQuizComposerQuestionOptionState> {
    constructor(props: IQuizComposerQuestionOptionProps) {
        super(props)
        this.state = {
            option: this.props.option
        }
    }

    deleteOption = () => {
        const { option } = this.state
        this.props.onDelete(option.id)
    }

    setCorrect = (isCorrect: boolean) => {
        let { option } = this.state
        option.isCorrect = isCorrect
        this.props.onUpdate(option)
    }

    setName = (name: string) => {
        let { option } = this.state
        option.text = name
        this.props.onUpdate(option)
    }

    render() {
        let { option } = this.state
        return (
            <div className=" mb-3 mr-5">
                <div className="form-row mb-0">
                    <div className="col">
                        <div className="input-group">
                            <input
                                className="form-control"
                                defaultValue={option.text}
                                maxLength={OPTION_MAX_LENGTH}
                                onChange={(e) => { this.setName(e.target.value) }}
                                placeholder="Add an Option"
                                aria-label="Add an Option"
                            />
                            {this.props.isGraded &&
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <input type="checkbox"
                                            defaultChecked={option.isCorrect}
                                            onChange={(e) => { this.setCorrect(e.target.checked) }}
                                            aria-label="Checkbox for following text input"
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="col-auto">
                        <button onClick={this.deleteOption} className="btn btn-sm btn-secondary ml-3" style={{ lineHeight: 1 }}>
                            <span className="material-icons-outlined">delete_outline</span>
                        </button>
                    </div>

                </div>
                {(option.text?.length >= OPTION_MAX_LENGTH) &&
                    // <small className="small text-danger">{option.text.length}/{OPTION_MAX_LENGTH}</small>
                    <small className="small text-danger">Max Length Exceeded</small>
                }
            </div>
        )
    }
}
