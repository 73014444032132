import React, { MutableRefObject, useEffect, useRef } from 'react'
import Pubnub from 'pubnub'
import { usePubNub } from 'pubnub-react'
import { GetPubnubChannelMessagesRequest, NewMessageRequest, PubnubMessage, SpotlightMessage } from '../../../types/messaging'
import _ from 'lodash'

export type IMessagingRoomProviderProps = {
    channelId: string
    profileId: string
    onUpdate: (updatedMessages: SpotlightMessage[]) => void
}

/**
 * Exposes Pubnub functionality in a reusable way so it doesn't need to be defined
 * in each messaging implementation. New message updates must be subscribed to 
 * using the onUpdate prop.
 * @param props 
 * @returns 
 */
export const useMessagingRoomProvider = (props: IMessagingRoomProviderProps) => {

    const { channelId, profileId } = props
    let pubnub: Pubnub
    try {
        pubnub = usePubNub()
    } catch (err) {
        console.warn('pubnub not ready')
    }

    /** Preserves the chat room messages for the entire hook lifetime unlike useState */
    const messagesRef: MutableRefObject<SpotlightMessage[]> = useRef([])

    useEffect(() => {
        messagesRef.current = []
        if (pubnub) {
            const subscribeParams: Pubnub.SubscribeParameters = {
                channels: [channelId],
                withPresence: false
            }
            pubnub.subscribe(subscribeParams)
            pubnub.addListener({
                message: handleNewMessage,
                presence: handlePresenceEvent,
            })
            loadMessages()
        }

        return () => {
            if (pubnub) {
                const unsubscribeParams: Pubnub.UnsubscribeParameters = {
                    channels: [channelId]
                }
                pubnub.unsubscribe(unsubscribeParams)
            }
            messagesRef.current = []
        }
    }, [channelId])

    const addMessagesToState = (newMessages: SpotlightMessage[]) => {
        let existingMessages = messagesRef.current
        let combinedMessages = newMessages.concat(existingMessages)
        if (combinedMessages && combinedMessages.length > 0) {
            let uniqueMessages = _.uniqBy(combinedMessages, x => x.time)
            let sortedMessages = _.sortBy(uniqueMessages, x => x.time)
            setMessagesState(sortedMessages)
        }
    }

    const handleNewMessage = (messageEvent: Pubnub.MessageEvent & { userMetadata: any }) => {
        // Only update messages array for messages in the provided channel
        if (channelId === messageEvent.channel) {
            const newMessage: SpotlightMessage = {
                channelId: messageEvent.channel,
                message: messageEvent.message,
                meta: messageEvent.userMetadata,
                time: messageEvent.timetoken,
                uuid: messageEvent.publisher
            }
            addMessagesToState([newMessage])
        }
    }

    /** TODO. Listen for changes to the number of users active in the room. */
    const handlePresenceEvent = (presenceEvent: Pubnub.PresenceEvent) => { }

    const loadMessages = async () => {
        const messages = await fetchAllMessages(pubnub, { channel: channelId })
        let sofoMessages: SpotlightMessage[] = messages?.map(msg => {
            return {
                channelId: channelId,
                message: msg.message,
                meta: msg.meta,
                time: msg.timetoken,
                uuid: msg.uuid
            }
        })
        addMessagesToState(sofoMessages)
    }

    const publishMessage = async (newMessage: NewMessageRequest) => {
        let request: Pubnub.PublishParameters = {
            channel: channelId,
            message: newMessage.message,
            meta: {
                profileId
            }
        }
        await pubnub.publish(request)
    }

    const setMessagesState = (messages: SpotlightMessage[]) => {
        /** Exclude messages that a moderator has flagged/removed */
        const availabledMessages = messages.filter(x => !x.meta?.unpublished)
        messagesRef.current = availabledMessages
        props.onUpdate(messagesRef.current)
    }

    return { loadMessages, publishMessage, messages: messagesRef?.current };

}

/** Iterate throught a chat room's history and receieve all messages instead of the default pubnub.fetchMessages 100 limit */
const fetchAllMessages = async (pubnub: Pubnub, args: GetPubnubChannelMessagesRequest): Promise<PubnubMessage[]> => {
    let allMessages: PubnubMessage[] = []
    let newMessages: PubnubMessage[] = []
    let start: string | number = args.start || new Date(0).getTime()
    let end: string | number = args.end || new Date().getTime()
    do {
        newMessages = await tryFetchMessages(pubnub, args.channel, start, end)
        if (newMessages?.length > 0) {
            allMessages = allMessages.concat(newMessages)
            if (newMessages?.length >= args.count) {
                let sortedResults = _.sortBy(newMessages, x => x.timetoken)
                end = sortedResults[0]?.timetoken || end
            }
        }
    } while (newMessages?.length >= args.count)
    return allMessages
}

/** Request 100 messages using start and end dates. Used to paginate through chat rooms with more than 100 messages */
const tryFetchMessages = async (pubnub: Pubnub, channelId: string, start: string | number, end: string | number): Promise<PubnubMessage[]> => {
    if (!end) { end = new Date().getTime() }
    let request: Pubnub.FetchMessagesParameters = {
        channels: [channelId],
        count: 100,
        includeMeta: true,
        start,
        end
    }
    let response: Pubnub.FetchMessagesResponse = await pubnub.fetchMessages(request)
    let results: PubnubMessage[] = response.channels[channelId]
    return results
}

export default useMessagingRoomProvider
