import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import profileReducer from './features/profile/profileSlice'
import meetingReducer from './features/meetings/meetingsSlice'


export const store = configureStore({
  reducer: {
    meetings: meetingReducer,
    profile: profileReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
