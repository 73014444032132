import * as Mediasite from './api.js';

var player;
var playerId;
var initialized = false;
var isLive = false
var isPending = false
var config;
var debugMode = window.location.href.indexOf("debug") > -1;
var DEBUG_MODE_ACTIVE = debugMode
var curTime = 0;
var pConfig;

var secondaryPlayer = null;
var secondaryVolumeEnabled = true
var SECONDARY_PLAYER_ID = 'secondaryPlayer'

var CHECK_POSITION = 'CHECK_POSITION'
var START_INTERMISSION = "Start_Intermission"

var moments = undefined

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}



function logPlayerError(eventData) {
	eventData.description = Mediasite.ErrorDescription[eventData.errorCode];
	// logEvent('error', eventData);

	if (eventData.errorCode === 231 || eventData.errorCode === 232) {
		// The Mediasite iFrame API client version does not match the 
		// API version of the server, but is *mostly* compatible. 
		// This is a warning to the app developer and it is usually 
		// best to not bother users with this information.
	} else {
		// This is an error and it's often appropriate to notify users.
		// *** ERROR HANDLING CODE HERE ***
		console.error(eventData.errorCode)
		console.error(eventData)
	}
}




/**
 * Summary. Listen for the play button to be clicked and show the control bar covers
 * to prevent users from seeing the default player controls early
 * 
 */
function listenForIframePlayBtnClick() {
	var myConfObj = {
		iframeMouseOver: false
	}
	window.addEventListener('blur', function () {
		if (myConfObj.iframeMouseOver) {
			showLoadingCover()
			$('#timeHider').delay(500).fadeIn(500)
		}
	});

	document.getElementById('customPlayerWrapper').addEventListener('mouseover', function () {
		myConfObj.iframeMouseOver = true;
	});
	document.getElementById('customPlayerWrapper').addEventListener('mouseout', function () {
		myConfObj.iframeMouseOver = false;
	});
}

/**
 * Summary. Handle the simulated live player
 */
function loadLivePlayer() {
	DEBUG_MODE_ACTIVE && console.log('load live player')
	listenForIframePlayBtnClick()

	var coverAlreadyShown = false
	function loadPlayer() {
		player = window.player = new Mediasite.Player(pConfig.playerId, {
			url: pConfig.presentationUrl,
			events: {

				'ready': onLivePlayerReady,
				'error': logPlayerError,
				'currenttimechanged': function (e) {
					if (moments.live) {
						setTimeCoverStamp(e.currentTime)
					}
				},
				'playerstatechanged': function (e) {
					DEBUG_MODE_ACTIVE && console.log('player state changed', e)
					if (e.state == "Ended") {
						endPlayer()
					}
				},
				'playstatechanged': function (e) {
					DEBUG_MODE_ACTIVE && console.log('play state changed', e)
					if (e.playState == "opening") {
						if (!coverAlreadyShown) {
							showLoadingCover()
							coverAlreadyShown = true
						}
					}
				},
				'timedeventreached': function (e) {
					if (moments.live) {
						if (e.timedEventType == CHECK_POSITION) {
							detectDrift(e.timedEventTime)
						}
					}
				}
			}
		})
	}

	function onLivePlayerReady(e) {
		DEBUG_MODE_ACTIVE && console.log('live player ready', e)

		player.pause()
		if (moments.pending || moments.live) {
			addPlayerDriftChecks(function () {
				refreshMoments()
				if (moments.live) {
					startLivePlayer()
				}
			})
		} else {
			refreshMoments()
			if (moments.live) {
				startLivePlayer()
			}
		}

	}

	//load the player
	loadPlayer()

}


function handleNoIntroDelayedPresentation() {
	DEBUG_MODE_ACTIVE && console.log('handle no intro delayed presentation')
	showPlayerCover(true)
	refreshMoments()

	let curentDate = new Date();
	let presentationIsToday = curentDate.setDate(curentDate.getDate() + 1) >= pConfig.start;
	let format = presentationIsToday ? "[at] hh:mm a" : "[on] l [at] hh:mm a";

	showIntermissionContent(true, 'This presentation will begin ' + moment(pConfig.start).format(format) + '.')
	simpleCountdown(moments.start.valueOf(), function () {
		DEBUG_MODE_ACTIVE && console.log('countdown finished')
		hideUnderstandBtn()
		toggleControlsCover(false)
		showPlayerCover(false)
		startLivePlayer()
	})
}


/*
 * Called at the end of the live presentation
 */
function endPlayer() {
	toggleLiveCovers(false)
	player.seekTo(0)
	player.stop()
}
function showLivePieces() {
	makePlayerFullHeight()
	toggleLiveCovers(true)
}
function startLivePlayer() {
	moments.live = true;
	moments.pending = false;
	showLivePieces()
	setTimeout(function () {
		setPlayerStartPosition()
		hideLoadingCover()
		setTimeout(function () {
			showPlayerCover(false)
			player.play()
			// remove the hidden class from covers when the player has started.
			var elems = document.querySelectorAll(".isLive.isLive2");
			[].forEach.call(elems, function (el) {
				el.classList.remove("isLive2");
			});
		}, 150)
	}, 300)
}

function showLoadingCover() {
	document.getElementById('controlBarLoadingCover').classList.add('loading')
	$('.live-waiting').fadeIn(500)
}
function hideLoadingCover() {
	$('#controlBarLoadingCover').fadeOut(300)
}

function setPresTitle(title) {
	try {
		document.getElementById('countdown2-presTitle').innerHTML = title
	} catch (err) { }
}
function toggleControlsCover(visible) {
	try {
		document.getElementById('introControlsCover').style.display = visible ? 'flex' : "none"
	} catch (err) { }
}
function toggleSecondaryPlayerVisibility(visibile) {
	var pendingPlayer = document.getElementById('pendingPlayer')
	if (pendingPlayer) {
		var dStyle = visibile ? 'flex' : 'none'
		pendingPlayer.style.display = dStyle;
	}
}






function initMoments() {
	var _moments = {
		start: getPresStartTimeUTC(),
		end: getPresEndTimeUTC(),
	}
	moments = _moments
}
function getMoments() {
	initMoments()
	// pres
	moments.presStartUTC = getPresStartTimeUTC()
	moments.presEndUTC = getPresEndTimeUTC()
	moments.introPending = isIntroPending()
	moments.pending = isPresentationPending()
	moments.live = isPresentationLive()
	moments.now = getNow()
	// intro
	moments.introStartUTC = pConfig.pendingPresentationId && moments.presStartUTC.add(-1 * pConfig.pendingPresentationDuration, 's')
	moments.now = function () {
		return getNow()
	}
	moments.onDemand = !moments.pending && !moments.live
	return moments
}
function refreshMoments() {
	moments = getMoments()
	return moments
}
function getNow() {
	return moment.utc()
}
function getNowMoment() {
	return getNow()
}
function getNowVal() {
	return moment.utc().valueOf()
}
Date.prototype.getUTCTime = function () {
	return Math.floor((this.getTime() - (this.getTimezoneOffset() * 60000)) / 1000);
};
function getIntroStart() {
	return getPresStartTimeUTC().add(-1 * pConfig.durationInSeconds, 's')
}
function getPresStartTimeUTC() {
	return moment.utc(pConfig.startUTCStr)
}
function getPresEndTimeUTC() {
	return moment.utc(pConfig.endUTCStr)
}
function isIntroPending() {
	if (pConfig.pendingPresentationId) {
		var introStartMoment = moment.utc(pConfig.startUTCStr).add(-1 * pConfig.pendingPresentationDuration, 's')
		return getNowMoment() < introStartMoment
	} return false;
}
function isPresentationPending() {
	return getNowMoment() < getPresStartTimeUTC()
}
function isPresentationLive() {
	var now = getNowMoment()
	return (now > getPresStartTimeUTC() && now < getPresEndTimeUTC())
}
function getCurrentIntroPosition() {
	var time = getNow().diff(moments.presStartUTC, 's')
	var tillStart = pConfig.pendingPresentationDuration - time
	return time
}
function getTimeTillIntroStart() {
	var time = getNow().diff(moments.introStartUTC, 's')
	if (time < 0) { return 0 }
	return time
}
function getTimeTillPresentationStart(start) {
	var time = getNow().diff(moments.presStartUTC, 's')
	if (time < 0) { return 0 }
	return time
}
function durationToPrettyTime(durationSeconds) {
	var hours = Math.floor((durationSeconds % (60 * 60 * 24)) / (60 * 60));
	var minutes = Math.floor((durationSeconds % (60 * 60)) / (60));
	var seconds = Math.floor((durationSeconds % (60)));
	var pMinutes = minutes.toString()
	var pSeconds = seconds.toString()
	var result = ''
	if (hours > 0) {
		result += hours + ":"
	}
	if (pMinutes.length == 1) {
		pMinutes = '0' + minutes
	}
	if (pSeconds.length == 1) {
		pSeconds = '0' + seconds
	}
	result = result + pMinutes + ":" + pSeconds
	if (result == "00:00") { return '' }
	return result
}








function hideUnderstandBtn() {
	var el = document.getElementById('understandBtnWrapper')
	if (el) { el.style.display = 'none' }
}
function showLiveBadge() {
	document.getElementById('liveBadge').style.display = "block";
}
function hideLiveBadge() {
	document.getElementById('liveBadge').style.display = "none";
}
function toggleLiveCovers(show) {
	var dStyle = 'none'
	if (show == true) { dStyle = 'flex' }
	var matches = document.getElementsByClassName('isLive');
	if (matches && matches.length > 0) {
		for (var i = 0; i < matches.length; i++) {
			matches[i].style.display = dStyle
		}
	}
}
function makePlayerFullHeight() {
	var ifh = document.getElementById('iframeHolder')
	if (ifh) {
		delete ifh.dataset.pending
	}
}
function setTimeCoverStamp(time) {
	var el = document.getElementById('timeCover')
	if (el) {
		var prettyTime = durationToPrettyTime(time)
		el.innerHTML = prettyTime
	}
}




/** start the player from the live position if the presentation is live
 */
function setPlayerStartPosition() {
	if (moments.live) {
		var now = moments.now()
		var start = moment.utc(pConfig.start)
		var distance = now.diff(start, 's')
		if (player.isReady()) {
			player.seekTo(distance + .1)
		} else {
			console.error('player was not ready');
		}
	}
}
function setPrimaryPlayerPosition(seconds) {
	if (seconds >= 0) {
		player.seekTo(seconds)
	}
}


/** Show the intermission cover image over the player.
 */
function showPlayerCover(visible) {
	var display = visible ? 'flex' : 'none'
	var intCover = document.getElementById('intermissionCover');
	var customPlayerWrapper = document.getElementById('customPlayerWrapper');
	var pointerEvent = visible ? 'none' : ''

	if (intCover) {
		intCover.style.display = display;
	}
	if (customPlayerWrapper) {
		customPlayerWrapper.style.cssText = "pointer-events: " + pointerEvent;
	}
}
/**
 * Set the details for the player innter content modal. 
 * shouldShow is a true/false value
 * content is the text value to display in the modal
 */
function showIntermissionContent(shouldShow, content) {
	var display = shouldShow ? 'flex' : 'none'
	var intContent = document.getElementById('simlive-msg');
	var customPlayerWrapper = document.getElementById('customPlayerWrapper');
	var pointerEvent = shouldShow ? 'none' : ''
	content = content || '...'
	if (intContent) {
		intContent.innerHTML = content;
		intContent.style.display = display;
		customPlayerWrapper.style.cssText = "pointer-events: " + pointerEvent;
	}
}


/** Wait for a utc time and make the onFinish callback */
function simpleCountdown(finishUTCVal, onFinish) {
	var x = setInterval(function () {
		var now = getNowVal()
		var distance = finishUTCVal - now;
		if (distance <= 0) {
			clearInterval(x);
			onFinish()
		}
	}, 1000)
}

/**
 * Add time events to the player to ensure the viewer is always at the correct position
 * @param {callback} callback Optional callback to make after the events are added
 */
function addPlayerDriftChecks(callback) {
	var curPosition = calculateCurrentTime() + 10
	// use an ugly start position so breaks are less likely to coincide
	var start = curPosition += getRandomInt(10)
	for (var i = start; i < pConfig.durationInSeconds; i += 30) {
		player.addTimedEvent(i, CHECK_POSITION, {})
	}
	callback && callback(true)
}

/**
 * Calculate the time the presentation should be at
 * @returns Current time in seconds
 */
function calculateCurrentTime() {
	var timeSinceStart = getNow().diff(pConfig.start, 's')
	if (timeSinceStart < 0) { timeSinceStart = 0 }
	return timeSinceStart
}
function detectDrift(curPosition) {
	if (moments.live) {
		var curTime = calculateCurrentTime()
		var drift = curPosition - curTime
		var absDrift = Math.abs(drift)
		DEBUG_MODE_ACTIVE && console.log({ curPosition, curTime, drift, absDrift })
		if (absDrift > 5) {
			player.seekTo(curTime)
			var logDetail = `Viewer had a drift of ${drift} seconds. Seeking to ${curTime}.`
			saveToLog(logDetail)
		}
	}
}

function getRandomInt(max) {
	return Math.floor(Math.random() * Math.floor(max));
}
function saveToLog(info) {
	try {
		var fetchUrl = 'https://' + window.location.host + '/LogInfo'
		$.ajax({
			type: "POST",
			url: fetchUrl,
			contentType: "application/json; charset=utf-8",
			data: JSON.stringify(info),
			processData: true,
			cache: false,
			success: function (data) {
				if (DEBUG_MODE_ACTIVE && data && data.length > 0) {
					console.log(data)
				}
			}
		});
	} catch (err) {
		console.error(err)
	}
}

function preparePlayers(config) {
	pConfig = config
	DEBUG_MODE_ACTIVE && console.log(pConfig)
	moments = getMoments()
	if (moments.pending) {
		showPlayerCover(true)
		handleNoIntroDelayedPresentation()
		loadLivePlayer()
	} else {
		showLiveBadge()
		loadLivePlayer()
	}
}

export default preparePlayers;