import React from 'react'
import Carousel from '../../../../foundation/components/Carousel';
import ErrorBoundary from '../../../../foundation/ErrorBoundary/ErrorBoundary';
import { VideoPreviewThumb } from '../../..';
import { SpotlightPresentation } from '../../../../types/presentations/SpotlightPresentation';
import './SpotlightedCarousel.scss'

export interface ISpotlightedCarouselProps {
    items?: SpotlightPresentation[]
}


const SpotlightedCarousel = (props: ISpotlightedCarouselProps) => {

    if (!props.items) { return (<div></div>) }

    return (
        <ErrorBoundary>
            <Carousel
                id="spotlight-carousel"
                breakpoints={{}}
                mousewheel={false}
                slidesPerView={1}
                useNavigation
            >
                {props.items?.map((item) => {
                    return (
                        <VideoPreviewThumb
                            id={item?.id}
                            key={item.id}
                            content={item}
                        />
                    )
                })}
            </Carousel>
        </ErrorBoundary>
    )
}

export default SpotlightedCarousel