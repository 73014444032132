import { UserPlaybackData } from '../UserPlaybackData'

export type SimLivePlayerConfigs = {
    presentationUrl: string
    startUTCStr: string;
    endUTCStr: string;
    isPending: boolean;
    isLive: boolean;
    isOnDemand: boolean;
    durationInSeconds: number
}

/**
 * Mediasite Video type
 */
export type SpotlightPresentation = {
    id: string;
    /** UTC unix timestamp */
    start: number
    viewable: boolean;
    contentMarkdown?: string;
    enableLiveQA: boolean;
    isSimulated: boolean;
    // currentlyLive: boolean;
    // endedLive: boolean;
    // enableAddToCalendar: boolean;
    mediasiteResourceId: string;
    mediasiteTitle: string;
    mediasiteDescription: string;
    mediasiteStartDate: string;
    // mediasiteLocalizedStartDate: string;
    mediasiteEndDate: string;
    /**
     * Presentation duration in milliseconds.
     */
    mediasiteDuration: number;
    // mediasiteTimeZone: string;
    // mediasiteTimeZoneAbbreviation: string;
    // mediasiteStatus: string;
    mediasiteIsLive: boolean;
    mediasiteNumberOfViews: number;
    mediasiteTags: string[]
    mediasiteThumbnailUrl: string;
    mediasitePresenters: string[];
    simLivePlayerConfigs: SimLivePlayerConfigs;
    // userPlaybackData: UserPlaybackData;
}

export type ISpotlightPresentationMeta = {
    id: string;
    description: string;
    /**
     * Presentation duration in milliseconds.
     */
    duration: number;
    thumbnailUrl: string;
    title: string;
    numFavorites: number
    numLikes: number
    /** UTC unix timestamp in milliseconds */
    start: number
    userProgress?: number
}

export class SpotlightPresentationMeta implements ISpotlightPresentationMeta {
    id: string;
    description: string;
    /**
     * Presentation duration in milliseconds.
     */
    duration: number;
    thumbnailUrl: string;
    title: string;
    numFavorites: number
    numLikes: number
    numViews: number
    start: number
    userProgress?: number
    enableLiveQA: boolean
    isSimulated: boolean
    simLivePlayerConfigs: SimLivePlayerConfigs
    constructor(pres: SpotlightPresentation) {
        this.id = pres.id
        this.description = pres.mediasiteDescription
        this.duration = pres.mediasiteDuration
        this.thumbnailUrl = pres.mediasiteThumbnailUrl || 'https://via.placehold/100x100'
        this.title = pres.mediasiteTitle
        this.start = pres.start
        this.enableLiveQA = pres.enableLiveQA
        this.isSimulated = pres.isSimulated
        this.simLivePlayerConfigs = pres.simLivePlayerConfigs
    }
}