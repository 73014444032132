import { MessagingCreds } from '../../types/messaging'
import { Base } from '../base'

const resourceName = 'user'

export class UserApi extends Base {

    async fetchUserProfile(request: FetchUserProfileRequest): Promise<FetchUserProfileResponse> {
         // @ts-ignore
         const { accountId, profileId, pubKey, subKey, userId} = window
         const messagingCreds = {
             publishKey: pubKey,
             subscribeKey: subKey,
             uuid : userId
         }
        return {
            accountId,
            id: messagingCreds.uuid,
            messaging: messagingCreds,
            profileId
        }
    }

}

export type FetchUserProfileRequest = {

}
export type FetchUserProfileResponse = {
    id?: string
    accountId: string
    messaging: MessagingCreds
    profileId: string
}
