import React from 'react'
import { IconButton, useTheme } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import './PreviewInfoButton.scss'

const PreviewInfoButton = () => {
    const theme = useTheme()
    const styles = {
        ':hover': {
            color: theme.palette.primary.main
        }
    }
    return (
        <IconButton className="preview-info-btn" component="div" >
            <VisibilityIcon sx={styles} />
        </IconButton>
    )
}

export default PreviewInfoButton