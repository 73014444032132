import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { IntegrationKey, IntegrationKeyType } from '../types'

export type GenericConfigFormProps = {
    configFields: IntegrationKey[]
    urlSlug: string;
}

const GenericConfigForm = (props: GenericConfigFormProps) => {
    const { configFields } = props
    return (
        <div>
            {configFields.map((field : IntegrationKey) => {
                const [fieldVal, setFieldVal] = useState(field.value);
                return (
                    <Form.Group key={field.id}>
                    {field.type === IntegrationKeyType.text && (
                        <>
                            <Form.Label>{field.label} {field.required ? '' : ' (Optional)'}</Form.Label>
                            <Form.Control className={field.masked ? 'fs-mask' : ''} name={field.name} required={field.required} defaultValue={field.value} />
                        </>
                    )}

                    {field.type === IntegrationKeyType.checkbox && (
                        <>
                            <Form.Check type="checkbox" className="int-key-check" name={field.name} defaultChecked={fieldVal} value={fieldVal} onChange={(event)=>{
                                let checked = event.target.checked ? 'true' : 'false';
                                setFieldVal(checked);
                                console.log(fieldVal);
                            }}/>
                            <Form.Label>{field.label} {field.required ? '' : ' (Optional)'}</Form.Label>
                        </>
                    )}
                    </Form.Group>
                )
            })}
        </div>
    )
}

export default GenericConfigForm