import React from 'react';
import { StyledLayout } from './Styled';

const EndedLobby = () => {
    const concludedMessage = `This meeting has finished.`
    return (
        <StyledLayout>
            <h3>{concludedMessage}</h3>
        </StyledLayout>
    )
}

export default EndedLobby;