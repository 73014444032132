import { Button, IconButton, TextareaAutosize, Typography } from "@mui/material";
import React, { useState } from "react";
import './SimpleQA.scss'
import { useAppSelector } from "../../../store/hooks";
import { API_REQUEST_METHOD, IApiRequest } from "../../../api/types";
import { useApi } from "../../../api";
import SendIcon from '@mui/icons-material/Send';


export class UserQuestion {
    Text: string
}

const SimpleQA = () => {
    const [questionText, setQuestionText] = useState('');
    const [successMsg, setsuccessMsg] = useState('');
    const [failureMsg, setFailureMsg] = useState('');

    const presentation = useAppSelector(x => x.spotlight.selectedVideo)

    const sendquestionText = async () => {
        setFailureMsg('');
        setsuccessMsg('');
        if (questionText.length <= 1) {
            setFailureMsg('Please enter your question.');
            return;
        } else if (questionText.length >= 1024) {
            setFailureMsg('Questions must be less than 1024 characters.');
            setQuestionText('');
            return;
        }

        const result = submitQuestion();

        if(result) {
            setsuccessMsg("Your question has been submitted. It will be reviewed shortly.")
        } else {
            setFailureMsg("Error. Please try again.")
        }
        setQuestionText('');
    }

    const submitQuestion = async () => {
        let question = new UserQuestion();
        question.Text = questionText;

        const request: IApiRequest = {
            method: API_REQUEST_METHOD.POST,
            endpoint: `/qa/${presentation?.id}/questionSubmit`,
            data: question
        }
        return await useApi(request);       
    }

    return (
        <div className="sofo-engage">
            <div className="header">
                <Typography variant="h5">Q&A</Typography>
            </div>
            
            <div className="messages">
            {successMsg.length > 0 &&
                <div className="text-success question-info">{successMsg}</div>
            }
            {failureMsg.length > 0 &&
                <div className="text-danger question-info">{failureMsg}</div>
            }
            </div>
 
            <div className="chat-input question-alignment">
                <TextareaAutosize
                    className="chat-input"
                    minRows={5}
                    maxRows={10}
                    placeholder="Submit Question"
                    value={questionText}
                    onChange={e => setQuestionText(e.target.value)}
                />
                <IconButton color="primary" onClick={sendquestionText}>
                    <SendIcon />
                </IconButton>
            </div>           
        </div>
    )
}

export default SimpleQA;