import React from "react";
import SofoModal from '../../../brand/modal'
import QuestionnaireTaker from "./QuestionnaireTaker";
import { fetchQuestionnaire } from '../../../../api/ce/questionnairesAPI';
import { CEQuestionnaire } from '../../../../models/ce'

interface IQuestionnaireModalProps {
    onComplete?: () => void
    onFinish?: () => void
    questionnaire?: CEQuestionnaire
    questionnaireId: string
}
interface IQuestionnaireModalState {
    shown: boolean
    questionnaire: CEQuestionnaire
}

class QuestionnaireModal extends React.Component<IQuestionnaireModalProps, IQuestionnaireModalState> {
    constructor(props: IQuestionnaireModalProps) {
        super(props)
        this.state = {
            questionnaire: props.questionnaire,
            shown: false
        }
    }
    componentDidMount = async () => {
        await this.loadQuestionnaire(this.props.questionnaireId)
    }

    loadQuestionnaire = async (questionnaireId: string) => {
        const questionnaire = await fetchQuestionnaire(questionnaireId)
        this.setQuestionnaire(questionnaire)
        this.setOpen()
    }

    componentDidUpdate(prevProps: IQuestionnaireModalProps, prevState: IQuestionnaireModalState) {
        if (prevProps.questionnaireId !== this.props.questionnaireId) {
            this.loadQuestionnaire(this.props.questionnaireId)
        } else if (prevProps.questionnaireId === this.props.questionnaireId && !prevState.shown) {
            this.setOpen()
        }
    }

    setClosed = () => {
        // if (this.state.shown) {
        //     this.props.onComplete ? this.props.onComplete() : this.props.onFinish()
        //     this.props.onFinish()
        // }
        // this.props.onComplete ? this.props.onComplete() : this.props.onFinish()
        // this.props.onComplete && this.props.onComplete()
        this.props.onFinish && this.props.onFinish()
        this.setState({ shown: false })
    }
    setOpen = () => { this.setState({ shown: true }) }
    setQuestionnaire = (questionnaire: CEQuestionnaire) => { this.setState({ questionnaire }) }

    render() {
        let { shown, questionnaire } = this.state
        if (!shown || !questionnaire) { return <div></div> }
        return (
            <SofoModal open={shown} onClose={this.setClosed} title={questionnaire.title} customClass="modal-fullscreen lg-down">
                <QuestionnaireTaker
                    id={questionnaire.id}
                    onComplete={this.props.onComplete}
                    onClose={this.setClosed}
                    questionnaire={questionnaire}
                />
            </SofoModal>
        )
    }
}

export default QuestionnaireModal