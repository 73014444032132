import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useApiClientConfig } from '.'
import SpotlightAPIClient from '../../SpotlightAPIClient'
import { FetchAllProfilesRequest, FetchAllProfilesResponse, FetchProfileRequest, FetchProfileResponse } from '../../SpotlightAPIClient/profiles'
import { Profile } from '../../types'
import { RootState } from '../store'

export interface ProfilesState {
    profiles?: Profile[]
}

const initialState: ProfilesState = {
    profiles: []
}

export const fetchProfile = createAsyncThunk<FetchProfileResponse, FetchProfileRequest, { state: RootState }>(
    'api/profiles/fetchProfile',
    async (request: FetchProfileRequest, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.fetchProfile(request)        
    }
)

export const fetchAllProfiles = createAsyncThunk<FetchAllProfilesResponse, FetchAllProfilesRequest, { state: RootState }>(
    'api/profiles/fetchAllProfiles',
    async (request: FetchAllProfilesRequest, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        return await client.fetchAllProfiles(request)        
    }
)


export const profilesSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProfile.fulfilled, (state, action : PayloadAction<FetchProfileResponse>) => {
            state.profiles = state.profiles.concat(action.payload)
        }),
        builder.addCase(fetchAllProfiles.fulfilled, (state, action : PayloadAction<FetchAllProfilesResponse>) => {
            state.profiles = state.profiles.concat(action.payload)
        })
    }
})

export default profilesSlice.reducer

