import React, { useEffect, useState } from 'react'
import * as _ from "lodash";
import { Grid, Button, Typography, Skeleton } from '@mui/material'
import VideoPreviewThumb from '../VideoPreviewThumb'
import { CONTENT_SORT_ORDERS } from '../../constants'
import PresentationGridListHeader from './components/PresentationGridListHeader';
import LoadingSkeleton from './components/LoadingSkeleton';
import { SpotlightPresentation } from '../../types/presentations/SpotlightPresentation';
/**
 * Styles
 */
import './PresentationGridList.scss'
import { useAppSelector } from '../..';
 
export interface IPresentationGridListProps {
    content: SpotlightPresentation[]
    defaultCollapsed?: boolean
    defaultOrder?: typeof CONTENT_SORT_ORDERS.NEWEST 
    | typeof CONTENT_SORT_ORDERS.OLDEST 
    | typeof CONTENT_SORT_ORDERS.TITLE_A 
    | typeof CONTENT_SORT_ORDERS.TITLE_Z 
    | typeof CONTENT_SORT_ORDERS.POPULAR
    emptyListMessage?: string
    id?: string
    limit?: number
    /** Show user progress for the video */
    showProgress?: boolean
    title: string
}

const PresentationGridList = (props: IPresentationGridListProps) => {

    const PAGE_SIZE = 10
    const [collapsed, setCollapsed] = useState(props.defaultCollapsed || false)
    const [showMoreBtnVisible, setShowMoreBtnVisible] = useState(true)
    const [shownItemCount, setShownItemCount] = useState(PAGE_SIZE)
    const [sortOption, setSortOption] = useState(props.defaultOrder || CONTENT_SORT_ORDERS.NEWEST)
    const likeCounts = useAppSelector(x=> x.engagement.presentationLikeCounts)
    const favCounts = useAppSelector(x=> x.engagement.presentationFavoriteCounts)
    const {
        title
    } = props
    let presentations = props.content

    useEffect(() => {
        setShowMoreBtnVisible(props.content && (shownItemCount < props.content.length))
    }, [props.content, shownItemCount])

    const handleShowMore = () => {
        setShownItemCount(shownItemCount + PAGE_SIZE)
    }

    const handleCollapseEffects = () => {
        setShownItemCount(PAGE_SIZE)
        setShowMoreBtnVisible(props.content && (shownItemCount < props.content.length))
    }

    const handleSortChange = (sortOption: number) => {
        handleCollapseEffects()
        setSortOption(sortOption)
    }

    let shownItems : SpotlightPresentation[] = undefined
    if (props.content) {
        if (sortOption === CONTENT_SORT_ORDERS.NEWEST && title !== 'Upcoming') {
            // Don't show future videos as the newest choices
            const now = new Date().getTime()
            presentations = presentations.filter(x => x.start < now)
        }
        presentations = _.sortBy(presentations,
            (x) => {
                if (sortOption === CONTENT_SORT_ORDERS.OLDEST) return x.start
                else if (sortOption === CONTENT_SORT_ORDERS.NEWEST) return x.start
                else if (sortOption === CONTENT_SORT_ORDERS.POPULAR) {
                    return ((favCounts?.[x.id] || 0) + (likeCounts?.[x.id] || 0))
                }
                // else if (sortOption === CONTENT_SORT_ORDERS.POPULAR) return (x.mediasiteNumberOfViews)
                return x.mediasiteTitle
            })

        const descendingOptions = [CONTENT_SORT_ORDERS.NEWEST, CONTENT_SORT_ORDERS.POPULAR, CONTENT_SORT_ORDERS.TITLE_Z]
        const useDescendingSort = descendingOptions.indexOf(sortOption) > -1
        let sortDirection = useDescendingSort ? 'desc' : 'asc'
        if (sortDirection === 'desc') { presentations.reverse() }
    
    
        shownItems = presentations.slice(0, shownItemCount)
    }


    const EmptyListMessage = () => {
        const defaultMsg = 'There\'s nothing here yet'
        const msg = props.emptyListMessage || defaultMsg
        return (
            <Typography variant='h5' className="empty-msg">{msg}</Typography>
        )
    }

    const Wrapper = (wrapperProps: any) => (
        <div id={props.id} className="pres-grid-list">
            <PresentationGridListHeader
                collapsed={collapsed}
                onCollapsed={setCollapsed}
                collapseCallback={handleCollapseEffects}
                onSort={handleSortChange}
                sort={sortOption}
                title={title}
            />
            <br />
            {!collapsed && wrapperProps.children}
        </div>
    )

    return (
        <Wrapper>
            {!props.content && <LoadingSkeleton />}
            {shownItems?.length === 0 && <EmptyListMessage />}
            {shownItems?.length > 0 && (
                <Grid container spacing={1} columns={10}>
                    {shownItems.map((item, index) => (
                        <Grid key={item?.id || index} item xs={5} sm={3} md={2}>
                            <VideoPreviewThumb
                                id={item?.id}
                                showProgress={props.showProgress}
                                key={item.id}
                                content={item} />
                        </Grid>
                    ))}
                    {showMoreBtnVisible &&
                        <Grid container direction="column" alignItems="center" justifyContent="center" item xs={12}>
                            <Button className="show-more" color="primary" variant="contained" size="large" onClick={handleShowMore}>Show More</Button>
                        </Grid>}
                </Grid>
            )}
        </Wrapper>
    )
}

PresentationGridList.defaultProps = {
    defaultOrder: CONTENT_SORT_ORDERS.NEWEST,
    limit: 10
}

export default PresentationGridList

