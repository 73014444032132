interface IDurationDisplayProps {
    isLive?: boolean
    text?: string
}
const DurationDisplay = (props: IDurationDisplayProps) => {
    const { isLive, text } = props;

    return (
        /* If the video is live, add an extra class to the duration and an icon to show that it is live */
        <div className={`duration${isLive ? " is-live" : ""}`}>
            {isLive &&
                <div className="icon"></div>
            }
            <div className="duration-text">
                {text}
            </div>
        </div>
    )
}

export default DurationDisplay