import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

/**
 * Convert an ASP date string from "2022-12-12T00:00:00" to yyyy-mm-dd for HTML input Fields
 * @param longdate 
 * @returns 
 */
 export const formatInputDate = (longdate: string) => {
    let dueDate = new Date(longdate).toISOString().split('T')[0]
    return dueDate
  }
  
  export const shortenLongUnixTimestamp = (timestamp: string) => {
    return Number(timestamp) / 10000
  }

  export const parseLongUnixTimestamp = (timestamp: string) => {
    const unixTimestamp = shortenLongUnixTimestamp(timestamp)
    return dayjs(unixTimestamp)
  }