export type IntegrationKey = {
    id: string
    label: string
    masked: boolean;
    name: string
    required: boolean;
    value?: string
    type?: number
}

export type IntegrationOptionType = {
    description: string
    id: string
    name: string
    purpose: number
    settingKeys: IntegrationConfigItem[]
}

export const IntegrationKeyType = {
    text: 0,
    checkbox: 1
}