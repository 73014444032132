import { API_REQUEST_METHOD } from '../../api/types'
import { Base } from '../base'

const resourceName = 'messaging'

export class MessagingApi extends Base {

    /**
     * Fetch chat messages for a given presentation (identified by contentId)
     * @returns Fetched content
     */
    async fetchChatMessages(request: FetchMessagesRequest) : Promise<any> {
        const endpoint = `${resourceName}/presentation/${request.presentationId}/chat`      
        return this.request(endpoint)
    }

    /**
     * Save a chat message to the Connect DB
     * @returns confirmation/status
     */
    async saveChatMessage(request: SaveMessageRequest): Promise<any> {
        const endpoint = `${resourceName}/presentation/${request.presentationId}/chat/publish`      
        return this.request(endpoint, {
            body: JSON.stringify(request),
            method: API_REQUEST_METHOD.POST
        })
    }

}

/**
 * Request to get more videos from the Spotlight API
 */
 export type FetchMessagesRequest = {
    presentationId?: string
    roomId?: string
}

export type SaveMessageRequest = {
    presentationId?: string
    message: any
    roomId?: string
}