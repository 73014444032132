import { Typography } from "@mui/material"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../.."
import { FetchProfileRequest } from "../../../SpotlightAPIClient/profiles"
import { fetchProfile } from "../../../store/slices/profilesSlice"
import { SpotlightMessage } from "../../../types/messaging"
import { tryFormatDate } from "../../../utils/DateUtils"

import './ChatMessage.scss'

export type ChatMessageProps = {
    currentUserProfileId: string
    message: SpotlightMessage
}
export const ChatMessage = (props: ChatMessageProps) => {

    const {
        currentUserProfileId,
        message
    } = props

    /** Spotlight Profile Id of the sender */
    const senderId = props.message.meta?.profileId || props.message.uuid

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles?.profiles?.find(x => x.id === senderId))
    const normalTimestamp = Math.floor(Number(props.message.time) / 10000)
    const timeStamp = tryFormatDate(normalTimestamp, 'h:mm A')
    useEffect(() => {
        if (!profile) {
            let request: FetchProfileRequest = {
                id: senderId
            }
            dispatch(fetchProfile(request))
        }
    }, [profile])

    /** Parse message line breaks and return html */
    const createMarkup = (text: string): { __html: string } => {
        const markup: string = text.replace(new RegExp('\r?\n', 'g'), '<br />');
        return { __html: markup }
    }
    const messageMarkup = createMarkup(message.message)
    return (
        <div
            className="sofo-msg"
            data-sender={currentUserProfileId == senderId ? true : null}
        >
            <Typography className="name">{profile?.displayName || '...'}</Typography>
            <div className="msg-wrapper">
                <Typography component={'div'} className="msg" dangerouslySetInnerHTML={messageMarkup} />
            </div>
            <Typography className="time" variant={'body2'}>{timeStamp}</Typography>
        </div>
    )
}

export default ChatMessage