export const buildPreviewLink = (videoId: string, playlistId?: string) => {
    let url = `/videos/preview/${videoId}`
    if (playlistId) {
        url = `/playlist/${playlistId}/videos/preview/${videoId}`
    }
    return url
}
export const buildWatchLink = (videoId: string, playlistId?: string) => {
    let url = `/videos/watch/${videoId}`
    if (playlistId) {
        url = `/playlist/${playlistId}/videos/watch/${videoId}`
    }
    return url
}

export const buildSpotlightLink = (playlistId?: string) => {
    return playlistId ? `/playlist/${playlistId}` : '/'
}

export const buildRelatedLink = (presentationId: string, playlistId?: string) => {
    let url = `presentations/${presentationId}/related`
    if (playlistId) {
        url = `playlist/${playlistId}/presentations/${presentationId}/related`
    }
    return url
}

// export const buildPreviewLink = (videoId: string, playlistId?: string) => {
//     let url = `/spotlight/videos/preview/${videoId}`
//     if (playlistId) {
//         url = `/spotlight/playlist/${playlistId}/videos/preview/${videoId}`
//     }
//     return url
// }
// export const buildWatchLink = (videoId: string, playlistId?: string) => {
//     let url = `/spotlight/videos/watch/${videoId}`
//     if (playlistId) {
//         url = `/spotlight/playlist/${playlistId}/videos/watch/${videoId}`
//     }
//     return url
// }

// export const buildSpotlightLink = (playlistId?: string) => {
//     return playlistId ? `/spotlight/playlist/${playlistId}` : '/spotlight/'
// }