import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

import './Modal.scss'

export type ModalProps = {
    children: JSX.Element
    dialogStyle?: any
    footer?: JSX.Element
    fullScreen?: boolean
    id?: string
    onClose: any
    onOpen?: any
    open: boolean
    closeBtnElement?: ReactNode
}

const Modal = (props: ModalProps) => {

    return (
        <Dialog
            id={props.id}
            fullScreen={props.fullScreen || false}
            open={props.open}
            className={props.fullScreen ? 'modal' : 'modal large'}
            onClose={(event, reason) => {
                if (props.onClose) {
                    props.onClose(event, reason);
                }
            }}
        >
            <div className="sofo-modal">
                <div className="sofo-modal-header">
                    {props.closeBtnElement ?
                        props.closeBtnElement :
                        <div className="closeIcon">
                            <IconButton onClick={() => { props.onClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                </div>
                <div className="sofo-modal-content">
                    {props.children}
                </div>
                {props.footer &&
                    <div className="sofo-modal-footer">
                        {props.footer}
                    </div>
                }
            </div>
        </Dialog>
    );
}

export default Modal