import React from "react"
import { IQuestionnaireQuestion } from "../../../../models/ce"
import { createTempId, IsNullOrEmpty } from "../../../../helpers";
import QuestionnaireComposerQuestionOption from "./QuestionnaireComposerQuestionOption";
import Switch from "../../../brand/switch";
import QuestionnaireComposerQuestionSummary from "./QuestionnaireComposerQuestionSummary";
import { CEQuestionType } from '../../../../models/ce/index';
import _ from "lodash";

export interface IQuestionnaireComposerQuestionProps {
    active: boolean
    index: number
    isGraded: boolean
    namePrefix?: string
    onDelete: ((questionId: string) => void)
    onDraft: ((questinoId: string) => void)
    onSave: (question: IQuestionnaireQuestion) => void
    question: IQuestionnaireQuestion
}
export interface IQuestionnaireComposerQuestionState {
    isValid: boolean
    question: IQuestionnaireQuestion
}


export default class QuestionnaireComposerQuestion extends React.Component<IQuestionnaireComposerQuestionProps, IQuestionnaireComposerQuestionState> {
    constructor(props: IQuestionnaireComposerQuestionProps) {
        super(props)
        this.state = {
            isValid: false,
            question: props.question
        }
    }

    addOption = () => {
        let { question } = this.state
        const id = createTempId()
        const order = question.options.length
        question.options.push({ id, ceQuestionId: question.id, order, isCorrect: false, text: '' })
        this.setState({ question }, this.updateValidity)
    }

    cancelDraft = () => {
        const original = this.props.question
        if (original.id.startsWith('temp')) { return this.deleteQuestion() }
        this.props.onSave(original)
    }

    deleteQuestion = () => {
        this.props.onDelete(this.state.question.id)
    }

    deleteOption = (id: string) => {
        let { question } = this.state
        if (question.options.length > 2) {
            const index = question.options.findIndex(x => x.id == id)
            question.options.splice(index, 1)
            this.setState({ question }, this.updateValidity)
        }
    }

    isValid = (): boolean => {
        let isValid = true;
        let { question } = this.state
        let { text, options } = question
        question.options = options
        if (question.responseType !== CEQuestionType.MultipleChoice && question.responseType !== CEQuestionType.OpenForm) { return false }
        if (IsNullOrEmpty(text)) { return false }
        if (question.responseType == CEQuestionType.MultipleChoice) {
            if (!question.options || question.options.length < 2) { return false }
            if (this.props.isGraded) {
                if (!question.options.find(x => x.isCorrect)) { return false }
                if (question.options.filter(x => x.isCorrect).length > 1) {
                    return false
                }
            }
            question.options.map(x => {
                if (isValid) {
                    isValid = !IsNullOrEmpty(x.text)
                }
            })
        }
        return isValid
    }

    saveQuestion = (): void => {
        let { question } = this.state
        if (this.isValid()) {
            this.props.onSave(question)
        }
    }

    setName = (name: string): void => {
        let { question } = this.state
        question.text = name
        this.setState({ question }, this.updateValidity)
    }

    setOrder = (order: number): void => {
        let { question } = this.state
        question.order = order
        this.setState({ question }, this.updateValidity)
    }

    setQuestionType = (questionType: number) => {
        let { question } = this.state
        question.responseType = questionType
        this.setState({ question }, this.updateValidity)
    }

    setRequired = (required: boolean) => {
        let { question } = this.state
        question.required = required
        this.setState({ question }, this.updateValidity)
    }

    updateValidity = () => {
        const isValid = this.isValid()
        this.setState({ isValid })
    }


    renderSummary = (question: IQuestionnaireQuestion) => {
        return (
            <QuestionnaireComposerQuestionSummary
                key={question.id}
                onDelete={this.deleteQuestion}
                onDraft={() => { this.props.onDraft(this.props.question.id) }}
                question={question}
            />
        )
    }

    render() {
        let { isValid, question } = this.state
        let options = _.sortBy(question.options, x=> x.order)

        if (!this.props.active) {
            return this.renderSummary(question)
        }

        return (
            <div>
                <div className="form-group">
                    <label>Question Text</label>
                    <input
                        className="form-control"
                        defaultValue={question.text}
                        maxLength={512}
                        onChange={(e) => this.setName(e.target.value)}
                        required
                    />
                    {(question.text?.length >= 511) &&
                        <small className="small text-danger">Max Length Exceeded</small>
                    }
                </div>

                <div className="form-row d-flex">
                    <div className="col lg-8 col-xl-6 form-group">
                        <label>Question Type</label>
                        <div className="d-flex align-items-center">
                            <select
                                className="form-control"
                                defaultValue={question.responseType}
                                onChange={(e) => { this.setQuestionType(Number(e.target.value)) }}
                                required >
                                <option value={-1}>Select One</option>
                                <option value={CEQuestionType.MultipleChoice}>Multiple Choice</option>
                                <option value={CEQuestionType.OpenForm}>Open Form</option>
                            </select>
                            <div className="ml-3">
                                <Switch
                                    checked={this.state.question.required}
                                    label={"Required"}
                                    onChange={(checked: boolean) => { this.setRequired(checked) }}
                                />
                            </div>
                        </div>

                    </div>

                </div>
                {question.responseType === CEQuestionType.MultipleChoice &&
                    <div className="form-group mt-1">
                        <label>Add options{this.props.isGraded && ' and select the correct answer'}</label>
                        <br />
                        {options && options.map((option, i) =>
                            <QuestionnaireComposerQuestionOption
                                isGraded={this.props.isGraded}
                                key={option.id || i}
                                onUpdate={this.updateValidity}
                                option={option}
                                onDelete={this.deleteOption}
                            />
                        )}

                        <div className="form-group">
                            <button
                                className="btn btn-primary"
                                onClick={this.addOption}
                                type="button"
                            >
                                <span className="material-icons-outlined">add</span>
                            </button>
                        </div>

                    </div>

                }

                <div className="form-group">
                    <button
                        className="btn btn-primary px-5"
                        disabled={!isValid}
                        onClick={this.saveQuestion}
                        type="button"
                    >
                        Save Question
                    </button>
                    <button
                        className="btn btn-secondary ml-3"
                        onClick={this.deleteQuestion}
                        style={{ lineHeight: 1 }}
                        title="Delete"
                        type="button"
                    >
                        <span className="material-icons-outlined">delete_outline</span>
                    </button>
                    <button
                        onClick={this.cancelDraft}
                        className="btn btn-warning ml-3"
                        style={{ lineHeight: 1 }}
                        title="Cancel"
                        type="button"
                    >
                        <span className="material-icons-outlined">cancel</span>
                    </button>
                </div>
            </div>
        )
    }
}