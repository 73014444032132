import { API_REQUEST_METHOD } from '../../api/types'
import { Base } from '../base'

const resourceName = 'favorites'

/**
 * Handle favorites for Spotlight Presentations
 */
export class FavoritesAPI extends Base {

    /**
     * Favorite a mediasite presentation
     * @param presentationId Mediasite Presentation Id
     * @returns 
     */
    async addFavorite(presentationId: string) : Promise<void> {
        await this.request(`${resourceName}/${presentationId}`, {
            body: JSON.stringify({ presentationId }),
            method: API_REQUEST_METHOD.POST
        })
    }

    /**
     * Remove a Favorite from a mediasite presentation
     * @param presentationId Mediasite Presentation Id
     * @returns 
     */
    async deleteFavorite(presentationId: string) {
        return await this.request(`${resourceName}/${presentationId}`, {
            body: JSON.stringify({ presentationId }),
            method: API_REQUEST_METHOD.DELETE
        })
    }

}