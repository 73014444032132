import Pubnub from 'pubnub';

const pubnubCreds = {
    publishKey: global.pubKey,
    subscribeKey: global.subKey,
}

/** Initialize the Pubnub api for a user */
export const getPubnubClient = (uuid: string) => {
    return new Pubnub(Object.assign({}, pubnubCreds, { uuid }))
}
