// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useContext, ChangeEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Flex,
  Heading,
  PrimaryButton,
  useMeetingManager,
  Modal,
  ModalBody,
} from 'amazon-chime-sdk-component-library-react';

import { getErrorContext } from '../../providers/ErrorProvider';
import routes from '../../constants/routes';
import Card from '../../components/Card';
import Spinner from '../../components/Spinner';
import DevicePermissionPrompt from '../DevicePermissionPrompt';
import { createGetAttendeeCallback, joinMeeting } from '../../utils/api';
import { useAppState } from '../../providers/AppStateProvider';

const MeetingForm: React.FC = () => {
  const meetingManager = useMeetingManager();
  const {
    isPresenter,
    region: appRegion,
    meetingId: appMeetingId,
    userId,
  } = useAppState();
  const [meetingId] = useState(appMeetingId);
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const history = useHistory();

  const handleJoinMeeting = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    meetingManager.getAttendee = createGetAttendeeCallback(meetingId);

    try {
      const { JoinInfo } = await joinMeeting(meetingId, userId, appRegion);
      await meetingManager.join({
        meetingInfo: JoinInfo.Meeting,
        attendeeInfo: JoinInfo.Attendee
      });
      history.push(routes.DEVICE);
    } catch (error) {
      updateErrorMessage(error.message);
    }
  };

  const closeError = (): void => {
    updateErrorMessage('');
    setIsLoading(false);
  };

  return (
    <form>
      <Heading tag="h1" level={4} css="margin-bottom: 1rem; text-align: center">
        {isPresenter ? 'Start' : 'Join'} the meeting
      </Heading>
      <Flex
        container
        layout="fill-space-centered"
        style={{ marginTop: '2.5rem' }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <PrimaryButton label={isPresenter ? 'Start' : 'Join' + " Meeting"} onClick={handleJoinMeeting} />
        )}
      </Flex>
      {errorMessage && (
        <Modal size="md" onClose={closeError}>
          <ModalBody>
            <Card
              title="Unable to join meeting"
              description="This meeting has not started yet. Please wait for the host to begin this meeting."
            />
          </ModalBody>
        </Modal>
      )}
      <DevicePermissionPrompt />
    </form>
  );
};

export default MeetingForm;
