import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store';
import { InteractionSummaryRequest, InteractionSummaryResponse, UserProgressSummary, UserProgressSummaryItem, UserProgressSummaryRequest } from '../../SpotlightAPIClient/engagement/types';
import { useApiClientConfig } from '.';
import SpotlightAPIClient from '../../SpotlightAPIClient';

// Define a type for the slice state
export interface EngagementState {
    favoritedPresentationIds?: string[];
    likedPresentationIds?: string[] | null;
    presentationFavoriteCounts: { [key: string]: number };
    presentationLikeCounts: { [key: string]: number };
    progress: UserProgressSummary
}

// Define the initial state using that type
const initialState: EngagementState = {
    favoritedPresentationIds: [],
    likedPresentationIds: [],
    presentationFavoriteCounts: {},
    presentationLikeCounts: {},
    progress: {}
}

export const loadEngagement = createAsyncThunk<InteractionSummaryResponse, InteractionSummaryRequest, { state: RootState }>(
    'engagement/load',
    async (request: InteractionSummaryRequest, thunkApi) => {
        const state = thunkApi.getState()
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        var engagement = await client.getEngagement(request)
        // thunkApi.dispatch(setContentEngagement(engagement))
        return engagement
    }
)

export const loadProgress = createAsyncThunk<UserProgressSummary, UserProgressSummaryRequest, { state: RootState }>(
    'engagement/fetchProgress',
    async (request: UserProgressSummaryRequest, thunkApi) => {
        const state = thunkApi.getState()
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        var progressItems = await client.getProgress()
        var progressMap = new Map(progressItems.map(item => [item.presentationId, item]));
        let progress: UserProgressSummary = Object.fromEntries(progressMap)
        // thunkApi.dispatch(setContentProgress(progress))
        return progress
    }
)

export const favoritePresentation = createAsyncThunk<string, string, { state: RootState }>(
    'user/favoritePresentation',
    async (presentationId: string, thunkApi) => {
        const state = thunkApi.getState()
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        await client.addFavorite(presentationId)
        return presentationId
    }
)

export const unfavoritePresentation = createAsyncThunk<string, string, { state: RootState }>(
    'user/unfavoritePresentation',
    async (presentationId: string, thunkApi) => {
        const state = thunkApi.getState()
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        await client.deleteFavorite(presentationId)
        return presentationId
    },
)

/**
 * Like a presentation
 */
export const likePresentation = createAsyncThunk<string, string, { state: RootState }>(
    'user/likePresentation',
    async (presentationId: string, thunkApi) => {
        const state = thunkApi.getState()
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        await client.addLike(presentationId)
        return presentationId
    }
)

export const unlikePresentation = createAsyncThunk<string, string, { state: RootState }>(
    'user/unlikePresentation',
    async (presentationId: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const clientConfig = useApiClientConfig(state.config)
        const client = new SpotlightAPIClient(clientConfig)
        await client.deleteLike(presentationId)
        return presentationId
    }
)

export const engagementSlice = createSlice({
    name: 'engagement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadEngagement.fulfilled, (state, action) => {
            state.favoritedPresentationIds = action.payload.favoritedPresentationIds
            state.likedPresentationIds = action.payload.likedPresentationIds
            state.presentationFavoriteCounts = action.payload.presentationFavoriteCounts
            state.presentationLikeCounts = action.payload.presentationLikeCounts
        })
        builder.addCase(loadProgress.fulfilled, (state, action) => {
            state.progress = action.payload
        })
        builder.addCase(favoritePresentation.fulfilled, (state, action) => {
            state.favoritedPresentationIds.push(action.payload)
            var item = state.presentationFavoriteCounts[action.payload]
            if (!item) { state.presentationFavoriteCounts[action.payload] = 1 }
            else { state.presentationFavoriteCounts[action.payload] += 1 }
        })
        builder.addCase(unfavoritePresentation.fulfilled, (state, action) => {
            state.favoritedPresentationIds = state.favoritedPresentationIds.filter(x => x !== action.payload)
            state.presentationFavoriteCounts[action.payload] -= 1
        })
        builder.addCase(likePresentation.fulfilled, (state, action) => {
            state.likedPresentationIds.push(action.payload)
            var item = state.presentationLikeCounts[action.payload]
            if (!item) { state.presentationLikeCounts[action.payload] = 1 }
            else { state.presentationLikeCounts[action.payload] += 1 }
        })
        builder.addCase(unlikePresentation.fulfilled, (state, action) => {
            state.likedPresentationIds = state.likedPresentationIds.filter(x => x !== action.payload)
            state.presentationLikeCounts[action.payload] -= 1
        })
    }
})

export default engagementSlice.reducer

