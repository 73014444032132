import React, { useEffect, useState } from "react";

/**
 * This hook allows you to debounce any fast changing value. The debounced value will only reflect
 * the latest value when the useDebounce hook has not been called for the specified time period.
 * @param value Object/Value to use with frequent changes
 * @param timeout Time required after value changes before calling the callback 
 * @param callback Callback function to fire when the value is ready
 */
export function useDebounce(value: any, timeout: number, callback: any) {

    const [timer, setTimer] = useState(null);

    const clearTimer = () => {
        if(timer) {
            clearTimeout(timer);
        }
    }

    useEffect(() => {
        clearTimer();

        if (value && callback) {
            const newTimer = setTimeout(callback, timeout);
            setTimer(newTimer);
        }
    }, [value]);
}