
export interface IContentItem {
    id: string;
    mediasiteTitle: string;
    mediasiteLocalizedStartDate: string;
    mediasiteResourceId: string;
    mediasiteManageUri: string;
    mediasiteTimeZoneAbbreviation: string;
    priority: number;
}

export enum VideoPriorities {
    None = 0,
    Featured = 1,
    Spotlight = 2
}

export interface IUserPlaylist {
    id: string;
    name: string;
}


export class ContentData {
    content: [];
}

export class PlaylistContent {
    userPlaylists: IUserPlaylist[];
    playlistContent: [];
}

export class IContentPlaylist {
    contentId: string;
    playlistId: string;
    playlist: IUserPlaylist;
}

export class Playlist {
    id?: string;
    name?: string;
    contentIds: string[];
}

