import React from "react"
import ReactDOM from "react-dom"
import SpotlightPage from '../pages/SpotlightPage'
import { store } from '../store/store'
import { Provider } from 'react-redux'
import { setConfiguration } from '../store/slices/configSlice'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import VideoPreviewModal from "../components/VideoPreviewModal"
import WatchModal from "../components/WatchModal"
import { SofoPubnubProvider } from "../integrations/pubnub/SofoPubnubProvider"
import { fetchAllContent, fetchAllProfiles, fetchSpotlighted, loadEngagement, loadProgress, setUser } from "../store"
import { UserProgressSummaryRequest } from "../SpotlightAPIClient/engagement/types"
import { FetchAllProfilesRequest } from "../SpotlightAPIClient/profiles"
import { InitializationConfig } from "../config/InitializationConfig"
import { Theme as SofoMUITheme } from '@sonic-foundry/mediasite-ui-react'
import { Theme, ThemeProvider } from "@mui/material"
import buildTheme from '../styles/ThemeCreator'
import AppStyles from "../styles/AppStyles"

const loadInitialPageData = async (config: InitializationConfig) => {
    let options = { playlistId: config.playlistId }
    store.dispatch(setConfiguration(config))
    store.dispatch(setUser({
        accountId: config.user.accountId,
        configured: true,
        messaging: config.messaging,
        profileId: config.user.profileId,
    }))
    store.dispatch(fetchAllContent(options)).then(() => {
        store.dispatch(fetchSpotlighted(options))
        store.dispatch(loadEngagement(options))
        const loadProgressRequest: UserProgressSummaryRequest = {}
        store.dispatch(loadProgress(loadProgressRequest))
    })
    const fetchAllProfilesRequest: FetchAllProfilesRequest = {}
    store.dispatch(fetchAllProfiles(fetchAllProfilesRequest))
}

const renderApp = (wrapper: string, config: InitializationConfig) => {
    loadInitialPageData(config)
    const siteTheme: Theme = buildTheme(config)
    const container = document.querySelector(wrapper)

    ReactDOM.render(
        <div id="spotlight-app">
            <Provider store={store}>
                <AppStyles siteTheme={siteTheme} />
                <ThemeProvider theme={SofoMUITheme}>
                    <ThemeProvider theme={siteTheme}>
                        <SofoPubnubProvider>
                            <BrowserRouter basename={`/site/${config.urlSlug}/spotlight`}>
                                <Routes>
                                    <Route path="/" element={<SpotlightPage />}>
                                        <Route path="/playlist/:playlistId">
                                            <Route path="/playlist/:playlistId/videos/preview/:videoId" element={<VideoPreviewModal />} />
                                            <Route path="/playlist/:playlistId/videos/watch/:videoId" element={<WatchModal />} />
                                        </Route>
                                        <Route path="/videos/preview/:videoId" element={<VideoPreviewModal />} />
                                        <Route path="/videos/watch/:videoId" element={<WatchModal />} />
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </SofoPubnubProvider>
                    </ThemeProvider>
                </ThemeProvider>
            </Provider >
        </div>
        , container)
}

export {
    renderApp
}