import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './redux/store'
import { useAppDispatch, useAppSelector } from './redux/hooks';

import QuestionnaireComposer from './components/CE/QuestionnaireComposer';
import ModalQuestionnaireTaker from './components/CE/QuestionnaireTaker/components/QuestionnaireModal';
import { loadProfile } from './redux/features/profile/profileSlice';
import UserAlertsContainer from './containers/alerts/UserAlertsContainer';
import { IsNullOrEmpty } from './helpers';
import AdminAlertsContainer from './containers/alerts/AdminAlertsContainer';
import { ADMIN_NOTIFICATIONS_TARGET } from './appTargets';
import MeetingContainer from './containers/meeting/MeetingContainer'
import SearchableSelect from './components/common/SearchableSelect';
import ContentManagerContainer from './containers/content/ContentManager/ContentManagerContainer';
import IntegrationConfigContainer from './containers/integrations/IntegrationConfigContainer';
import { renderSpotlightApp } from '@sonic-foundry/connect-showcase-react';


global.renderQuestionnaireComposer = (domSelector: string, questionnaireId?: string, eventId?: string) => {
    const domContainer = document.querySelector(domSelector)
    questionnaireId = questionnaireId ?? domContainer.getAttribute('data-id')
    eventId = eventId ?? domContainer.getAttribute('data-eventid')
    ReactDOM.render(<QuestionnaireComposer eventId={eventId} id={questionnaireId} />, domContainer);
}

global.renderModalQuestionnaireTaker = (domSelector: string, questionnaireId?: string, onFinish?: any) => {
    const domContainer = document.querySelector(domSelector)
    questionnaireId = questionnaireId ?? domContainer.getAttribute('data-id')
    ReactDOM.render(<ModalQuestionnaireTaker questionnaireId={questionnaireId} onFinish={onFinish} />, domContainer);
}

global.renderTypeahead = (domSelector: string, data: any) => {
    const domContainer = document.querySelector(domSelector)
    ReactDOM.render(
        <SearchableSelect
            mapper={(item) => ({
                id: item.id,
                name: `${item.firstName || '?'} ${item.lastName || '?'}`,
                email: item.email
            })}
            onSelect={data.onSelect}
            placeholder={data.presenterName || 'Search for a presenter...'}
            searchUrl={data.searchUrl}
        />, domContainer);
}


global.renderContentManagerContainer = (domSelector: string) => {
    const domContainer = document.querySelector(domSelector)
    ReactDOM.render(<ContentManagerContainer />, domContainer);
}

global.renderTypeahead = (domSelector: string, data: any) => {
    const domContainer = document.querySelector(domSelector)
    ReactDOM.render(
        <SearchableSelect
            mapper={(item) => ({
                id: item.id,
                name: `${item.firstName || '?'} ${item.lastName || '?'}`,
                email: item.email
            })}
            onSelect={data.onSelect}
            placeholder={data.presenterName || 'Search for a presenter...'}
            searchUrl={data.searchUrl}
        />, domContainer);
}

global.renderIntegrationConfigContainer = () => {
    let wrapper = document.querySelector('#securityIntegrationWrapper')
    if (wrapper) {
        ReactDOM.render(<IntegrationConfigContainer />, wrapper);
    }
}


global.renderSpotlightPage = renderSpotlightApp


const AppContent = () => {
    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profile)

    useEffect(() => {
        const initialize = async () => {
            dispatch(loadProfile())
        }
        initialize()
    }, [])

    const ready = !IsNullOrEmpty(profile?.id)
    const adminAlertComponent = document.querySelector(ADMIN_NOTIFICATIONS_TARGET)

    return (
        <div>
            {ready &&
                <>
                    {adminAlertComponent && <AdminAlertsContainer target={ADMIN_NOTIFICATIONS_TARGET} />}
                    <MeetingContainer />
                    <UserAlertsContainer />
                </>
            }
        </div>
    )
}


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppContent />
        </Provider>
    </React.StrictMode>,
    document.getElementById('react-root')
);

