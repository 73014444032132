import React from 'react'
import { createTempId, formatInputDate, getSiteAdminUrl, getSiteUrl, IsNullOrEmpty } from '../../../../helpers'
import { CEQuestionType, IQuestionnaire, IQuestionnaireQuestion, Questionnaire } from '../../../../models/ce'
import QuestionnaireComposerQuestion from './QuestionnaireComposerQuestion'
import Switch from '../../../brand/switch'
import FormFileUploader from '../../../FormFileUploader'
import { fetchQuestionnaire, updateQuestionnaire, createQuestionnaire, fetchAvailablePresentations } from '../../../../api/ce/questionnairesAPI';
import _ from 'lodash'
import PresentationSelector from '../../../common/presentationSelector'

export interface IQuestionnaireComposerProps {
    eventId?: string
    id?: string
}

export interface IQuesitonnaireComposerState extends IQuestionnaire {
    draftingQuestion: boolean
    draftingQuestionId?: string
    editing: boolean
    isNew?: boolean
    presentations?: Map<string, string>
    presentationId?: string
}

export default class QuestionnaireComposer extends React.Component<IQuestionnaireComposerProps, IQuesitonnaireComposerState> {
    constructor(props: IQuestionnaireComposerProps) {
        super(props)
        this.state = {
            description: '',
            dueDate: '',
            draftingQuestion: false,
            editing: false,
            bannerUrl: '',
            bannerKey: undefined,
            category: '',
            credits: 0,
            eventId: props.eventId,
            id: props.id,
            isGraded: false,
            isNew: IsNullOrEmpty(props.id),
            title: '',
            passingGrade: 0,
            published: false,
            presenters: '',
            questions: []
        }
    }

    componentDidMount = async () => {
        if (!IsNullOrEmpty(this.state.id)) {
            let questionnaire = await fetchQuestionnaire(this.state.id)
            this.setComposerQuestionnaire(questionnaire)
        }
        let presentations = await fetchAvailablePresentations()
        this.setState({ presentations })
    }

    setComposerQuestionnaire = (questionnaire: IQuestionnaire) => {
        let dueDate = formatInputDate(questionnaire.dueDate)
        this.setState({
            bannerKey: questionnaire.bannerKey,
            bannerUrl: questionnaire.bannerUrl,
            category: questionnaire.category,
            credits: questionnaire.credits,
            dueDate,
            eventId: questionnaire.eventId,
            isGraded: questionnaire.isGraded,
            passingGrade: questionnaire.passingGrade,
            published: questionnaire.published,
            presentationId: questionnaire.presentationId,
            presenters: questionnaire.presenters,
            questions: questionnaire.questions,
            title: questionnaire.title
        })
    }

    addQuestion = () => {
        let questions = this.state.questions
        const id = createTempId()
        let newQuestionOrder = 1
        if (questions.length > 0) {
            newQuestionOrder = _.sortBy(questions, x => x.order)[questions.length - 1]?.order + 1
        }
        const newQuestion: IQuestionnaireQuestion = {
            id,
            eventId: this.state.eventId,
            graded: true,
            order: newQuestionOrder,
            required: true,
            text: '',
            options: [{ id: `${id}-1`, ceQuestionId: id, isCorrect: false, order: 0, text: '' }, { id: `${id}-2`, ceQuestionId: id, isCorrect: false, order: 1, text: '' }],
            questionnaireId: this.state.id,
            responseType: -1
        }
        questions.push(newQuestion)
        this.setState({
            questions,
            draftingQuestion: true,
            draftingQuestionId: id
        })
    }

    deleteQuestion = (questionId: string) => {
        let { questions } = this.state
        let qIndex = questions.findIndex(x => x.id === questionId)
        questions.splice(qIndex, 1)
        this.setState({
            draftingQuestion: false,
            draftingQuestionId: null,
            questions
        })
    }

    draftQuestion = (questionId: string) => {
        this.setState({
            draftingQuestion: true,
            draftingQuestionId: questionId
        })
    }

    isValid = (): boolean => {
        let isValid = true
        isValid = !IsNullOrEmpty(this.state.category)
        isValid = isValid && !IsNullOrEmpty(this.state.title)
        isValid = isValid && this.state.questions?.length > 0
        return isValid
    }

    saveQuestion = (question: IQuestionnaireQuestion) => {
        let { questions } = this.state
        let qIndex = questions.findIndex(x => x.id === question.id)
        questions.splice(qIndex, 1, question)
        this.setState({
            draftingQuestion: false,
            draftingQuestionId: null,
            questions
        })
    }

    setDueDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ dueDate: e.target.value })
    }


    setGraded = (graded: boolean) => {
        let { passingGrade } = this.state
        if (!this.state.isGraded && graded) {
            if (passingGrade === 0) { passingGrade = 100 }
        } else if (this.state.isGraded && !graded) {
            passingGrade = 0
        }
        this.setState({ isGraded: graded, passingGrade })
    }

    setTitle = (title: string) => {
        this.setState({ title })
    }

    submit = async () => {
        let {
            bannerKey: bannerKey,
            category,
            credits,
            dueDate,
            eventId,
            id,
            isGraded,
            passingGrade,
            presentationId,
            presenters,
            published,
            questions
        } = this.state
        let questionnaire = new Questionnaire()

        questionnaire.id = id
        if (IsNullOrEmpty(id) || id.startsWith('temp')) {
            questionnaire.id = undefined
        }
        questionnaire.bannerKey = bannerKey
        questionnaire.category = category
        questionnaire.credits = credits
        questionnaire.dueDate = new Date(dueDate)?.toJSON()
        questionnaire.eventId = eventId
        questionnaire.isGraded = isGraded
        questionnaire.passingGrade = passingGrade
        questionnaire.presentationId = presentationId
        questionnaire.presenters = presenters
        questionnaire.published = published
        questionnaire.questions = questions
        questionnaire.title = this.state.title
        questionnaire.questions.map(x => {
            x.questionnaireId = questionnaire.id
            x.eventId = eventId
            if (x.id?.startsWith('temp')) { x.id = undefined }
            if (x.questionnaireId?.startsWith('temp')) { x.questionnaireId = undefined }
            if (x.responseType == CEQuestionType.OpenForm) { x.options = [] }
            x.options.map(y => {
                if (y.id?.startsWith('temp')) { y.id = undefined }
                if (y.ceQuestionId?.startsWith('temp')) { y.ceQuestionId = undefined }
            })
        })
        if (IsNullOrEmpty(questionnaire.id)) {
            questionnaire = await createQuestionnaire(questionnaire)
            if (!questionnaire) { alert('Sorry, there was an error.') }
            let redirectUrl = `${getSiteAdminUrl()}/ce/questionnaires/${questionnaire.id}/edit`
            window.location.href = redirectUrl
        } else {
            questionnaire = await updateQuestionnaire(questionnaire)
            if (!questionnaire) { alert('Sorry, there was an error.') }
            window.location.href = `${getSiteAdminUrl()}/ce`
        }
    }

    setPassingGrade = (passingGrade: number) => {
        passingGrade = passingGrade ?? this.state.passingGrade
        if (passingGrade < 0) { passingGrade = 0 }
        if (passingGrade > 100) { passingGrade = 100 }
        this.setState({ passingGrade })
    }

    setPresentation = (presentationId: string) => {
        this.setState({ presentationId })
    }

    updateBanner = (bannerKey: string, bannerSrc: string) => {
        this.setState({ bannerKey, bannerUrl: bannerSrc })
    }


    render() {
        let {
            category,
            credits,
            draftingQuestion,
            draftingQuestionId,
            title,
            presenters,
            presentations,
            presentationId,
            questions
        } = this.state
        questions = _.sortBy(questions, x => x.order)
        let isValid = this.isValid() && !draftingQuestion

        return (
            <div className="questionnaire-composer">


                <div className="form-group">
                    <label> Title</label>
                    <input
                        className="form-control"
                        defaultValue={title}
                        onChange={(e) => { this.setTitle(e.target.value) }}
                        type="text" ></input>
                </div>

                <div className="form-group">
                    <label>Due Date</label>
                    <input
                        className="form-control"
                        onChange={this.setDueDate}
                        value={this.state.dueDate}
                        type="date" ></input>
                </div>

                <div className="form-group">
                    <label>Banner</label>
                    <FormFileUploader
                        src={this.state.bannerUrl}
                        onDelete={() => { this.setState({ bannerUrl: '' }) }}
                        onUpdate={this.updateBanner}

                    />
                </div>


                <div className="form-group">
                    <label>Category</label>
                    <input type="text" className="form-control"
                        defaultValue={category}
                        onChange={(e) => { this.setState({ category: e.target.value }) }}
                    />
                </div>

                <div className="form-group">
                    <label>Credits</label>
                    <input type="number" min={0} max={100} pattern="[0-9]*"
                        className="form-control"
                        value={credits}
                        onChange={(e) => { this.setState({ credits: Number(e.target.value) }) }}
                        style={{ maxWidth: 120 }}
                    />
                </div>

                <div className="form-group">
                    <label>Presenters</label>
                    <input type="text" className="form-control"
                        defaultValue={presenters}
                        onChange={(e) => { this.setState({ presenters: e.target.value }) }}
                    />
                </div>

                <div className="form-group">
                    <PresentationSelector
                        label={'Presentation'}
                        onSelect={this.setPresentation}
                        presentations={presentations}
                        value={presentationId}
                    />
                </div>

                <div className="form-group">
                    <Switch
                        checked={this.state.published}
                        label={"Active"}
                        labelStyle={{
                            paddingRight: 10
                        }}
                        onChange={(published: boolean) => { this.setState({ published }) }}
                    />
                </div>

                <div className="form-group d-flex align-items-center">
                    <Switch
                        checked={this.state.isGraded}
                        label={"Graded"}
                        onChange={(checked: boolean) => { this.setGraded(checked) }}
                    />
                    {this.state.isGraded &&
                        <input type="number" min={0} max={100}
                            className="form-control ml-3"
                            value={this.state.passingGrade}
                            onChange={(e) => { this.setPassingGrade(Number(e.target.value)) }}
                            style={{ maxWidth: 120 }}
                        />
                    }
                </div>

                {questions.map((question: IQuestionnaireQuestion, index: number) => {
                    return (
                        <QuestionnaireComposerQuestion
                            active={question.id === draftingQuestionId}
                            isGraded={this.state.isGraded}
                            key={question.id}
                            index={index}
                            onDelete={this.deleteQuestion}
                            onDraft={this.draftQuestion}
                            onSave={this.saveQuestion}
                            question={question}
                        />
                    )
                })}

                <div className="form-row mx-0">
                    <div className="form-group">
                        <button
                            className="btn btn-primary"
                            disabled={draftingQuestion}
                            onClick={this.addQuestion}
                            type="button"
                        >
                            Add Question
                        </button>
                    </div>
                    <div className="form-group ml-3">
                        <button
                            className="btn btn-primary px-5"
                            disabled={!isValid}
                            onClick={this.submit}
                            type="button"
                        >{this.state.isNew ? 'Create' : 'Save'} </button>
                    </div>
                </div>
            </div >
        )
    }
}


